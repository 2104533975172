@charset "UTF-8";
/*
SCSS variables are information about icon's compiled state, stored under its original file name

.icon-home {
  width: $icon-home-width;
}

The large array-like variables contain all information about a single icon
$icon-home: x y offset_x offset_y width height total_width total_height image_path;

At the bottom of this section, we provide information about the spritesheet itself
$spritesheet: width height image $spritesheet-sprites;
*/
/*
The provided mixins are intended to be used with the array-like variables

.icon-home {
  @include sprite-width($icon-home);
}

.icon-email {
  @include sprite($icon-email);
}

Example usage in HTML:

`display: block` sprite:
<div class="icon-home"></div>

To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:

// CSS
.icon {
  display: inline-block;
}

// HTML
<i class="icon icon-home"></i>
*/
/*
The `sprites` mixin generates identical output to the CSS template
  but can be overridden inside of SCSS

@include sprites($spritesheet-sprites);
*/
/*
SCSS variables are information about icon's compiled state, stored under its original file name

.icon-home {
  width: $icon-home-width;
}

The large array-like variables contain all information about a single icon
$icon-home: x y offset_x offset_y width height total_width total_height image_path;

At the bottom of this section, we provide information about the spritesheet itself
$spritesheet: width height image $spritesheet-sprites;
*/
/*
The provided mixins are intended to be used with the array-like variables

.icon-home {
  @include sprite-width($icon-home);
}

.icon-email {
  @include sprite($icon-email);
}

Example usage in HTML:

`display: block` sprite:
<div class="icon-home"></div>

To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:

// CSS
.icon {
  display: inline-block;
}

// HTML
<i class="icon icon-home"></i>
*/
/*
The `sprites` mixin generates identical output to the CSS template
  but can be overridden inside of SCSS

@include sprites($spritesheet-sprites);
*/
/*
SCSS variables are information about icon's compiled state, stored under its original file name

.icon-home {
  width: $icon-home-width;
}

The large array-like variables contain all information about a single icon
$icon-home: x y offset_x offset_y width height total_width total_height image_path;

At the bottom of this section, we provide information about the spritesheet itself
$spritesheet: width height image $spritesheet-sprites;
*/
/*
The provided mixins are intended to be used with the array-like variables

.icon-home {
  @include sprite-width($icon-home);
}

.icon-email {
  @include sprite($icon-email);
}

Example usage in HTML:

`display: block` sprite:
<div class="icon-home"></div>

To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:

// CSS
.icon {
  display: inline-block;
}

// HTML
<i class="icon icon-home"></i>
*/
/*
The `sprites` mixin generates identical output to the CSS template
  but can be overridden inside of SCSS

@include sprites($spritesheet-sprites);
*/
/*
SCSS variables are information about icon's compiled state, stored under its original file name

.icon-home {
  width: $icon-home-width;
}

The large array-like variables contain all information about a single icon
$icon-home: x y offset_x offset_y width height total_width total_height image_path;

At the bottom of this section, we provide information about the spritesheet itself
$spritesheet: width height image $spritesheet-sprites;
*/
/*
The provided mixins are intended to be used with the array-like variables

.icon-home {
  @include sprite-width($icon-home);
}

.icon-email {
  @include sprite($icon-email);
}

Example usage in HTML:

`display: block` sprite:
<div class="icon-home"></div>

To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:

// CSS
.icon {
  display: inline-block;
}

// HTML
<i class="icon icon-home"></i>
*/
/*
The `sprites` mixin generates identical output to the CSS template
  but can be overridden inside of SCSS

@include sprites($spritesheet-sprites);
*/
/*
SCSS variables are information about icon's compiled state, stored under its original file name

.icon-home {
  width: $icon-home-width;
}

The large array-like variables contain all information about a single icon
$icon-home: x y offset_x offset_y width height total_width total_height image_path;

At the bottom of this section, we provide information about the spritesheet itself
$spritesheet: width height image $spritesheet-sprites;
*/
/*
The provided mixins are intended to be used with the array-like variables

.icon-home {
  @include sprite-width($icon-home);
}

.icon-email {
  @include sprite($icon-email);
}

Example usage in HTML:

`display: block` sprite:
<div class="icon-home"></div>

To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:

// CSS
.icon {
  display: inline-block;
}

// HTML
<i class="icon icon-home"></i>
*/
/*
The `sprites` mixin generates identical output to the CSS template
  but can be overridden inside of SCSS

@include sprites($spritesheet-sprites);
*/
/*
SCSS variables are information about icon's compiled state, stored under its original file name

.icon-home {
  width: $icon-home-width;
}

The large array-like variables contain all information about a single icon
$icon-home: x y offset_x offset_y width height total_width total_height image_path;

At the bottom of this section, we provide information about the spritesheet itself
$spritesheet: width height image $spritesheet-sprites;
*/
/*
The provided mixins are intended to be used with the array-like variables

.icon-home {
  @include sprite-width($icon-home);
}

.icon-email {
  @include sprite($icon-email);
}

Example usage in HTML:

`display: block` sprite:
<div class="icon-home"></div>

To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:

// CSS
.icon {
  display: inline-block;
}

// HTML
<i class="icon icon-home"></i>
*/
/*
The `sprites` mixin generates identical output to the CSS template
  but can be overridden inside of SCSS

@include sprites($spritesheet-sprites);
*/
/*
SCSS variables are information about icon's compiled state, stored under its original file name

.icon-home {
  width: $icon-home-width;
}

The large array-like variables contain all information about a single icon
$icon-home: x y offset_x offset_y width height total_width total_height image_path;

At the bottom of this section, we provide information about the spritesheet itself
$spritesheet: width height image $spritesheet-sprites;
*/
/*
The provided mixins are intended to be used with the array-like variables

.icon-home {
  @include sprite-width($icon-home);
}

.icon-email {
  @include sprite($icon-email);
}

Example usage in HTML:

`display: block` sprite:
<div class="icon-home"></div>

To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:

// CSS
.icon {
  display: inline-block;
}

// HTML
<i class="icon icon-home"></i>
*/
/*
The `sprites` mixin generates identical output to the CSS template
  but can be overridden inside of SCSS

@include sprites($spritesheet-sprites);
*/
/*
SCSS variables are information about icon's compiled state, stored under its original file name

.icon-home {
  width: $icon-home-width;
}

The large array-like variables contain all information about a single icon
$icon-home: x y offset_x offset_y width height total_width total_height image_path;

At the bottom of this section, we provide information about the spritesheet itself
$spritesheet: width height image $spritesheet-sprites;
*/
/*
The provided mixins are intended to be used with the array-like variables

.icon-home {
  @include sprite-width($icon-home);
}

.icon-email {
  @include sprite($icon-email);
}

Example usage in HTML:

`display: block` sprite:
<div class="icon-home"></div>

To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:

// CSS
.icon {
  display: inline-block;
}

// HTML
<i class="icon icon-home"></i>
*/
/*
The `sprites` mixin generates identical output to the CSS template
  but can be overridden inside of SCSS

@include sprites($spritesheet-sprites);
*/
/*
SCSS variables are information about icon's compiled state, stored under its original file name

.icon-home {
  width: $icon-home-width;
}

The large array-like variables contain all information about a single icon
$icon-home: x y offset_x offset_y width height total_width total_height image_path;

At the bottom of this section, we provide information about the spritesheet itself
$spritesheet: width height image $spritesheet-sprites;
*/
/*
The provided mixins are intended to be used with the array-like variables

.icon-home {
  @include sprite-width($icon-home);
}

.icon-email {
  @include sprite($icon-email);
}

Example usage in HTML:

`display: block` sprite:
<div class="icon-home"></div>

To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:

// CSS
.icon {
  display: inline-block;
}

// HTML
<i class="icon icon-home"></i>
*/
/*
The `sprites` mixin generates identical output to the CSS template
  but can be overridden inside of SCSS

@include sprites($spritesheet-sprites);
*/
/*
SCSS variables are information about icon's compiled state, stored under its original file name

.icon-home {
  width: $icon-home-width;
}

The large array-like variables contain all information about a single icon
$icon-home: x y offset_x offset_y width height total_width total_height image_path;

At the bottom of this section, we provide information about the spritesheet itself
$spritesheet: width height image $spritesheet-sprites;
*/
/*
The provided mixins are intended to be used with the array-like variables

.icon-home {
  @include sprite-width($icon-home);
}

.icon-email {
  @include sprite($icon-email);
}

Example usage in HTML:

`display: block` sprite:
<div class="icon-home"></div>

To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:

// CSS
.icon {
  display: inline-block;
}

// HTML
<i class="icon icon-home"></i>
*/
/*
The `sprites` mixin generates identical output to the CSS template
  but can be overridden inside of SCSS

@include sprites($spritesheet-sprites);
*/
/*
	HTML5 Reset :: style.css
	----------------------------------------------------------
	We have learned much from/been inspired by/taken code where offered from:
	
	Eric Meyer					:: http://meyerweb.com
	HTML5 Doctor				:: http://html5doctor.com
	and the HTML5 Boilerplate	:: http://html5boilerplate.com
	
-------------------------------------------------------------------------------*/
/* Let's default this puppy out
-------------------------------------------------------------------------------*/
html, body, body div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, figure, footer, header, menu, nav, section, time, mark, audio, video, details, summary { margin: 0; padding: 0; border: 0; font-size: 100%; font-weight: normal; vertical-align: baseline; background: transparent; }

article, aside, figure, footer, header, nav, section, details, summary { display: block; }

/* Handle box-sizing while better addressing child elements:  http://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/ */
html { box-sizing: border-box; }

*, *:before, *:after { box-sizing: inherit; }

/* consider resetting the default cursor: https://gist.github.com/murtaugh/5247154 */
/* Responsive images and other embedded objects Note: keeping IMG here will cause problems if you're using foreground images as sprites. If this default setting for images is causing issues, you might want to replace it with a .responsive class instead. */
img, object, embed { max-width: 100%; }

/* force a vertical scrollbar to prevent a jumpy page */
html { overflow-y: scroll; }

/* we use a lot of ULs that aren't bulleted.  don't forget to restore the bullets within content. */
ul { list-style: none; }

blockquote, q { quotes: none; }

blockquote:before, blockquote:after, q:before, q:after { content: ''; content: none; }

a { margin: 0; padding: 0; font-size: 100%; vertical-align: baseline; background: transparent; }

del { text-decoration: line-through; }

abbr[title], dfn[title] { border-bottom: 1px dotted #000; cursor: help; }

/* tables still need cellspacing="0" in the markup */
table { border-collapse: collapse; border-spacing: 0; }

th { font-weight: bold; vertical-align: bottom; }

td { font-weight: normal; vertical-align: top; }

hr { display: block; height: 1px; border: 0; border-top: 1px solid #ccc; margin: 1em 0; padding: 0; }

input, select { vertical-align: middle; }

pre { white-space: pre; /* CSS2 */ white-space: pre-wrap; /* CSS 2.1 */ white-space: pre-line; /* CSS 3 (and 2.1 as well, actually) */ word-wrap: break-word; /* IE */ }

input[type="radio"] { vertical-align: text-bottom; }

input[type="checkbox"] { vertical-align: bottom; }

.ie7 input[type="checkbox"] { vertical-align: baseline; }

.ie6 input { vertical-align: text-bottom; }

select, input, textarea { font: 99% sans-serif; }

table { font-size: inherit; font: 100%; }

small { font-size: 85%; }

strong { font-weight: bold; }

td, td img { vertical-align: top; }

/* Make sure sup and sub don't mess with your line-heights http://gist.github.com/413930 */
sub, sup { font-size: 75%; line-height: 0; position: relative; }

sup { top: -0.5em; }

sub { bottom: -0.25em; }

/* standardize any monospaced elements */
pre, code, kbd, samp { font-family: monospace, sans-serif; }

/* hand cursor on clickable elements */
.clickable, label, input[type=button], input[type=submit], input[type=file], button { cursor: pointer; }

/* Webkit browsers add a 2px margin outside the chrome of form elements */
button, input, select, textarea { margin: 0; }

/* make buttons play nice in IE */
button, input[type=button] { width: auto; overflow: visible; }

/* scale images in IE7 more attractively */
.ie7 img { -ms-interpolation-mode: bicubic; }

/* prevent BG image flicker upon hover  (commented out as usage is rare, and the filter syntax messes with some pre-processors) .ie6 html {filter: expression(document.execCommand("BackgroundImageCache", false, true));} */
/* let's clear some floats */
.clearfix:before, .queue-panel:before, .order-panel:before, .summary-popup .summary-sales-item:before, .summary-popup .summary-sales-item .item-info .item-info-row:before, .summary-popup--quality .summary-quality-item:before, .summary-popup--quality .summary-quality-item .item-info .item-info-row:before, .clearfix:after, .queue-panel:after, .order-panel:after, .summary-popup .summary-sales-item:after, .summary-popup .summary-sales-item .item-info .item-info-row:after, .summary-popup--quality .summary-quality-item:after, .summary-popup--quality .summary-quality-item .item-info .item-info-row:after { content: "\0020"; display: block; height: 0; overflow: hidden; }

.clearfix:after, .queue-panel:after, .order-panel:after, .summary-popup .summary-sales-item:after, .summary-popup .summary-sales-item .item-info .item-info-row:after, .summary-popup--quality .summary-quality-item:after, .summary-popup--quality .summary-quality-item .item-info .item-info-row:after { clear: both; }

.clearfix, .queue-panel, .order-panel, .summary-popup .summary-sales-item, .summary-popup .summary-sales-item .item-info .item-info-row, .summary-popup--quality .summary-quality-item, .summary-popup--quality .summary-quality-item .item-info .item-info-row { zoom: 1; }

.row:before, .row:after { content: "\0020"; display: block; height: 0; overflow: hidden; }

.row:after { clear: both; }

.row { zoom: 1; }

.ngsb-wrap { -ms-touch-action: none; }

.ngsb-wrap .ngsb-container { width: auto; overflow: hidden; transition: 0.5s all; }

.ngsb-wrap:hover .ngsb-scrollbar { opacity: 1; filter: "alpha(opacity=100)"; -ms-filter: "alpha(opacity=100)"; /* old ie */ }

.ngsb-wrap .ngsb-scrollbar { width: 16px; height: 100%; top: 0; right: 0; opacity: 0.75; filter: "alpha(opacity=75)"; -ms-filter: "alpha(opacity=75)"; /* old ie */ }

.ngsb-wrap .ngsb-scrollbar .ngsb-thumb-container { position: absolute; top: 0; left: 0; bottom: 0; right: 0; height: auto; }

.ngsb-wrap .ngsb-scrollbar a.ngsb-thumb-container { margin: 20px 0; }

.ngsb-wrap .ngsb-scrollbar .ngsb-track { height: 100%; margin: 0 auto; width: 6px; background: #000; background: rgba(0, 0, 0, 0.4); -webkit-border-radius: 2px; -moz-border-radius: 2px; border-radius: 2px; filter: "alpha(opacity=40)"; -ms-filter: "alpha(opacity=40)"; /* old ie */ box-shadow: 1px 1px 1px rgba(255, 255, 255, 0.1); }

.ngsb-wrap .ngsb-scrollbar .ngsb-thumb-pos { cursor: pointer; width: 100%; height: 30px; }

.ngsb-wrap .ngsb-scrollbar .ngsb-thumb-pos .ngsb-thumb { transition: 0.5s all; width: 4px; height: 100%; margin: 0 auto; -webkit-border-radius: 10px; -moz-border-radius: 10px; border-radius: 10px; text-align: center; background: #fff; /* rgba fallback */ background: rgba(255, 255, 255, 0.4); filter: "alpha(opacity=40)"; -ms-filter: "alpha(opacity=40)"; /* old ie */ }

.ngsb-wrap .ngsb-scrollbar .ngsb-thumb-pos:hover .ngsb-thumb { background: rgba(255, 255, 255, 0.5); filter: "alpha(opacity=50)"; -ms-filter: "alpha(opacity=50)"; /* old ie */ }

.ngsb-wrap .ngsb-scrollbar .ngsb-thumb-pos:active { background: rgba(255, 255, 255, 0.6); filter: "alpha(opacity=60)"; -ms-filter: "alpha(opacity=60)"; /* old ie */ }

@font-face { font-family: 'play'; src: url("../fonts/play-bold-webfont.eot"); src: url("../fonts/play-bold-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/play-bold-webfont.woff2") format("woff2"), url("../fonts/play-bold-webfont.woff") format("woff"), url("../fonts/play-bold-webfont.ttf") format("truetype"); font-weight: bold; font-style: normal; }

@font-face { font-family: 'play'; src: url("../fonts/play-regular-webfont.eot"); src: url("../fonts/play-regular-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/play-regular-webfont.woff2") format("woff2"), url("../fonts/play-regular-webfont.woff") format("woff"), url("../fonts/play-regular-webfont.ttf") format("truetype"); font-weight: normal; font-style: normal; }

/* APP CSS */
html { height: 100%; }

.basic { height: 100%; text-shadow: none; color: #111; background: url(../img/assembly/bg.jpg) no-repeat 50% 50%; background-size: cover; }

.basic .wrapper { padding-top: 30px; }

.well { width: 550px; padding: 25px; margin: 0 auto; background: linear-gradient(to bottom, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0.15) 100%), url(../img/common-pattern.jpg); box-shadow: inset -1px -1px 40px rgba(0, 0, 0, 0.5), inset 1px 1px 0 rgba(255, 255, 255, 0.3), inset -1px -1px 0 rgba(0, 0, 0, 0.3), -1px -1px 0 rgba(255, 255, 255, 0.3), 1px 1px 0 rgba(0, 0, 0, 0.3); text-shadow: none; color: #fff; position: relative; z-index: 100; top: 220px; border: 4px solid #74797b; }

.well:before, .well:after { content: ''; display: block; position: absolute; }

.well:before { width: 22px; height: 19px; background: url(../img/common-corner-a.png) no-repeat 0 0; top: -7px; right: -8px; }

.well:after { width: 21px; height: 19px; background: url(../img/common-corner-b.png) no-repeat 0 0; bottom: -7px; left: -7px; }

.login-form.well { width: 407px; height: 252px; padding: 20px 32px; background: url(../img/login-bg.png) no-repeat 0 0; border: 0; box-shadow: none; }

.login-form:before, .login-form:after { display: none; }

.login-form label { display: block; width: 148px; padding: 5px 20px; font-size: 12px; margin-bottom: -1px; background: url(../img/common-label.png) no-repeat 0 0; color: #fff; position: relative; }

.login-form input { width: 100%; margin-bottom: 15px; padding: 8px; background: url(../img/common-metal.jpg); border: 1px solid rgba(0, 0, 0, 0.8); border-top-color: rgba(255, 255, 255, 0.2); border-left-color: rgba(255, 255, 255, 0.3); box-shadow: inset 1px 1px 1px rgba(0, 0, 0, 0.6), inset -1px -1px 1px rgba(255, 255, 255, 0.6), 1px 1px 5px rgba(0, 0, 0, 0.1); }

.login-form .btn.login { display: block; background: url(../img/button-login.png) no-repeat 0 0; font-size: 0; width: 163px; height: 35px; margin: 20px auto; }

.login-form .btn.login:hover { background: url(../img/button-login-hover.png) no-repeat 0 0; }

.login-form .btn.login:active { background: url(../img/button-login-active.png) no-repeat 0 0; }

.well button, .well .button { width: 148px; height: 36px; line-height: 26px; padding: 5px 15px; background: url(../img/common-label.png) no-repeat 0 0; color: #fff; text-align: center; border: 0; }

.well button[disabled], .well .button[disabled], .well .button.disabled { opacity: .5; cursor: default; }

.form-group { width: 344px; height: 73px; margin-bottom: 10px; background: url(../img/login-and-password.png) no-repeat 0 0; position: relative; }

.form-group label { width: 96px; height: 35px; background: none; position: absolute; top: 0; left: 0; color: #fff; font-size: 20px; text-align: center; line-height: 36px; padding: 0; }

.form-group input { height: 45px; margin-top: 30px; padding: 0 20px; background: none; border: 0; box-shadow: none; color: #000; font-size: 20px; text-shadow: none; }

.introjs-tooltiptext { color: #000; text-shadow: none; }

.introjs-helperLayer { background: rgba(255, 255, 255, 0.1); background-position: 0 0; background-size: cover; background-attachment: fixed; box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.3); }

.sell .introjs-helperLayer { background-image: url(../img/sell/bg.jpg); }

.shopping .introjs-helperLayer { background-image: url(../img/shopping/bg.jpg); }

.assembly .introjs-helperLayer { background-image: url(../img/assembly/bg.jpg); }

.production .introjs-helperLayer { background-image: url(../img/production/bg.jpg); }

.introjs-helperNumberLayer { font-size: 9px; line-height: 11px; }

.assembly .introjs-tooltip { max-width: 500px; }

.sweet-alert .sa-icon { display: none !important; }

.sweet-alert .sa-button-container { position: static; }

.sweet-alert .sa-confirm-button-container { position: static; }

[ng-app] .sweet-alert button { bottom: 34px; }

@-webkit-keyframes autofill { to { color: #000;
    background: transparent; } }

input:-webkit-autofill { -webkit-animation-name: autofill; -webkit-animation-fill-mode: both; }

.common-part { position: relative; }

.common-part .well { position: absolute; margin: 0; top: 0; padding: 16px 25px; text-align: center; background: none; box-shadow: none; border: 0; }

.common-part .well:before, .common-part .well:after { display: none; }

.row .well { width: 100%; margin: 10px auto; }

.text-right { text-align: right; }

.account-panel { height: 36px; padding: 7px 20px 5px 20px; position: fixed; z-index: 1000; right: 0; top: -8px; background: url(../img/common-bar.png) no-repeat 0 0; border: 0; color: #fff; font-size: 12px; line-height: 26px; text-transform: uppercase; }

.button-logout { display: inline-block; margin: 0 0 0 8px; padding: 0 0 0 10px; border: 0; color: #fff; border-left: 1px solid #aaa; cursor: pointer; background: none; text-transform: uppercase; outline: 0; }

.button-logout:hover { text-decoration: underline; }

/* Nanoscroller */
/* fix zeby nano odpowiednio wyswietlal kontenery na wysokosc */
.robots-recipe-items-list .nano { height: 350px; }

.robots-recipe-items-list.robots-recipe-items-list-small .nano { height: 145px; }

.actions-panel { z-index: 10; }

.robots-recipe-panel { z-index: 100; }

.assembly-line { position: relative; }

.transparent-grid { position: absolute; top: 0; left: 0; right: 0; bottom: 0; }

/* Style dla kart przeniesionych na grida */
/* karty przepisow - wysrodkowane w 3 kolumnach */
.transparent-grid .robots-recipe-item-wrapper { margin: 0; float: none; }

.transparent-grid .robots-recipe-item-wrapper img { margin: 0 auto; }

.assembly .transparent-grid .robots-recipe-item-wrapper img.active { margin-top: 20px; }

.transparent-grid .assemblyLineElement { height: 100%; }

.transparent-grid .top, .transparent-grid .bottom { height: 155px; }

.transparent-grid .binder { height: 80px; }

.material-count { padding: 2px 0; }

.sweet-alert { text-shadow: none; }

button, [ng-click] { cursor: pointer; }

.ui-draggable-handle { cursor: default; }

.ui-draggable-handle[data-drag="true"], .ui-draggable-handle:not(.inactive) { cursor: move; cursor: -webkit-grab; cursor: -moz-grab; }

.ui-draggable-handle.ui-draggable-dragging[data-drag="true"], .ui-draggable-handle.ui-draggable-dragging:not(.inactive) { cursor: move; cursor: -webkit-grabbing; cursor: -moz-grabbing; }

/* shopping modals */
.full-height { height: 100%; }

.buy-button { width: 84px; height: 37px; position: absolute; top: 3px; left: 50%; margin-left: -42px; background: url(../img/shopping/offer-buy.png) no-repeat center; z-index: 10; }

.buy-button:active { background: url(../img/shopping/offer-buy-hover.png) no-repeat center; }

.modal-fade { position: fixed; top: 0; bottom: 0; left: 0; right: 0; background: rgba(0, 0, 0, 0.5); z-index: 9999; }

.modal-fade .modal-content { position: absolute; top: 50%; left: 50%; }

.media-modal .modal-content { margin-left: -380px; margin-top: -200px; width: 760px; height: 400px; background: url(../img/shopping/media-popup/bg.png) no-repeat center; padding: 15px 16px 20px 0px; }

.media-modal .modal-content > .row:first-of-type { margin-bottom: 15px; }

.media-modal .media-offer { width: 204px; height: 153px; background: url(../img/shopping/media-popup/offer-bg.png) no-repeat center; }

.media-modal .media-offer > .row:first-of-type { margin-bottom: 8px; }

.media-modal .media-offer > .row:last-of-type { height: 70px; }

.media-modal .media-offer .media-title { line-height: 45px; text-align: center; }

.media-modal .media-offer .media-option { padding: 8px 6px 6px; text-align: center; height: 100%; position: relative; }

.media-modal .media-offer .media-option input { text-align: center; position: absolute; top: 73px; left: 12px; width: 68px; background: none; border: 0; }

.media-modal .media-offer .media-option .option-title { font-size: 12px; display: inline-block; max-height: 35px; height: 100%; }

.media-modal .media-offer .media-option .option-price { display: inline-block; width: 50px; height: 25px; background: url(../img/shopping/media-popup/money.png) no-repeat; background-size: 100%; font-size: 20px; position: absolute; bottom: 6px; left: 50%; margin-left: -25px; }

.media-modal .media-offer .media-option .option-title, .media-modal .media-offer .media-option .option-price { transition: all 250ms ease-out; }

.media-modal .media-offer .media-option:hover .option-title, .media-modal .media-offer .media-option:hover .option-price, .media-modal .media-offer .media-option.inactive .option-title, .media-modal .media-offer .media-option.inactive .option-price { opacity: .3; }

.media-modal .media-offer .media-option .buy-button { opacity: 0; transition: all 250ms ease-out; transform: translate(0, 5px); }

.media-modal .media-offer .media-option:not(.inactive):hover .buy-button { opacity: 1; transform: none; }

.media-modal .media-offer .media-option.inactive:before { display: block; content: ''; width: 100%; height: 37px; position: absolute; top: 50%; left: 0px; margin-top: -19px; background: url(../img/shopping/offer-buy-inactive.png) center no-repeat; z-index: 10; }

.media-modal .media-offer .media-option.inactive .buy-button { display: none; }

.media-modal .ok, .media-modal .cancel { width: 163px; height: 36px; cursor: pointer; position: absolute; bottom: 16px; }

.media-modal .ok { right: 50%; margin-right: 45px; background: url(../img/shopping/media-popup/ok.png) no-repeat 0 0; }

.media-modal .ok:hover { background: url(../img/shopping/media-popup/ok-hover.png) no-repeat 0 0; }

.media-modal .ok:active { background: url(../img/shopping/media-popup/ok-active.png) no-repeat 0 0; }

.media-modal .cancel { left: 50%; margin-left: 45px; background: url(../img/shopping/media-popup/cancel.png) no-repeat 0 0; }

.media-modal .cancel:hover { background: url(../img/shopping/media-popup/cancel-hover.png) no-repeat 0 0; }

.media-modal .cancel:active { background: url(../img/shopping/media-popup/cancel-active.png) no-repeat 0 0; }

/* buy binders modal*/
.binders-modal .modal-content { margin-left: -127px; margin-top: -254px; width: 254px; height: 508px; background: url(../img/shopping/binders-popup/bg.png) no-repeat center; padding: 35px 23px 12px 26px; }

.binder-buy-offer { background-image: url(../img/sprite-shopping.png); background-position: 0px -137px; width: 203px; height: 97px; margin-top: 5px; padding: 5px 14px 12px 12px; }

.binder-buy-offer .buy-title { line-height: 22px; height: 22px; font-size: 18px; text-align: center; margin-bottom: 5px; padding-left: 20px; position: relative; }

.binder-buy-offer .buy-title:before { content: ''; display: block; width: 27px; height: 26px; position: absolute; top: -2px; left: -7px; }

.binder-buy-offer.type--nany .buy-title:before { background: url(../img/shopping/binders-popup/binder-icon-1.png) no-repeat 0 0; }

.binder-buy-offer.type--housekeeper .buy-title:before { background: url(../img/shopping/binders-popup/binder-icon-2.png) no-repeat 0 0; }

.binder-buy-offer.type--handyman .buy-title:before { background: url(../img/shopping/binders-popup/binder-icon-3.png) no-repeat 0 0; }

.binder-buy-offer.type--driver .buy-title:before { background: url(../img/shopping/binders-popup/binder-icon-4.png) no-repeat 0 0; }

.binder-buy-offer .buy-panel { height: 50px; line-height: 50px; text-align: center; }

.binder-buy-offer .buy-panel .buy-number { font-size: 24px; font-weight: bold; padding-top: 4px; }

.binder-buy-offer .buy-panel .buy-less-button, .binder-buy-offer .buy-panel .buy-more-button { margin-top: 10px; }

.binder-buy-offer .buy-panel .buy-less-button { display: inline-block; width: 52px; height: 36px; background: url(../img/shopping/binders-popup/buttons/buy-less-inactive.png) center no-repeat; cursor: default; }

.binder-buy-offer .buy-panel .buy-less-button.is-enabled { background: url(../img/shopping/binders-popup/buttons/buy-less.png) center no-repeat; cursor: pointer; }

.binder-buy-offer .buy-panel .buy-less-button.is-enabled:hover { background: url(../img/shopping/binders-popup/buttons/buy-less-hover.png) center no-repeat; }

.binder-buy-offer .buy-panel .buy-less-button.is-enabled:active { background: url(../img/shopping/binders-popup/buttons/buy-less-active.png) center no-repeat; }

.binder-buy-offer .buy-panel .buy-more-button { display: inline-block; width: 52px; height: 36px; background: url(../img/shopping/binders-popup/buttons/buy-more-inactive.png) center no-repeat; cursor: default; }

.binder-buy-offer .buy-panel .buy-more-button.is-enabled { background: url(../img/shopping/binders-popup/buttons/buy-more.png) center no-repeat; cursor: pointer; }

.binder-buy-offer .buy-panel .buy-more-button.is-enabled:hover { background: url(../img/shopping/binders-popup/buttons/buy-more-hover.png) center no-repeat; }

.binder-buy-offer .buy-panel .buy-more-button.is-enabled:active { background: url(../img/shopping/binders-popup/buttons/buy-more-active.png) center no-repeat; }

.buy-button { top: auto; bottom: 15px; }

.media-modal, .binders-modal { display: none; }

.media-modal.open, .binders-modal.open { display: block; }

.draggable { text-align: center; }

.draggable.ui-draggable-dragging { position: absolute; margin: 0 0 0 25px; opacity: .5; width: 20px !important; height: 40px !important; }

.draggable.ui-draggable-dragging > * { transform: translate(-25px, 0); }

.draggable.resident { position: absolute; }

.draggable[data-size="3x1"] { height: 155px; }

.draggable[data-size="3x1"][data-type="binder"] { height: 80px !important; }

.draggable[data-size="1x2"] { height: 152px; }

.draggable[data-size="1x3"] { height: 390px; }

.draggable[data-size="2x1"] { width: 150px; height: 155px; }

.draggable[data-size="3x1"] { width: 225px; height: 155px; }

.draggable[data-size="2x2"] { width: 150px; height: 152px; }

.draggable[data-size="2x3"] { width: 150px; height: 390px; }

.draggable[data-size="3x2"] { width: 225px; height: 152px; }

.draggable[data-size="3x3"] { width: 225px; height: 390px; }

.draggable[data-size="4x2"] { width: 300px; height: 152px; }

.draggable[data-size="4x3"] { width: 300px; height: 390px; }

.draggable[data-size="5x2"] { width: 375px; height: 152px; }

.draggable[data-size="5x3"] { width: 375px; height: 390px; }

.draggable.ui-draggable-dragging .material, .zone .material { width: 75px; height: 71px; }

.draggable[data-size] img { display: block; }

.draggable[data-size="3x1"] img, .draggable[data-size="3x1"] .type { width: 150px; height: 155px; max-width: none; }

.draggable[data-size="3x1"][data-type="binder"] img, .draggable[data-size="3x1"][data-type="binder"] .type { height: 80px !important; }

.draggable[data-size="1x3"] img, .draggable[data-size="1x3"] .type { width: 75px; height: 390px; }

.draggable[data-size="2x1"] img, .draggable[data-size="2x1"] .type { width: 150px; height: 155px; }

.draggable[data-size="3x1"] img, .draggable[data-size="3x1"] .type { width: 225px; height: 155px; }

.draggable[data-size="2x3"] img, .draggable[data-size="2x3"] .type { width: 150px; height: 390px; }

.draggable[data-size="3x3"] img, .draggable[data-size="3x3"] .type { width: 225px; height: 390px; }

.draggable[data-size="4x3"] img, .draggable[data-size="4x3"] .type { width: 300px; height: 390px; }

.draggable[data-size="5x3"] img, .draggable[data-size="5x3"] .type { width: 375px; height: 390px; }

.production .draggable[data-size="3x1"] img, .production .draggable[data-size="3x1"] .type { width: 150px; height: 76px; max-width: none; }

.production .draggable[data-size="1x2"] img, .production .draggable[data-size="1x2"] .type { width: 58px; height: 142px; }

.production .draggable[data-size="2x1"] img, .production .draggable[data-size="2x1"] .type { width: 150px; height: 76px; }

.production .draggable[data-size="3x1"] img, .production .draggable[data-size="3x1"] .type { width: 225px; height: 76px; }

.production .draggable[data-size="2x2"] img, .production .draggable[data-size="2x2"] .type { width: 150px; height: 152px; }

.production .draggable[data-size="3x2"] img, .production .draggable[data-size="3x2"] .type { width: 225px; height: 152px; }

.production .draggable[data-size="4x2"] img, .production .draggable[data-size="4x2"] .type { width: 300px; height: 152px; }

.production .draggable[data-size="5x2"] img, .production .draggable[data-size="5x2"] .type { width: 375px; height: 152px; }

.inactive .draggable[data-size="3x1"] { height: 77px; }

.inactive .draggable[data-size="1x3"] { height: 154px; }

.inactive .draggable[data-size="2x1"] { height: 77px; }

.inactive .draggable[data-size="3x1"] { height: 154px; }

.inactive .draggable[data-size="2x3"] { height: 154px; }

.inactive .draggable[data-size="3x3"] { height: 154px; }

.inactive .draggable[data-size="4x3"] { height: 154px; }

.inactive .draggable[data-size="5x3"] { height: 154px; }

.inactive .draggable[data-size="3x1"] img, .inactive .draggable[data-size="3x1"] .type { height: 77px; }

.inactive .draggable[data-size="1x3"] img, .inactive .draggable[data-size="1x3"] .type { height: 154px; }

.inactive .draggable[data-size="2x1"] img, .inactive .draggable[data-size="2x1"] .type { height: 77px; }

.inactive .draggable[data-size="3x1"] img, .inactive .draggable[data-size="3x1"] .type { height: 77px; }

.inactive .draggable[data-size="2x3"] img, .inactive .draggable[data-size="2x3"] .type { height: 154px; }

.inactive .draggable[data-size="3x3"] img, .inactive .draggable[data-size="3x3"] .type { height: 154px; }

.inactive .draggable[data-size="4x3"] img, .inactive .draggable[data-size="4x3"] .type { height: 154px; }

.inactive .draggable[data-size="5x3"] img, .inactive .draggable[data-size="5x3"] .type { height: 154px; }

.production .inactive .draggable[data-size="3x1"] img, .production .inactive .draggable[data-size="3x1"] .type { height: 71px; }

.production .inactive .draggable[data-size="1x2"] img, .production .inactive .draggable[data-size="1x2"] .type { height: 152px; }

.production .inactive .draggable[data-size="2x1"] img, .production .inactive .draggable[data-size="2x1"] .type { height: 71px; }

.production .inactive .draggable[data-size="3x1"] img, .production .inactive .draggable[data-size="3x1"] .type { height: 71px; }

.production .inactive .draggable[data-size="2x2"] img, .production .inactive .draggable[data-size="2x2"] .type { height: 152px; }

.production .inactive .draggable[data-size="3x2"] img, .production .inactive .draggable[data-size="3x2"] .type { height: 152px; }

.production .inactive .draggable[data-size="4x2"] img, .production .inactive .draggable[data-size="4x2"] .type { height: 152px; }

.production .inactive .draggable[data-size="5x2"] img, .production .inactive .draggable[data-size="5x2"] .type { height: 152px; }

.droppable { margin: 0 -2px; }

.droppable .inner { width: 75px; height: 155px; position: absolute; }

.inactive .droppable .inner { display: none !important; }

.production .droppable .inner { height: 76px; }

.droppable .inner.active:after { background: radial-gradient(ellipse at center, rgba(255, 255, 255, 0.4) 50%, rgba(255, 255, 255, 0) 100%); }

.droppable.binder { height: 80px; }

.droppable.binder .inner { height: 80px; }

/* .draggable img { max-width: auto; width: 100%; height: 100%; display: block; } */
.draggable .type { display: block; background: #111; color: #fff; text-align: center; padding: 30px 0; font-size: 9px; text-transform: uppercase; border-radius: 3px; box-shadow: inner 0 0 0 3px currentcolor; }

.inner[data-expect], .inner[ml-clickdrop].active { position: relative; }

.inner[data-expect]:after, .inner[ml-clickdrop].active:after { content: ''; display: block; width: 100%; height: 100%; top: 0; left: 0; }

.inner[data-expect="1x3"]:after { height: 390px; }

.inner[data-expect="2x1"]:after { width: 150px; }

.inner[data-expect="3x1"]:after { width: 225px; }

.inner[data-expect="2x3"]:after { width: 150px; height: 390px; }

.inner[data-expect="3x3"]:after { width: 225px; height: 390px; }

.inner[data-expect="4x3"]:after { width: 300px; height: 390px; }

.inner[data-expect="5x3"]:after { width: 375px; height: 390px; }

.production .inner[data-expect="1x2"]:after { height: 152px; }

.production .inner[data-expect="2x1"]:after { width: 150px; }

.production .inner[data-expect="3x1"]:after { width: 225px; }

.production .inner[data-expect="2x2"]:after { width: 150px; height: 152px; }

.production .inner[data-expect="3x2"]:after { width: 225px; height: 152px; }

.production .inner[data-expect="4x2"]:after { width: 300px; height: 152px; }

.production .inner[data-expect="5x2"]:after { width: 375px; height: 152px; }

.follow-mouse { position: absolute; z-index: 100000; }

.c { clear: both; }

.zone { position: absolute; top: 0; left: 0; /*width: 100%; height: 100%;*/ }

.zone .draggable { float: left; }

.zone-inactive { display: none; }

.inactive .zone { display: none; }

.inactive .zone-inactive { display: block; }

/*.list-cards { white-space: nowrap; }
.list-cards .robots-recipe-item-wrapper { display: inline-block; float: none; }*/
.components-panel { overflow: hidden; }

/*.robots-recipe-item { text-align: center; vertical-align: center; }
.robots-recipe-item img,
.robots-recipe-item img.active { display: inline; }*/
.machine-wrapper { position: relative; }

.activate-machine { position: absolute; top: 0; left: 0; right: 0; bottom: 0; }

body, button, input, textarea { font-family: 'play', 'Arial', sans-serif; color: #fff; text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.75); }

html { -webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; overflow: hidden; }

::-webkit-scrollbar { display: none; }

body [ui-view="main"] { position: absolute; top: -100%; left: -100%; }

body.loaded [ui-view="main"] { position: static; top: 0; left: 0; }

body.loaded .site-preloader { display: none; }

img { max-width: none; }

.site-preloader { width: 200px; padding: 5px 20px; position: fixed; top: 150px; left: 50%; margin-left: -100px; border: 4px solid #74797b; box-shadow: inset -1px -1px 40px rgba(0, 0, 0, 0.5), inset 1px 1px 0 rgba(255, 255, 255, 0.3), inset -1px -1px 0 rgba(0, 0, 0, 0.3), -1px -1px 0 rgba(255, 255, 255, 0.3), 1px 1px 0 rgba(0, 0, 0, 0.3); z-index: 10000; text-shadow: none; color: #000; }

.site-preloader .inner { width: 100%; height: 15px; padding: 5px 0; border-radius: 5px; overflow: hidden; }

.site-preloader .percent { width: 100%; height: 5px; background: #000; }

.site-preloader .fill { width: 0%; height: 5px; background: #fff; position: relative; }

.site-preloader .fill:after { content: attr(data-percent); display: block; padding: 0 3px; border-radius: 2px; position: absolute; right: 0; top: -4px; background: #fff; font-size: 10px; line-height: 13px; }

.site-preloader:before, .site-preloader:after { content: ''; display: block; position: absolute; }

.site-preloader:before { width: 22px; height: 19px; background: url(../img/common-corner-a.png) no-repeat 0 0; top: -7px; right: -8px; }

.site-preloader:after { width: 21px; height: 19px; background: url(../img/common-corner-b.png) no-repeat 0 0; bottom: -7px; left: -7px; }

.site-preloader.indicator .fill { width: 100%; animation: loader-indicator 500ms linear infinite; }

.loaded .site-preloader.indicator .fill { animation: none; }

.site-preloader.indicator .fill:after { display: none; }

@keyframes loader-indicator { 0% { transform: translate(-100%, 0); }
  100% { transform: translate(100%, 0); } }

.clearfix:before, .queue-panel:before, .order-panel:before, .summary-popup .summary-sales-item:before, .summary-popup .summary-sales-item .item-info .item-info-row:before, .summary-popup--quality .summary-quality-item:before, .summary-popup--quality .summary-quality-item .item-info .item-info-row:before, .clearfix:after, .queue-panel:after, .order-panel:after, .summary-popup .summary-sales-item:after, .summary-popup .summary-sales-item .item-info .item-info-row:after, .summary-popup--quality .summary-quality-item:after, .summary-popup--quality .summary-quality-item .item-info .item-info-row:after { content: "\0020"; display: block; height: 0; overflow: hidden; }

.clearfix:after, .queue-panel:after, .order-panel:after, .summary-popup .summary-sales-item:after, .summary-popup .summary-sales-item .item-info .item-info-row:after, .summary-popup--quality .summary-quality-item:after, .summary-popup--quality .summary-quality-item .item-info .item-info-row:after { clear: both; }

.clearfix, .queue-panel, .order-panel, .summary-popup .summary-sales-item, .summary-popup .summary-sales-item .item-info .item-info-row, .summary-popup--quality .summary-quality-item, .summary-popup--quality .summary-quality-item .item-info .item-info-row { zoom: 1; }

a { color: #03f; text-decoration: none; }

a:hover { color: #69f; }

button { border: 0; cursor: pointer; outline: 0; background: none; }

button:disabled, [disabled] { cursor: default; opacity: .5; pointer-events: none; }

::-moz-selection { background: #fcd700; color: #fff; text-shadow: none; }

::selection { background: #fcd700; color: #fff; text-shadow: none; }

a:link { -webkit-tap-highlight-color: #fcd700; }

/* OWLS GRID SYSTEM */
.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col-13, .col-14, .col-15, .col-16, .col-17, .col-18, .col-19, .col-20, .col-21, .col-22, .col-23, .col-24 { float: left; padding: 0 2px; }

.row { margin: 0 -2px; }

.col-1 { width: 4.1666%; }

.col-2 { width: 8.3333%; }

.col-3 { width: 12.4999%; }

.col-4 { width: 16.6666%; }

.col-5 { width: 20.8333%; }

.col-6 { width: 25%; }

.col-7 { width: 29.1666%; }

.col-8 { width: 33.3333%; }

.col-9 { width: 37.4999%; }

.col-10 { width: 41.6666%; }

.col-11 { width: 45.8333%; }

.col-12 { width: 50%; }

.col-13 { width: 54.1666%; }

.col-14 { width: 58.3333%; }

.col-16 { width: 66.6666%; }

.col-17 { width: 70.8332%; }

.col-18 { width: 75%; }

.col-19 { width: 79.1666%; }

.col-20 { width: 83.3333%; }

.col-21 { width: 87.4999%; }

.col-22 { width: 91.6666%; }

.col-24 { width: 100%; }

.push-col-1 { margin-left: 4.1666%; }

.push-col-2 { margin-left: 8.3333%; }

.push-col-3 { margin-left: 12.4998%; }

.push-col-4 { margin-left: 16.6666%; }

.push-col-5 { margin-left: 20.8333%; }

.push-tired { margin-left: 11%; }

.pull-tired { margin-left: -4.1666%; }

.wrapper { width: 1200px; top: 50%; left: 50%; margin: -350px 0 0 -600px; position: absolute; z-index: 1; }

/* GENERAL ELEMENTS */
.bg { background-repeat: no-repeat; background-position: center center; }

.open-panel { cursor: pointer; }

.cover-bg { position: fixed; top: 0px; bottom: 0px; left: 0px; right: 0px; background-repeat: no-repeat; background-position: center center; background-size: cover; background-attachment: fixed; z-index: 0; cursor: default; }

.depertment-name { height: 36px; margin-bottom: 3px; }

.timer { height: 36px; }

.timer .timer-value { padding-left: 162px; font-size: 0; text-align: center; }

.timer .timer-value span { font-weight: 900; font-size: 32px; line-height: 36px; }

.timer .timer-value .minutes { display: inline-block; width: 40px; padding-right: 3px; text-align: right; }

.timer .timer-value .seconds { display: inline-block; width: 40px; padding-left: 3px; text-align: left; }

.timer .timer-value.is-over-100 span { font-size: 20px; }

.timer.tick .divider { animation: timer-tick 1000ms linear infinite; }

@keyframes timer-tick { 0% { opacity: 0; }
  25% { opacity: 1; }
  75% { opacity: 1; }
  100% { opacity: 0; } }

.media { height: 78px; }

.binders { height: 78px; }

.actions { height: 78px; font-size: 15px; }

.actions .row { margin-left: 130px; width: 466px; }

.actions .row .col-2 { width: 10%; padding: 0; }

.actions-panel { height: 0px; width: 100%; position: absolute; top: 100%; transition: all 250ms cubic-bezier(0.86, 0, 0.07, 1); background-position: bottom center; background-repeat: no-repeat; overflow: hidden; }

.actions-panel.open { height: 387px; }

.actions-panel .actions-panel-inner { position: absolute; top: -574px; width: 100%; height: 581px; transition: all 250ms ease-in-out; }

.actions-panel.open .actions-panel-inner { top: 7px; }

.actions-panel .action-row { margin: 10px 18px 0 16px; }

.functionalities-prices { height: 78px; }

.functionalities-prices-panel { height: 0px; width: 100%; position: absolute; top: 100%; transition: all 250ms cubic-bezier(0.86, 0, 0.07, 1); background-position: bottom center; overflow: hidden; z-index: 10; }

.functionalities-prices-panel.open { height: 620px; }

.functionalities-prices-panel .functionalities-prices-panel-inner { position: absolute; top: -605px; width: 100%; height: 620px; transition: all 250ms cubic-bezier(0.86, 0, 0.07, 1); }

.functionalities-prices-panel.open .functionalities-prices-panel-inner { top: 15px; }

.offers { height: 78px; }

.common-part { height: 78px; }

.end-round { height: 36px; cursor: pointer; margin-bottom: 4px; }

.summary { height: 36px; cursor: pointer; }

.report { height: 36px; cursor: pointer; margin-bottom: 3px; }

.saldo { height: 78px; width: 152px; margin-left: -3px; text-align: center; font-size: 26px; padding-top: 37px; line-height: 38px; text-shadow: -1px -1px 1px rgba(0, 0, 0, 0.35); }

.action-1 { height: 101px; cursor: pointer; }

.action-2 { height: 101px; cursor: pointer; }

.action-3 { height: 101px; cursor: pointer; }

.action-4 { height: 101px; cursor: pointer; }

.action-5 { height: 101px; cursor: pointer; }

.action-1-info { height: 101px; }

.action-2-info { height: 101px; }

.action-3-info { height: 101px; }

.action-4-info { height: 101px; }

.action-5-info { height: 101px; }

.function-1, .function-2, .function-3, .function-4 { height: 147px; padding: 75px 32px 0 140px; text-align: center; font-size: 36px; font-weight: bold; }

.function-1.unaccepted, .function-2.unaccepted, .function-3.unaccepted, .function-4.unaccepted { font-size: 0; }

.offers-compare { height: 18px; }

.machine { width: 93px; height: 18px; position: absolute; z-index: 10; top: 0; left: 0; pointer-events: none; }

.machine-vert { height: 205px; }

.number-1 { height: 40px; }

.number-2 { height: 40px; }

.number-3 { height: 40px; }

.number-4 { height: 40px; }

.number-5 { height: 40px; }

.number-6 { height: 40px; }

.number-7 { height: 40px; }

.number-8 { height: 40px; }

.number-9 { height: 40px; }

.number-10 { height: 40px; }

.number-11 { height: 40px; }

.number-12 { height: 40px; }

.color { height: 40px; }

.color-placeholder { height: 40px; overflow: hidden; }

.color-placeholder .color-change-item { width: 33.333%; height: 40px; float: left; cursor: pointer; }

.color-placeholder.color-disabled { cursor: default; }

.color-placeholder.color-disabled .color-change-item { display: none; }

.orders { height: 40px; }

.queue { height: 40px; }

.magazine { height: 40px; }

.orders-count, .queue-count, .magazine-count { height: 40px; padding: 9px 22px 0 0; text-align: center; }

.orders-count:empty:before, .queue-count:empty:before, .magazine-count:empty:before { content: '0'; }

.orders-count.alot, .queue-count.alot, .magazine-count.alot { font-size: 12px; padding-top: 12px; }

.sort { height: 40px; cursor: pointer; }

.moves { height: 40px; }

.move { height: 40px; }

.move.active { cursor: pointer; }

.stock, .wide-stock { height: 40px; }

.compare { display: block; height: 40px; cursor: pointer; }

.nav-prev, .nav-next { width: 18px; height: 31px; display: block; }

.material { width: 46px; height: 52px; margin-top: 3px; }

.panel-container { position: relative; }

.recipe-placeholder { height: 387px; padding-top: 18px; padding-left: 14px; position: relative; }

.inactive .recipe-placeholder { padding-top: 6px; }

.recipe-placeholder.active .robots-recipe-item { opacity: 0; }

.production .recipe-placeholder { padding-top: 0; padding-left: 0; }

.production .recipe-placeholder .robots-recipe-item-wrapper { float: none; margin: 0; }

.production .recipe-placeholder .robots-recipe-item-wrapper img { display: block; margin: 0 auto; }

.robots-recipe { cursor: pointer; height: 387px; }

.robots-recipe-panel { height: 387px; width: 0px; position: absolute; left: 100%; transition: all 250ms cubic-bezier(0.86, 0, 0.07, 1); top: 0px; background-position: right center; overflow: hidden; opacity: 0; }

.robots-recipe-panel.open { width: 900px; height: 387px; opacity: 1; }

.robots-recipe-panel-inner { width: 900px; padding-left: 20px; position: absolute; right: 0px; }

.attach-right .robots-recipe-panel-inner { right: auto; left: 0; }

.attach-top .robots-recipe-panel-inner { top: 0; }

.robots-recipe-items-list { margin: 20px 20px; }

.robots-recipe-items-list-small { margin-top: 6px; }

.robots-recipe-item { position: relative; }

.robots-recipe-item.disabled { cursor: default; opacity: .5; }

.robots-recipe-item img.active { display: block; }

.robots-recipe-item img.inactive { display: none; }

.inactive .robots-recipe-item img.active { display: none; }

.inactive .robots-recipe-item img.inactive { display: block; }

.robots-recipe-item.ui-draggable-dragging { z-index: 10000; }

.robots-recipe-item-wrapper { float: left; }

.components-panel .robots-recipe-item-wrapper.locked { opacity: .2; pointer-events: none; cursor: wait; }

.popup-container { position: fixed; top: 0px; bottom: 0px; left: 0px; right: 0px; background: rgba(0, 0, 0, 0.7); z-index: 10; display: block; z-index: 1600; }

.popup-container .ok { width: 163px; height: 36px; cursor: pointer; position: absolute; right: 40px; bottom: 26px; background: url(../img/button-ok.png) no-repeat 0 0; }

.popup-container .ok:hover { background: url(../img/button-ok-hover.png) no-repeat 0 0; }

.popup-container .ok:active { background: url(../img/button-ok-active.png) no-repeat 0 0; }

.popup-small { width: 497px; height: 248px; padding: 25px; background: url(../img/common-popup-small.png) no-repeat center; position: absolute; top: 50%; left: 50%; margin: -129px 0 0 -248px; text-align: center; font-size: 18px; color: #fff; cursor: default; animation: appear-pop 400ms ease-out; }

.popup-medium { width: 631px; height: 315px; padding: 25px; background: url(../img/common-popup-medium.png) no-repeat center; position: absolute; top: 50%; left: 50%; margin: -157px 0 0 -315px; text-align: center; font-size: 18px; color: #fff; cursor: default; animation: appear-pop 400ms ease-out; }

.popup-large { width: 761px; height: 378px; padding: 25px; background: url(../img/common-popup-large.png) no-repeat center; position: absolute; top: 50%; left: 50%; margin: -189px 0 0 -380px; text-align: center; font-size: 18px; color: #fff; cursor: default; animation: appear-pop 400ms ease-out; }

.orders-panel { height: 543px; }

.orders-panel--warehouse .orders-panel-inner { padding-bottom: 60px; }

.orders-panel-inner { height: 543px; padding: 25px 15px 15px 15px; overflow-x: auto; }

.orders-panel .up, .orders-panel .down { display: block; width: 30px; height: 17px; position: absolute; left: 50%; margin-left: -15px; z-index: 101; cursor: default; }

.orders-panel .up.active, .orders-panel .down.active { cursor: pointer; }

.orders-panel .up { top: 3px; }

.orders-panel .down { bottom: 3px; }

.queue-container { height: 543px; overflow-x: auto; }

.queue-panel { height: 231px; padding: 56px 0px 0 15px; position: relative; background-position: center left; background-image: url(../img/sell/queue/queue-items2.png); }

.queue-panel .queue-panel-round { width: 42px; height: 32px; position: absolute; top: 5px; left: 78px; text-align: center; text-shadow: none; font-size: 30px; line-height: 32px; font-weight: bold; }

.queue-panel .queue-panel-delay, .queue-panel .queue-panel-penalty { display: none; }

.queue-panel-fake-card { display: none; }

.has-fake-offer .queue-panel-fake-card.is-visible { display: block; }

.queue-panel-fake-card--1 { width: 269px; height: 155px; background: url(../img/tutorial-fakes/order.png) no-repeat 0 0; }

.queue-panel-fake-card--2 { width: 274px; height: 161px; background: url(../img/tutorial-fakes/order-with-robot.png) no-repeat 0 0; }

.queue-panel-fake-card--3 { width: 274px; height: 161px; background: url(../img/tutorial-fakes/order-with-robot-send.png) no-repeat 0 0; }

.queue-panel.day--3, .queue-panel.day--2, .queue-panel.day--1 { background-image: url(../img/sell/queue/queue-late-items2.png); }

.queue-panel.day--3 .queue-panel-round, .queue-panel.day--2 .queue-panel-round, .queue-panel.day--1 .queue-panel-round { display: none; }

.queue-panel.day--3 .queue-panel-delay, .queue-panel.day--2 .queue-panel-delay, .queue-panel.day--1 .queue-panel-delay { display: block; width: 43px; height: 32px; position: absolute; top: 4px; left: 348px; text-align: center; text-shadow: none; font-size: 30px; line-height: 32px; font-weight: bold; }

.queue-panel.day--3 .queue-panel-penalty, .queue-panel.day--2 .queue-panel-penalty, .queue-panel.day--1 .queue-panel-penalty { display: block; width: 68px; height: 26px; position: absolute; top: 8px; left: 501px; text-align: center; text-shadow: none; font-size: 24px; line-height: 26px; font-weight: bold; }

.queue-panel.medium { height: 426px; background-image: url(../img/sell/queue/queue-items4.png); }

.queue-panel.medium.day--3, .queue-panel.medium.day--2, .queue-panel.medium.day--1 { background-image: url(../img/sell/queue/queue-late-items4.png); }

.queue-panel.long { height: 619px; background-image: url(../img/sell/queue/queue-items6.png); }

.queue-panel.long.day--3, .queue-panel.long.day--2, .queue-panel.long.day--1 { background-image: url(../img/sell/queue/queue-late-items6.png); }

.queue-panel.items8 { height: 814px; background-image: url(../img/sell/queue/queue-items8.png); }

.queue-panel.items8.day--3, .queue-panel.items8.day--2, .queue-panel.items8.day--1 { height: 619px; background-image: url(../img/sell/queue/queue-late-items6.png); }

.queue-panel.items10 { height: 1010px; background-image: url(../img/sell/queue/queue-items10.png); }

.queue-panel.items10.day--3, .queue-panel.items10.day--2, .queue-panel.items10.day--1 { height: 619px; background-image: url(../img/sell/queue/queue-late-items6.png); }

.queue-panel.empty { height: 42px; padding-top: 0; padding-bottom: 0; background-image: url(../img/sell/queue/queue-empty.png); }

.queue-panel.empty.day--3, .queue-panel.empty.day--2, .queue-panel.empty.day--1 { height: 42px; padding-top: 0; padding-bottom: 0; background-image: url(../img/sell/queue/queue-late-empty.png); }

.queue-panel.has-fake-offer { height: 231px !important; padding: 56px 0px 0 15px !important; background-image: url(../img/sell/queue/queue-items2.png) !important; }

.queue-panel.has-fake-offer .queue-panel-item { display: none !important; }

.queue-panel .queue-panel-item { float: left; margin-right: 8px; position: relative; }

.queue-panel .queue-panel-item .order-robot { width: 269px; height: 156px; position: absolute; top: 0; left: 0; }

.queue-panel .queue-panel-item .order-robot.droppable.active { background: radial-gradient(ellipse at center, rgba(255, 255, 255, 0.4) 50%, rgba(255, 255, 255, 0) 100%); }

.queue-panel .queue-panel-item .order-card { transition: all 150ms ease-out; }

.queue-panel .queue-panel-item.has-robot .order-card { position: relative; top: -5px; left: -5px; cursor: default; }

.queue-panel .queue-panel-item.has-robot .order-card:hover { z-index: 10; }

.order-panel { height: 192px; padding: 41px 0px 0 27px; position: relative; background-position: center left; background-image: url(../img/shopping/orders-items2.png); }

.order-panel .order-panel-round { width: 42px; height: 26px; position: absolute; top: 5px; left: 195px; z-index: 5; text-align: center; text-shadow: none; font-size: 24px; line-height: 26px; font-weight: normal; }

.order-panel .order-panel-sum { width: 78px; height: 26px; position: absolute; top: 4px; left: 492px; z-index: 5; text-align: center; text-shadow: none; font-size: 20px; line-height: 26px; font-weight: normal; }

.order-panel.over-budget:before { content: ''; display: block; width: 100%; height: 39px; position: absolute; top: 0; left: 0; background: url(../img/shopping/orders-over-budget.png) no-repeat 0 0; }

.order-panel.medium { padding-top: 46px; height: 348px; background-image: url(../img/shopping/orders-items4.png); }

.order-panel.long { padding-top: 46px; height: 505px; background-image: url(../img/shopping/orders-items6.png); }

.order-panel.items8 { padding-top: 46px; height: 661px; background-image: url(../img/shopping/orders-items8.png); }

.order-panel.items10 { padding-top: 46px; height: 817px; background-image: url(../img/shopping/orders-items10.png); }

.order-panel.items12 { padding-top: 46px; height: 973px; background-image: url(../img/shopping/orders-items12.png); }

.order-panel.empty { height: 35px; padding-top: 0; padding-bottom: 0; background-image: url(../img/shopping/orders-empty.png); }

.order-panel.empty .order-panel-sum { display: none; }

.order-panel.tutorial-fake-view { height: 192px !important; background-image: url(../img/tutorial-fakes/sales-queue.png) !important; }

.order-panel.tutorial-fake-view-media { background-image: url(../img/tutorial-fakes/sales-queue-media.png) !important; }

.order-panel.tutorial-fake-view-media .fake-cancel { visibility: hidden !important; }

.order-panel.tutorial-fake-view > * { visibility: hidden; }

.order-panel.tutorial-fake-view .fake-cancel { width: 162px; height: 35px; position: absolute; top: 97px; left: 343px; background: url(../img/tutorial-fakes/sales-queue-cancel.png) no-repeat -343px -97px; }

.order-panel.tutorial-fake-view .fake-cancel.is-visible { visibility: visible; }

.order-panel .order-panel-sum.introjs-showElement { visibility: visible; font-size: 0; background: url(../img/tutorial-fakes/sales-queue.png) no-repeat -520px -5px; }

.order-panel .order-panel-item { float: left; margin-right: 14px; margin-top: 7px; margin-bottom: 7px; position: relative; }

.stock-panel { height: 194px; position: relative; }

.stock-panel .round-number { width: 44px; height: 28px; position: absolute; top: 5px; left: 193px; z-index: 150; font-size: 24px; line-height: 28px; text-align: center; font-weight: normal; text-shadow: none; }

.stock-panel.day-1 .round-number { display: none; }

.order-panel-container { height: 581px; }

.order-panel-container .order-panel { margin-right: 0; }

.order-panel-slider { overflow: hidden; height: 192px; position: relative; }

.order-panel-slider .order-panel-slider-inner { padding: 0; overflow-x: auto; white-space: nowrap; margin: 0 3px; }

.order-panel-slider .order-panel-slider-inner-cards { padding: 50px 22px 0 22px; white-space: nowrap; }

.order-panel-slider .offer { display: inline-block; white-space: normal; margin: 0 5px; }

.button-make-offer { width: 88px; height: 117px; position: absolute; top: 29px; left: 5px; opacity: 0; font-size: 0; background: url(../img/sell/make-offer.png) no-repeat 0 0; transition: all 150ms ease-in-out; }

.button-make-offer.tutorial-tmp-visible { display: block !important; opacity: 1 !important; pointer-events: none !important; box-shadow: 0 0 10px 5px rgba(178, 255, 30, 0.5); }

.button-make-offer:hover { background-image: url(../img/sell/make-offer-hover.png); }

.button-make-offer:active { background-image: url(../img/sell/make-offer-active.png); }

.button-make-offer:hover { background-image: url(../img/sell/make-offer-hover.png); }

.button-make-offer:active { background-image: url(../img/sell/make-offer-active.png); }

.order-card .button-make-offer:hover { opacity: 1; }

.button-check-robot { width: 257px; height: 50px; margin: 0 auto; opacity: 0; font-size: 0; background: url(../img/sell/check-robot.png) no-repeat 0 0; transition: opacity 150ms ease-in-out; }

.button-check-robot:hover { background-image: url(../img/sell/check-robot-hover.png); }

.button-check-robot:active { background-image: url(../img/sell/check-robot-active.png); }

.robot-card:hover .button-check-robot { opacity: 1; }

.button-send-robot { width: 257px; height: 50px; position: absolute; top: 45px; left: 11px; opacity: 0; font-size: 0; background: url(../img/sell/send-robot.png) no-repeat 0 0; transition: all 150ms ease-in-out; }

.button-send-robot:hover { background-image: url(../img/sell/send-robot-hover.png); }

.button-send-robot:active { background-image: url(../img/sell/send-robot-active.png); }

.queue-panel-item:hover .button-send-robot { opacity: 1; }

.components { cursor: pointer; height: 387px; }

.components-panel { height: 387px; width: 0px; position: absolute; right: 100%; transition: all 250ms cubic-bezier(0.86, 0, 0.07, 1); top: 0px; z-index: 200; }

.components-panel.open { width: 900px; height: 387px; }

.components-placeholder { height: 155px; }

.components-placeholder-night { height: 155px; }

.binder-placeholder { height: 80px; }

.binder-placeholder-night { height: 80px; }

.machine-tiredness { height: 40px; }

.machine-tiredness-circle { height: 40px; }

.machine-tiredness-triangle { height: 40px; }

.machine-tiredness-square { height: 40px; }

.machine-tiredness-100 { height: 40px; }

.machine-tiredness-90 { height: 40px; }

.machine-tiredness-80 { height: 40px; }

.machine-tiredness-70 { height: 40px; }

.machine-tiredness-60 { height: 40px; }

.machine-tiredness-50 { height: 40px; }

.machine-tiredness-40 { height: 40px; }

.machine-tiredness-30 { height: 40px; }

.machine-tiredness-20 { height: 40px; }

.material-count { text-align: center; }

.inactive { cursor: pointer; }

.panel-close-layer { position: fixed; top: 0; left: 0; right: 0; bottom: 0; background: rgba(0, 0, 0, 0.5); z-index: 100; }

.report-popup { width: 100%; height: 100%; position: fixed; top: 0; left: 0; z-index: 900; display: block; }

.report-popup .summary-popup-bg { position: fixed; top: 0; left: 0; right: 0; bottom: 0; background: rgba(0, 0, 0, 0.7); z-index: 1; animation: appear 300ms ease-out; }

.report-popup .back { width: 163px; height: 36px; position: absolute; bottom: 18px; right: 18px; }

.report-popup .show-report-players { display: block; width: 261px; height: 36px; position: absolute; bottom: 18px; left: 50%; transform: translateX(-50%); }

.report-popup .report-popup-content { width: 1151px; height: 662px; padding: 45px 20px; position: absolute; top: 50%; left: 50%; margin: -331px 0 0 -575px; z-index: 10; display: block; animation: appear-pop 400ms ease-out; }

.report-popup .summary-report { padding: 0 10px; height: 530px; color: #fff; position: relative; z-index: 10; }

.report-popup .summary-report .nano-content { padding: 0 25px; }

.report-popup .ended-buttons { display: flex; align-items: center; justify-content: center; position: absolute; left: 0; right: 0; bottom: 15px; font-size: 18px; color: #fff; text-align: center; }

.report-popup .ended-buttons-group { display: flex; flex-direction: column; align-items: center; padding: 0 10px; }

.report-popup .ended-buttons-label { display: block; padding: 5px 10px; }

.report-popup .ended-buttons .button-show { display: block; width: 261px; height: 36px; background: url(../img/trainer/report-show.png) no-repeat center; }

.report-popup .ended-buttons .button-show:hover { background-image: url(../img/trainer/report-show-hover.png); }

.report-popup .ended-buttons .button-show:active { background-image: url(../img/trainer/report-show-active.png); }

.report-popup .ended-buttons .button-show.hide { background: url(../img/trainer/report-hide.png) no-repeat center; }

.report-popup .ended-buttons .button-show.hide:hover { background-image: url(../img/trainer/report-hide-hover.png); }

.report-popup .ended-buttons .button-show.hide:active { background-image: url(../img/trainer/report-hide-active.png); }

.report-popup .ended-buttons .button-download { display: block; width: 163px; height: 36px; background: url(../img/trainer/download.png) no-repeat center; }

.report-popup .ended-buttons .button-download:hover { background-image: url(../img/trainer/download-hover.png); }

.report-popup .ended-buttons .button-download:active { background-image: url(../img/trainer/download-active.png); }

.report-popup table { width: 100%; border: 4px solid #000; }

.report-popup table thead { background: url(../img/trainer/report-h-bg.png); }

.report-popup table thead th { font-size: 18px; padding: 2px 5px; }

.report-popup table thead tr:first-child th:nth-child(2) { background: url(../img/trainer/report-h-green-bg.png); }

.report-popup table thead tr:first-child th:nth-child(3) { background: url(../img/trainer/report-h-red-bg.png); }

.report-popup table thead tr:nth-child(2) { border-bottom: 4px solid #000; }

.report-popup table thead tr:nth-child(2) th { font-size: 14px; background: url(../img/trainer/report-h-red-bg.png); }

.report-popup table td, .report-popup table th { border: 2px solid #fff; padding: 5px; vertical-align: middle; text-align: center; }

.report-popup table tbody tr:nth-child(2n) { background: rgba(255, 255, 255, 0.15); }

.report-popup table tbody td { font-size: 24px; }

.report-popup table tbody td:first-child { font-size: 36px; padding: 2px 5px; }

.report-popup table tfoot td { padding: 5px 5px; font-size: 24px; }

.report-popup table tfoot td:nth-child(1) { opacity: 0; }

.report-popup table tfoot td:nth-child(2) { border-top: 4px solid #000; background: url(../img/trainer/report-h-bg.png); font-size: 18px; }

.report-popup table tfoot td:nth-child(3) { border-top: 4px solid #000; }

.rejected-popup { width: 100%; height: 100%; position: fixed; top: 0; left: 0; z-index: 900; display: block; text-shadow: none; }

.rejected-popup .rejected-popup-bg { position: fixed; top: 0; left: 0; right: 0; bottom: 0; background: rgba(0, 0, 0, 0.7); z-index: 1; animation: appear 300ms ease-out; }

.rejected-popup .rejected-popup-content { width: 497px; height: 248px; padding: 30px 30px; position: absolute; top: 50%; left: 50%; margin: -124px 0 0 -248px; z-index: 10; display: block; animation: appear-pop 400ms ease-out; background: url(../img/common-popup-small.png) no-repeat 0 0; }

.rejected-popup h1 { margin-bottom: 10px; font-size: 24px; font-weight: bold; text-align: center; }

.rejected-popup ul li:before { content: '- '; }

.rejected-popup .button-options { position: absolute; bottom: 20px; left: 30px; right: 30px; }

.rejected-popup .button-options li { width: 50%; padding: 0 10px; float: left; text-align: center; }

.rejected-popup .button-options li:before { content: ''; }

.rejected-popup .button-options li .button-label { display: flex; width: 100%; height: 38px; align-items: center; }

.rejected-popup .button-options .button { display: block; width: 56px; height: 37px; margin: 10px auto 0; font-size: 0; cursor: pointer; }

.rejected-popup .button-options .button--ok { background: url(../img/trainer/sell-ok.png) no-repeat 0 0; }

.rejected-popup .button-options .button--ok:hover { background-image: url(../img/trainer/sell-ok-hover.png); }

.rejected-popup .button-options .button--ok:active { background-image: url(../img/trainer/sell-ok-active.png); }

.rejected-popup .button-options .button--cancel { background: url(../img/trainer/sell-nope.png) no-repeat 0 0; }

.rejected-popup .button-options .button--cancel:hover { background-image: url(../img/trainer/sell-nope-hover.png); }

.rejected-popup .button-options .button--cancel:active { background-image: url(../img/trainer/sell-nope-active.png); }

.summary-popup { width: 100%; height: 100%; position: fixed; top: 0; left: 0; z-index: 900; display: block; text-shadow: none; }

.summary-popup .summary-popup-bg { position: fixed; top: 0; left: 0; right: 0; bottom: 0; background: rgba(0, 0, 0, 0.7); z-index: 1; animation: appear 300ms ease-out; cursor: default; }

.summary-popup .summary-popup-bg.can-close { cursor: pointer; }

.summary-popup .summary-popup-content { width: 1200px; height: 700px; padding: 40px 40px; position: absolute; top: 50%; left: 50%; margin: -350px 0 0 -600px; z-index: 10; display: block; animation: appear-pop 400ms ease-out; }

.summary-popup.ng-hide .summary-popup-content { transform: scale(0.9); opacity: 0; }

.summary-popup .back { width: 163px; height: 36px; position: absolute; bottom: 20px; right: 30px; }

.summary-popup .summary-left { width: 325px; height: 620px; float: left; border-right: 1px solid rgba(255, 255, 255, 0.3); }

.summary-popup .summary-left .panel { height: 399px; background-image: url(../img/trainer/panel-left.png); padding: 20px 10px; }

.summary-popup .summary-right { width: 765px; height: 590px; float: right; }

.summary-popup .finance-summary { height: 80px; background-image: url(../img/trainer/finance-summary.png); text-align: center; color: #fff; font-size: 30px; font-weight: bold; text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.75); padding-top: 32px; margin-bottom: 26px; }

.summary-popup h2 { text-shadow: none; font-size: 24px; line-height: 38px; font-weight: bold; font-family: 'play', sans-serif; }

.summary-popup .finance-summary, .basic .summary-popup .finance-summary { width: 342px; height: 75px; background: none; padding: 8px 0 0; margin: 0; position: absolute; left: 12px; bottom: 12px; }

.summary-popup .finance-summary .label, .basic .summary-popup .finance-summary .label { display: block; font-size: 24px; font-weight: bold; line-height: 1; }

.summary-popup .finance-summary .value, .basic .summary-popup .finance-summary .value { display: block; font-size: 36px; font-weight: bold; line-height: 1; }

.summary-popup .boards-results { width: 340px; margin: 5px 0 0 -30px; }

.summary-popup .boards-results h2 { line-height: 38px; text-align: center; }

.summary-popup .boards-results .results-container { margin-left: 38px; }

.summary-popup .bonus-chart { width: 340px; margin: -26px 0 0 -30px; }

.summary-popup .bonus-chart h2 { line-height: 38px; text-align: center; }

.summary-popup .bonus-chart table { width: 100%; text-align: center; }

.summary-popup .bonus-chart table th, .summary-popup .bonus-chart table td { vertical-align: middle; padding: 0 10px; }

.summary-popup .bonus-chart table th { font-size: 16px; font-weight: normal; line-height: 1; }

.summary-popup .bonus-chart table td { height: 30px; }

.summary-popup .bonus-chart table td:first-child { width: 230px; }

.summary-popup .bonus-chart table td:last-child { width: 112px; }

.summary-popup .bonus-chart table thead th { height: 42px; }

.summary-popup .bonus-chart table tfoot th { height: 22px; }

.summary-popup .results-container { margin-bottom: 3px; margin-left: 22px; }

.summary-popup .results-container.big-gutter { margin-top: 45px; }

.summary-popup .results-container > div { float: left; height: 34px; width: 183px; }

.summary-popup .results-container > input { float: left; width: 92px; height: 34px; background: url(../img/trainer/results-input.png) no-repeat center; border: 0px; text-align: center; line-height: 34px; font-size: 16px; font-weight: bold; }

.summary-popup .results-production { background-image: url(../img/trainer/results-production.png); }

.summary-popup .results-assemblyA { background-image: url(../img/trainer/results-assembly1.png); }

.summary-popup .results-assemblyB { background-image: url(../img/trainer/results-assembly2.png); }

.summary-popup .results-sales { background-image: url(../img/trainer/results-sell.png); }

.summary-popup .results-quality { background-image: url(../img/trainer/results-quality.png); }

.summary-popup .results-marketing { background-image: url(../img/trainer/results-marketing.png); }

.summary-popup .results-supply { background-image: url(../img/trainer/results-shopping.png); }

.summary-popup .results-failure { background-image: url(../img/trainer/results-failure.png); }

.summary-popup .results-costs { background-image: url(../img/trainer/results-costs.png); }

.summary-popup .summary-recipe-item-wrapper { margin-right: 15px; }

.summary-popup .summary-recipe-counters { overflow: hidden; width: 221px; padding: 0 5px 10px; }

.summary-popup .counters-label { float: left; width: 75%; }

.summary-popup .counters-value { float: right; width: 25%; text-align: right; }

.summary-popup .summary-materials { overflow: hidden; padding: 0; margin: -26px 0; text-align: left; }

.summary-popup .summary-material { display: block; width: 510px; padding: 18px 40px; border-bottom: 1px solid rgba(255, 255, 255, 0.5); text-align: left; overflow: hidden; text-shadow: none; }

.summary-popup .summary-material h4 { margin-bottom: 5px; }

.summary-popup .summary-material figure { float: left; width: 125px; }

.summary-popup .summary-material figure .material { display: inline-block; margin: 0 -7px; }

.summary-popup .summary-material ul { float: left; width: 305px; font-weight: normal; font-size: 16px; line-height: 20px; margin-top: 10px; }

.summary-popup .summary-material:last-child { border: 0; }

.summary-popup .summary-assembly { overflow: hidden; padding: 0; margin: -26px 0; text-align: left; position: relative; }

.summary-popup .summary-assembly h2 { margin-bottom: 5px; }

.summary-popup .summary-assembly .h-sum { position: absolute; width: 125px; text-align: center; top: 0; right: 0; }

.summary-popup .summary-assembly .summary-assembly-row { overflow: hidden; border-bottom: 1px solid rgba(255, 255, 255, 0.6); }

.summary-popup .summary-assembly .summary-assembly-row:last-child { border-bottom: 0; }

.summary-popup .summary-assembly .summary-assembly-row img { display: block; }

.summary-popup .summary-assembly .summary-assembly-row .value { font-size: 16px; line-height: 1.35; font-weight: normal; text-shadow: none; }

.summary-popup .summary-assembly .summary-assembly-row .column-heading { float: left; width: 230px; padding: 15px 0 13px; border-right: 1px solid rgba(255, 255, 255, 0.6); }

.summary-popup .summary-assembly .summary-assembly-row .column-heading figure { margin: 0 0 5px; }

.summary-popup .summary-assembly .summary-assembly-row .column-single { float: left; width: 80px; padding: 15px 0 13px; text-align: center; border-right: 1px solid rgba(255, 255, 255, 0.6); }

.summary-popup .summary-assembly .summary-assembly-row .column-single figure { margin: 5px 0 10px; }

.summary-popup .summary-assembly .summary-assembly-row .column-single img { margin: 0 auto; }

.summary-popup .summary-assembly .summary-assembly-row .column-double { float: left; width: 170px; padding: 15px 0 13px; text-align: center; border-right: 1px solid rgba(255, 255, 255, 0.6); }

.summary-popup .summary-assembly .summary-assembly-row .column-double figure { margin: 5px 0 10px; position: relative; font-size: 0; }

.summary-popup .summary-assembly .summary-assembly-row .column-double figure:after { content: '+'; display: block; font-size: 40px; font-weight: bold; position: absolute; top: 6px; left: 50%; margin-left: -12px; }

.summary-popup .summary-assembly .summary-assembly-row .column-double figure img { display: inline-block; margin: 0 4px; }

.summary-popup .summary-assembly .summary-assembly-row .column-sum { float: left; width: 125px; padding: 88px 0 13px; text-align: center; }

.summary-popup .summary-production { overflow: hidden; padding: 0; margin: -26px -20px; text-align: left; position: relative; }

.summary-popup .summary-production h2 { margin-left: 20px; margin-bottom: -5px; }

.summary-popup .summary-production .robots-recipe-item-wrapper { width: 50%; margin: 0; padding: 8px 0; border-bottom: 1px solid rgba(255, 255, 255, 0.6); overflow: hidden; opacity: .3; }

.summary-popup .summary-production .robots-recipe-item-wrapper.is-available { opacity: 1; }

.summary-popup .summary-production .robots-recipe-item-wrapper:nth-last-child(1), .summary-popup .summary-production .robots-recipe-item-wrapper:nth-last-child(2) { border-bottom: 0; }

.summary-popup .summary-production .robots-recipe-item-wrapper .robots-recipe-item { float: left; width: 180px; }

.summary-popup .summary-production .robots-recipe-item-wrapper .summary-recipe-counters { float: left; width: 220px; padding: 52px 0 0; text-shadow: none; font-size: 16px; font-weight: normal; }

.summary-popup .summary-production .robots-recipe-item-wrapper img { display: block; }

.summary-popup .material { width: 60px; height: 60px; }

.summary-popup .material-1 { background-image: url(../img/production/material-1.png); }

.summary-popup .material-1.small { background-image: url(../img/production/material-1-small.png); }

.summary-popup .material-2 { background-image: url(../img/production/material-2.png); }

.summary-popup .material-2.small { background-image: url(../img/production/material-2-small.png); }

.summary-popup .material-3 { background-image: url(../img/production/material-3.png); }

.summary-popup .material-3.small { background-image: url(../img/production/material-3-small.png); }

.summary-popup .material-4 { background-image: url(../img/production/material-4.png); }

.summary-popup .material-4.small { background-image: url(../img/production/material-4-small.png); }

.summary-popup .material-5 { background-image: url(../img/production/material-5.png); }

.summary-popup .material-5.small { background-image: url(../img/production/material-5-small.png); }

.summary-popup .summary-media { overflow: hidden; padding: 15px 5px; margin: 15px 0; border-top: 1px solid rgba(255, 255, 255, 0.3); border-bottom: 1px solid rgba(255, 255, 255, 0.3); }

.summary-popup .summary-medium { width: 50%; float: left; padding: 4px 25px; }

.summary-popup .summary-medium .summary-medium-name { width: 70%; float: left; }

.summary-popup .summary-medium .summary-medium-amount { width: 15%; float: left; text-align: center; }

.summary-popup .summary-medium .summary-medium-price { width: 15%; float: left; text-align: right; }

.summary-popup .summary-binders { overflow: hidden; padding: 15px 5px; }

.summary-popup .summary-binder { width: 25%; float: left; text-align: center; padding: 0 10px; }

.summary-popup .summary-binder img { margin-bottom: 5px; width: 100%; }

.summary-popup .summary-sales h3 { font-size: 18px; line-height: 30px; text-shadow: none; }

.summary-popup .summary-sales .subheader { overflow: hidden; }

.summary-popup .summary-sales .subheader h3 { float: left; width: 285px; }

.summary-popup .summary-sales .empty-info { text-shadow: none; font-size: 18px; line-height: 30px; margin-bottom: 40px; }

.summary-popup .summary-sales-item { padding-bottom: 10px; margin-bottom: 10px; border-bottom: 1px solid rgba(255, 255, 255, 0.3); text-shadow: none; }

.summary-popup .summary-sales-item .order-card { float: left; margin-bottom: 0; }

.summary-popup .summary-sales-item .robot-card { float: left; margin-bottom: 0; margin-left: 10px; }

.summary-popup .summary-sales-item .item-info { width: 215px; float: left; margin-left: 10px; }

.summary-popup .summary-sales-item .item-info .item-info-row.sum { border-top: 1px solid rgba(255, 255, 255, 0.3); margin-top: 5px; padding-top: 5px; }

.summary-popup .summary-sales-item .item-info .item-info-text { margin-bottom: 15px; }

.summary-popup .summary-sales-item .item-info.item-info__status-badRobot .item-info-row:first-child .price, .summary-popup .summary-sales-item .item-info.item-info__status-badRobot_badTop .item-info-row:first-child .price, .summary-popup .summary-sales-item .item-info.item-info__status-badRobot_badBottom .item-info-row:first-child .price, .summary-popup .summary-sales-item .item-info.item-info__status-badRobot_badTop_badBottom .item-info-row:first-child .price { position: relative; }

.summary-popup .summary-sales-item .item-info.item-info__status-badRobot .item-info-row:first-child .price:after, .summary-popup .summary-sales-item .item-info.item-info__status-badRobot_badTop .item-info-row:first-child .price:after, .summary-popup .summary-sales-item .item-info.item-info__status-badRobot_badBottom .item-info-row:first-child .price:after, .summary-popup .summary-sales-item .item-info.item-info__status-badRobot_badTop_badBottom .item-info-row:first-child .price:after { content: ''; position: absolute; display: block; position: absolute; top: 50%; left: -4px; right: -4px; height: 2px; margin-top: -1px; background: #fff; box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.3); transform: rotate(10deg); }

.summary-popup .summary-sales-item .item-info .label { display: block; float: left; width: 160px; height: 21px; line-height: 21px; }

.summary-popup .summary-sales-item .item-info .price { display: block; float: right; width: 55px; height: 21px; line-height: 21px; text-align: right; white-space: nowrap; }

.cursor-default, .cursor-default[ng-click] { cursor: default; }

.offers-panel { display: none; width: 100%; height: 100%; position: fixed; top: 0; left: 0; overflow: hidden; z-index: 9999; background: rgba(0, 0, 0, 0.5); }

.offers-panel.introjs-fixParent { z-index: 9999999 !important; background: none !important; pointer-events: none; }

.offers-panel-content { display: block; width: 298px; height: 620px; position: absolute; top: 50%; left: 50%; margin-left: -149px; margin-top: -310px; overflow: hidden; z-index: 1000; }

.offers-panel.open { display: block; }

.offers-panel-inner { width: 298px; height: 620px; padding: 12px 0 0 20px; position: absolute; bottom: 0; }

.offers-panel-inner { height: 600px; bottom: 10px; padding: 10px 0 0 20px; overflow: hidden; }

.offers-panel-inner .offer { margin-bottom: 6px; }

.offer { width: 255px; height: 130px; position: relative; }

.offer.offer-small { width: 255px; height: 111px; }

.offer.offer-small .offer-number { font-size: 12px; width: 32px; }

.offer.offer-small .offer-date { font-size: 12px; width: 32px; }

.offer.fake-no-props .offer-info-1, .offer.fake-no-props .offer-info-2 { font-size: 0; }

.offer.fake-no-props .offer-info-1:after, .offer.fake-no-props .offer-info-2:after { content: ' ?'; font-size: 14px; }

.offer .offer-number { font-size: 14px; line-height: 28px; position: absolute; top: 0px; left: 3px; width: 35px; text-align: center; text-shadow: none; cursor: default; }

.offer .offer-date { width: 35px; position: absolute; right: 3px; top: 0; font-size: 14px; line-height: 28px; text-transform: uppercase; text-align: center; text-shadow: none; cursor: default; }

.offer .offer-once, .offer .offer-cycle { font-size: 24px; line-height: 28px; width: 56px; position: absolute; top: 45px; text-align: center; text-shadow: none; }

.offer .offer-once { left: 43px; }

.offer .offer-cycle { right: 43px; }

.offer .offer-cycle-caption { font-weight: bold; width: 90px; position: absolute; right: 23px; top: 77px; font-size: 11px; line-height: 11px; text-align: center; text-shadow: none; font-weight: normal; }

.offer .offer-quality { width: 100%; position: absolute; left: 0; bottom: 0; font-size: 11px; line-height: 28px; text-align: center; }

.offer .offer-quality strong { font-weight: inherit; text-transform: uppercase; }

.offer .offer-info { display: none; position: absolute; left: 12px; right: 12px; bottom: 4px; font-size: 14px; line-height: 18px; cursor: default; }

.offer .offer-info .offer-info-1 { float: left; }

.offer .offer-info .offer-info-2 { float: right; }

.offer .offer-info .label { font-size: 11px; font-weight: normal; vertical-align: baseline; }

.offer.offer-stock .offer-info { display: block; }

.offer .offer-ordered { display: none; }

.offer .comment { width: 25px; height: 25px; position: absolute; top: 47px; right: 5px; }

.offer .comment .comment-icon { width: 25px; height: 25px; background: url(../img/shopping/add-note.png) no-repeat 0 0; cursor: pointer; }

.offer .comment .comment-text { display: none; width: 220px; min-height: 14px; line-height: 14px; position: absolute; bottom: 30px; right: 0; z-index: 1000; color: #fff; font-size: 10px; font-weight: normal; }

.offer .comment .value { display: none; }

.offer .comment .placeholder, .offer .comment .value { padding: 4px; max-width: 220px; min-width: 50px; float: right; background: rgba(0, 0, 0, 0.9); text-align: left; }

.offer .comment:hover, .offer .comment.is-edit-mode { z-index: 1500; }

.offer .comment:hover .comment-text, .offer .comment.is-edit-mode .comment-text { display: block; }

.offer .comment.is-edit-mode .placeholder, .offer .comment.is-view-mode .placeholder, .offer .comment.is-active .placeholder { display: none; }

.offer .comment.is-edit-mode .value, .offer .comment.is-view-mode .value, .offer .comment.is-active .value { display: block; }

.offer .comment.is-readonly .comment-icon, .offer .comment.is-readonly.is-active .comment-icon { background: url(../img/shopping/add-note-active.png) no-repeat 0 0; }

.offer .comment.is-active .comment-icon { background: url(../img/shopping/add-note-active.png) no-repeat 0 0; }

.offer .comment.item-comment { top: 205px; margin-left: 199px; }

.offer .buy { border: 0; background: none; background-image: url(../img/sprite-shopping.png); background-position: 0px -412px; width: 124px; height: 29px; position: absolute; left: 3px; bottom: 3px; outline: 0; }

.offer .buy:hover { background-image: url(../img/sprite-shopping.png); background-position: -378px -381px; width: 124px; height: 29px; }

.offer .buy:active { background-image: url(../img/sprite-shopping.png); background-position: -252px -350px; width: 124px; height: 29px; }

.offer.offer-stock .buy { border: 0; background: none; background-image: url(../img/sprite-shopping.png); background-position: 0px -412px; width: 124px; height: 29px; position: absolute; left: 3px; bottom: 28px; outline: 0; }

.offer.offer-stock .buy:hover { background-image: url(../img/sprite-shopping.png); background-position: -378px -381px; width: 124px; height: 29px; }

.offer.offer-stock .buy:active { background-image: url(../img/sprite-shopping.png); background-position: -252px -350px; width: 124px; height: 29px; }

.offer .buy-express { border: 0; background: none; background-image: url(../img/sprite-shopping.png); background-position: -126px -412px; width: 124px; height: 29px; position: absolute; right: 3px; bottom: 3px; outline: 0; }

.offer .buy-express:hover { background-image: url(../img/sprite-shopping.png); background-position: 0px -350px; width: 124px; height: 29px; }

.offer .buy-express:active { background-image: url(../img/sprite-shopping.png); background-position: -450px -312px; width: 124px; height: 29px; }

.offer .check { border: 0; background: none; background-image: url(../img/sprite-shopping.png); background-position: -252px -381px; width: 124px; height: 29px; position: absolute; right: 3px; bottom: 28px; outline: 0; }

.offer .check:hover { background-image: url(../img/sprite-shopping.png); background-position: -367px -273px; width: 124px; height: 29px; }

.offer .check:active { background-image: url(../img/sprite-shopping.png); background-position: -126px -350px; width: 124px; height: 29px; }

.offer .cancel { width: 163px; height: 35px; margin: -17px 0 0 -81px; border: 0; background: url(../img/shopping/resign.png) no-repeat 0 0; position: absolute; left: 50%; top: 50%; outline: 0; opacity: 0; }

.offer .cancel:hover { background-image: url(../img/shopping/resign-hover.png); }

.offer .cancel:active { background-image: url(../img/shopping/resign-active.png); }

.offer:hover .cancel { opacity: 1; }

.offer .buy, .offer .buy-express, .offer .check { opacity: 0; transition: opacity 250ms ease-out; }

.offer:hover .buy, .offer:hover .buy-express, .offer:hover .check { opacity: 1; }

.offer .check-info { position: absolute; width: 100%; height: 47px; background: url(../img/shopping/stock-contact.png) no-repeat center; bottom: 0px; left: 0px; right: 0px; display: none; }

.offer .check-info.active { display: block; }

.offer.offer-material-stock-1 { background-image: url(../img/shopping/materials/01.png); }

.offer.offer-material-stock-1 .badge { background-image: url(../img/shopping/badge-red.png); }

.offer.offer-material-stock-2 { background-image: url(../img/shopping/materials/02.png); }

.offer.offer-material-stock-2 .badge { background-image: url(../img/shopping/badge-blue.png); }

.offer.offer-material-stock-3 { background-image: url(../img/shopping/materials/03.png); }

.offer.offer-material-stock-3 .badge { background-image: url(../img/shopping/badge-green.png); }

.offer.offer-material-stock-4 { background-image: url(../img/shopping/materials/04.png); }

.offer.offer-material-stock-4 .badge { background-image: url(../img/shopping/badge-violet.png); }

.offer.offer-material-stock-5 { background-image: url(../img/shopping/materials/05.png); }

.offer.offer-material-stock-5 .badge { background-image: url(../img/shopping/badge-yellow.png); }

.offer .badge { display: inline-block; width: 63px; height: 63px; overflow: hidden; line-height: 63px; text-align: center; background-repeat: no-repeat; font-size: 32px; font-weight: bold; }

.offer .badges { position: absolute; top: 50%; left: 50%; width: 199px; height: 63px; overflow: hidden; line-height: 63px; text-align: center; background-repeat: no-repeat; margin: -32px 0 0 -99px; font-size: 32px; font-weight: bold; font-size: 0; }

.offer .badges[data-cycles-done="1"] .badge:nth-child(1) { opacity: .5; }

.offer .badges[data-cycles-done="2"] .badge:nth-child(1) { opacity: .5; }

.offer .badges[data-cycles-done="2"] .badge:nth-child(2) { opacity: .5; }

.offer .badges[data-cycles-done="3"] .badge:nth-child(1) { opacity: .5; }

.offer .badges[data-cycles-done="3"] .badge:nth-child(2) { opacity: .5; }

.offer .badges[data-cycles-done="3"] .badge:nth-child(3) { opacity: .5; }

.offer.is-ordered.offer-material-1 { background-image: url(../img/shopping/materials/01-empty.png); }

.offer.is-ordered.offer-material-2 { background-image: url(../img/shopping/materials/02-empty.png); }

.offer.is-ordered.offer-material-3 { background-image: url(../img/shopping/materials/03-empty.png); }

.offer.is-ordered.offer-material-4 { background-image: url(../img/shopping/materials/04-empty.png); }

.offer.is-ordered.offer-material-5 { background-image: url(../img/shopping/materials/05-empty.png); }

.offer.is-ordered.offer-material-1:after, .offer.is-ordered.offer-material-2:after, .offer.is-ordered.offer-material-3:after, .offer.is-ordered.offer-material-4:after, .offer.is-ordered.offer-material-5:after { content: 'Surowiec od stałego dostawcy - DOBRA jakość'; display: block; position: absolute; left: 0; right: 0; bottom: 8px; font-size: 11px; text-align: center; }

.offer.is-ordered.offer-material-stock-1 { background-image: url(../img/shopping/materials/01-empty.png); }

.offer.is-ordered.offer-material-stock-2 { background-image: url(../img/shopping/materials/02-empty.png); }

.offer.is-ordered.offer-material-stock-3 { background-image: url(../img/shopping/materials/03-empty.png); }

.offer.is-ordered.offer-material-stock-4 { background-image: url(../img/shopping/materials/04-empty.png); }

.offer.is-ordered.offer-material-stock-5 { background-image: url(../img/shopping/materials/05-empty.png); }

.offer.is-ordered .offer-ordered { width: 100%; display: block; display: flex; height: 100px; padding-top: 25px; align-items: center; justify-content: center; }

.offer.is-ordered .offer-ordered table { width: 50%; }

.offer.is-ordered .offer-ordered td { width: 50%; line-height: 24px; }

.offer.is-ordered .offer-ordered td:first-child { text-align: right; font-size: 11px; }

.offer.is-ordered .offer-ordered td:last-child { margin-left: 10px; text-align: center; font-size: 22px; font-weight: 600; }

.offer.is-ordered .offer-ordered td .money-bill { display: inline-block; width: 54px; height: 28px; background: url(../img/shopping/materials/materials-dollar.png) no-repeat center; font-weight: bold; line-height: 27px; font-size: 19px; text-shadow: none; }

.offer.is-ordered .offer-once { display: none; }

.offer.is-ordered .offer-cycle { display: none; }

.offer.is-ordered .offer-cycle-caption { display: none; }

.offer .offer-comment.comment:hover { z-index: 99; }

.media-card { width: 255px; height: 130px; background-repeat: no-repeat; background-position: 0 0; position: relative; }

.media-card--newspaper { background-image: url(../img/shopping/offer-media-1.png); }

.media-card--newspaper.layout--marketing { background-image: url(../img/marketing/media-popup/marketing-media-1.png); }

.media-card--tv { background-image: url(../img/shopping/offer-media-2.png); }

.media-card--tv.layout--marketing { background-image: url(../img/marketing/media-popup/marketing-media-2.png); }

.media-card--flyers { background-image: url(../img/shopping/offer-media-3.png); }

.media-card--flyers.layout--marketing { background-image: url(../img/marketing/media-popup/marketing-media-3.png); }

.media-card--billboards { background-image: url(../img/shopping/offer-media-4.png); }

.media-card--billboards.layout--marketing { background-image: url(../img/marketing/media-popup/marketing-media-4.png); }

.media-card--internet { background-image: url(../img/shopping/offer-media-5.png); }

.media-card--internet.layout--marketing { background-image: url(../img/marketing/media-popup/marketing-media-5.png); }

.media-card--radio { background-image: url(../img/shopping/offer-media-6.png); }

.media-card--radio.layout--marketing { background-image: url(../img/marketing/media-popup/marketing-media-6.png); }

.media-card .option { display: block; width: 23px; position: absolute; left: 6px; top: 3px; font-size: 20px; font-weight: bold; text-align: center; }

.media-card .cost { width: 61px; position: absolute; top: 42px; left: 150px; font-size: 24px; text-shadow: none; text-align: center; }

.media-card .points { width: 125px; position: absolute; top: 42px; right: 12px; font-size: 24px; text-shadow: none; text-align: center; }

.media-card .round { width: 125px; position: absolute; top: 93px; right: 12px; font-size: 24px; text-shadow: none; text-align: center; }

.media-card .label { width: 125px; position: absolute; left: 8px; bottom: 8px; font-size: 14px; text-shadow: none; text-align: center; }

.media-card.layout--marketing .points { right: 20px; }

.media-card.layout--marketing .round { right: 20px; top: 95px; }

.media-card.layout--marketing .label { width: 80px; left: 16px; bottom: auto; top: 90px; }

.media-card .cancel { width: 163px; height: 35px; margin: -17px 0 0 -81px; border: 0; background: url(../img/shopping/resign.png) no-repeat 0 0; position: absolute; left: 50%; top: 50%; outline: 0; opacity: 0; }

.media-card .cancel:hover { background-image: url(../img/shopping/resign-hover.png); }

.media-card .cancel:active { background-image: url(../img/shopping/resign-active.png); }

.media-card .launch { width: 163px; height: 35px; margin: -17px 0 0 -81px; border: 0; background: url(../img/marketing/media-launch.png) no-repeat 0 0; position: absolute; left: 50%; top: 50%; outline: 0; opacity: 0; }

.media-card .launch .launch-overlay { display: none; }

.media-card .launch:hover { background-image: url(../img/marketing/media-launch-hover.png); }

.media-card .launch:hover .launch-overlay { display: block; animation: appear 300ms ease-out; }

.media-card:hover .cancel { opacity: 1; }

.media-card:hover .launch { opacity: 1; }

.nano > .nano-pane { width: 19px; }

.nano > .nano-pane > .nano-slider { width: 18px; }

.nano > .nano-pane > .nano-slider:after { display: block; content: ''; position: absolute; top: 4px; bottom: 4px; width: 18px; }

/* DIVISION : ASSEMBLY */
.assembly .cover-bg { background-image: url(../img/assembly/bg.jpg); }

.assembly .depertment-name { background-image: url(../img/assembly/department-name.png); }

.assembly .depertment-name--A { background-image: url(../img/assembly/department-name-1.png); }

.assembly .depertment-name--B { background-image: url(../img/assembly/department-name-2.png); }

.assembly .timer { background-image: url(../img/assembly/counter.png); }

.assembly .actions { background-image: url(../img/assembly/actions.png); }

.assembly .actions:hover { background-image: url(../img/assembly/actions-hover.png); }

.assembly .actions.active { background-image: url(../img/assembly/actions-active.png); }

.assembly .actions-panel { background-image: url(../img/assembly/actions-panel.png); }

.assembly .end-round { background-image: url(../img/assembly/end-round.png); }

.assembly .end-round:hover { background-image: url(../img/assembly/end-round-hover.png); }

.assembly .end-round:active { background-image: url(../img/assembly/end-round-active.png); }

.assembly .summary { background-image: url(../img/assembly/summary.png); }

.assembly .summary:hover { background-image: url(../img/assembly/summary-hover.png); }

.assembly .summary:active { background-image: url(../img/assembly/summary-active.png); }

.assembly .report { background-image: url(../img/assembly/report.png); }

.assembly .report:hover { background-image: url(../img/assembly/report-hover.png); }

.assembly .report:active { background-image: url(../img/assembly/report-active.png); }

.assembly .summary-popup-content { background: url(../img/assembly/summary-popup.png) no-repeat center; }

.assembly .summary-popup .back { background: url(../img/assembly/summary-back.png) no-repeat center; }

.assembly .summary-popup .back:hover { background-image: url(../img/assembly/summary-back-hover.png); }

.assembly .summary-popup .back:active { background-image: url(../img/assembly/summary-back-active.png); }

.assembly .machine-1 { background-image: url(../img/assembly/machine-mini-1.png); }

.assembly .machine-2 { background-image: url(../img/assembly/machine-mini-2.png); }

.assembly .number-1 { background-image: url(../img/assembly/number-1.png); }

.assembly .number-2 { background-image: url(../img/assembly/number-2.png); }

.assembly .number-3 { background-image: url(../img/assembly/number-3.png); }

.assembly .number-4 { background-image: url(../img/assembly/number-4.png); }

.assembly .number-5 { background-image: url(../img/assembly/number-5.png); }

.assembly .number-6 { background-image: url(../img/assembly/number-6.png); }

.assembly .number-7 { background-image: url(../img/assembly/number-7.png); }

.assembly .number-8 { background-image: url(../img/assembly/number-8.png); }

.assembly .number-9 { background-image: url(../img/assembly/number-9.png); }

.assembly .number-10 { background-image: url(../img/assembly/number-10.png); }

.assembly .number-11 { background-image: url(../img/assembly/number-11.png); }

.assembly .number-12 { background-image: url(../img/assembly/number-12.png); }

.assembly .recipe-placeholder { background-image: url(../img/assembly/recipe-placeholder.png); }

.assembly .recipe-placeholder.active { background-image: url(../img/assembly/recipe-placeholder-active.png); }

.assembly .recipe-placeholder-small { background-image: url(../img/assembly/recipe-placeholder-small.png); height: 160px; text-align: center; padding-top: 5px; }

/*.assembly .recipe-placeholder.filled { background-image: url(../img/assembly/recipe-placeholder-active.png); }*/
.assembly .robots-recipe { background-image: url(../img/assembly/robots-recipe.png); }

.assembly .robots-recipe:hover { background-image: url(../img/assembly/robots-recipe-hover.png); }

.assembly .robots-recipe.active { background-image: url(../img/assembly/robots-recipe-active.png); }

.assembly .robots-recipe-panel { background: url(../img/assembly/components-panel.png) center right 19px no-repeat; }

.assembly .components-head-panel:after, .assembly .components-legs-panel:after, .assembly .components-binders-panel:after, .assembly .robots-recipe-panel:after { content: ''; display: block; position: absolute; left: 0px; right: 22px; top: 0px; bottom: 0px; pointer-events: none; }

.assembly .robots-recipe-panel:after { background: url(../img/assembly/components-panel-after.png) center right no-repeat; }

.assembly .components-head-panel:after { background: url(../img/assembly/components-panel-head-after.png) center left no-repeat; }

.assembly .components-legs-panel:after { background: url(../img/assembly/components-panel-legs-after.png) center left no-repeat; }

.assembly .components-binders-panel:after { background: url(../img/assembly/components-panel-binders-after.png) center left no-repeat; right: 0; }

.assembly .robots-recipe-panel-inner { height: 100%; padding: 5px 0px; }

.assembly .components { background-image: url(../img/assembly/components.png); }

.assembly .components:hover { background-image: url(../img/assembly/components-hover.png); }

.assembly .components.active { background-image: url(../img/assembly/components-active.png); }

.assembly .components-panel { background: url(../img/assembly/components-panel.png) no-repeat left center; }

.assembly .components-panel-noscroll { background: url(../img/assembly/components-panel-noscroll.png) no-repeat left center; }

.assembly .binders-free { width: 743px; height: 29px; background: url(../img/assembly/binders-free.png) no-repeat center; margin: 15px auto 0; }

.assembly .binders-pay { width: 743px; height: 29px; background: url(../img/assembly/binders-pay.png) no-repeat center; margin: 5px auto; }

.assembly .binders-remove { display: block; width: 170px; height: 36px; background: url(../img/assembly/binders-remove.png) no-repeat 0 0; position: absolute; bottom: 3px; left: 722px; z-index: 10; }

.assembly .binders-remove:hover { background-image: url(../img/assembly/binders-remove-hover.png); }

.assembly .binders-remove:active, .assembly .binders-remove.active { background-image: url(../img/assembly/binders-remove-active.png); }

.assembly .components-placeholder { background-image: url(../img/assembly/components-placeholder.png); }

.assembly .components-placeholder-night { background-image: url(../img/assembly/components-placeholder-night.png); }

.assembly .binder-placeholder { background-image: url(../img/assembly/binder-placeholder.png); }

.assembly .binder-placeholder-night { background-image: url(../img/assembly/binder-placeholder-night.png); }

.assembly .machine-tiredness { background-image: url(../img/assembly/machine-tiredness.png); }

.assembly .machine-tiredness-circle { background-image: url(../img/assembly/machine-tiredness-circle.png); }

.assembly .machine-tiredness-triangle { background-image: url(../img/assembly/machine-tiredness-triangle.png); }

.assembly .machine-tiredness-square { background-image: url(../img/assembly/machine-tiredness-square.png); }

.assembly .machine-tiredness-circle.active, .assembly .machine-tiredness-circle.intro-active { background-image: url(../img/assembly/machine-tiredness-circle-active.png); }

.assembly .machine-tiredness-triangle.active, .assembly .machine-tiredness-triangle.intro-active { background-image: url(../img/assembly/machine-tiredness-triangle-active.png); }

.assembly .machine-tiredness-square.active, .assembly .machine-tiredness-square.intro-active { background-image: url(../img/assembly/machine-tiredness-square-active.png); }

.inactive .machine-inactive-hide { display: none; }

.machine-active-hide { display: none; }

.inactive .machine-active-hide { display: block; }

.assembly .inactive .recipe-placeholder { background-image: url(../img/assembly/recipe-placeholder-inactive.png); height: 153px; }

.assembly .inactive .components { background-image: url(../img/assembly/components-inactive.png); height: 153px; }

.assembly .inactive .components-placeholder { background-image: url(../img/assembly/components-placeholder-inactive.png); height: 77px; }

.assembly .inactive .components-placeholder-night { background-image: url(../img/assembly/components-placeholder-night-inactive.png); height: 77px; }

.assembly .inactive .robots-recipe { background-image: url(../img/assembly/robots-recipe-inactive.png); height: 153px; }

.assembly .fake-cards-set { display: none; position: absolute; width: 223px; height: 385px; }

.assembly .fake-cards-set.is-visible { display: block; }

.assembly .fake-cards-set-bg { width: 100%; height: 100%; background: url(../img/tutorial-fakes/assembly-cards.png) no-repeat 0 0; }

.assembly .fake-cards-set-i { width: 25px; height: 25px; position: absolute; top: 204px; right: 0; background: url(../img/assembly/add-note.png) no-repeat 0 0; }

.assembly .fake-action-type-service { display: none; position: absolute; width: 357px; height: 352px; }

.assembly .fake-action-type-service.is-visible { display: block; top: 18px; left: 236px; }

.assembly .fake-action-type-service-bg { width: 100%; height: 100%; background: url(../img/assembly/actions/03.png) no-repeat 0 0; }

.assembly .fake-action-type-service-bg:before { content: 'Maszyna 2'; display: block; position: absolute; top: 30px; left: 13px; font-weight: bold; text-shadow: none; }

.assembly .fake-action-type-service-button { width: 352px; height: 63px; position: absolute; top: 50%; left: 50%; margin: -31px 0 0 -176px; background: url(../img/assembly/actions/03-apply.png) no-repeat 0 0; box-shadow: 0 0 15px 5px rgba(0, 255, 255, 0.7); }

.assembly .fake-action-type-marketing { display: none; position: absolute; width: 63px; height: 352px; }

.assembly .fake-action-type-marketing.is-visible { display: block; top: 18px; left: 236px; }

.assembly .fake-action-type-marketing-bg { width: 100%; height: 100%; background: url(../img/assembly/actions/01.png) no-repeat 0 0; }

.assembly .fake-action-type-marketing-button { width: 63px; height: 352px; position: absolute; top: 50%; left: 50%; margin: -176px 0 0 -31px; background: url(../img/assembly/actions/01-apply.png) no-repeat 0 0; box-shadow: 0 0 15px 5px rgba(0, 255, 255, 0.7); }

.assembly .robot-type { width: 225px; height: 154px; position: relative; }

.assembly .robot-type .number { font-size: 16px; color: #fff; position: absolute; top: 11px; left: 116px; text-align: left; text-shadow: none; font-weight: bold; }

.assembly .robot-type .quality-info { font-size: 14px; color: #fff; position: absolute; top: 33px; left: 12px; text-align: left; text-shadow: none; font-weight: normal; }

.assembly .robot-type .quality-info p { font-weight: normal; }

.assembly .robot-type.robot-type-01 { background-image: url(../img/assembly/components/01.png); }

.assembly .robot-type.robot-type-02 { background-image: url(../img/assembly/components/02.png); }

.assembly .robot-type.robot-type-03 { background-image: url(../img/assembly/components/03.png); }

.assembly .robot-type.robot-type-04 { background-image: url(../img/assembly/components/04.png); }

.assembly .robot-type.robot-type-05 { background-image: url(../img/assembly/components/05.png); }

.assembly .robot-type.robot-type-06 { background-image: url(../img/assembly/components/06.png); }

.assembly .robot-type.robot-type-07 { background-image: url(../img/assembly/components/07.png); }

.assembly .robot-type.robot-type-08 { background-image: url(../img/assembly/components/08.png); }

.assembly .robot-type-small { width: 225px; height: 76px; }

.assembly .robot-type-small .quality-info { display: none; }

.assembly .robot-type-small.robot-type-01 { background-image: url(../img/assembly/components-inactive/01.png); }

.assembly .robot-type-small.robot-type-02 { background-image: url(../img/assembly/components-inactive/02.png); }

.assembly .robot-type-small.robot-type-03 { background-image: url(../img/assembly/components-inactive/03.png); }

.assembly .robot-type-small.robot-type-04 { background-image: url(../img/assembly/components-inactive/04.png); }

.assembly .robot-type-small.robot-type-05 { background-image: url(../img/assembly/components-inactive/05.png); }

.assembly .robot-type-small.robot-type-06 { background-image: url(../img/assembly/components-inactive/06.png); }

.assembly .robot-type-small.robot-type-07 { background-image: url(../img/assembly/components-inactive/07.png); }

.assembly .robot-type-small.robot-type-08 { background-image: url(../img/assembly/components-inactive/08.png); }

.assembly .robot-number { height: 37px; background: url(../img/assembly/robot-number.png) no-repeat center; margin-bottom: 40px; }

.assembly .input-robot-number-container { position: relative; height: 37px; }

.assembly .input-robot-number { position: absolute; height: 37px; width: 222px; background: url(../img/assembly/robot-number-input.png) no-repeat center; border: 0px; text-align: center; outline: none; font-size: 30px; line-height: 35px; font-weight: bold; }

.assembly .robot-small { width: 227px; height: 151px; }

.assembly .robot-small.robot-01 { background-image: url(../img/assembly/recipes-inactive/01.png); }

.assembly .robot-small.robot-02 { background-image: url(../img/assembly/recipes-inactive/02.png); }

.assembly .robot-small.robot-03 { background-image: url(../img/assembly/recipes-inactive/03.png); }

.assembly .robot-small.robot-04 { background-image: url(../img/assembly/recipes-inactive/04.png); }

.assembly .robot-small.robot-05 { background-image: url(../img/assembly/recipes-inactive/05.png); }

.assembly .robot-small.robot-06 { background-image: url(../img/assembly/recipes-inactive/06.png); }

.assembly .robot-small.robot-07 { background-image: url(../img/assembly/recipes-inactive/07.png); }

.assembly .robot-small.robot-08 { background-image: url(../img/assembly/recipes-inactive/08.png); }

.assembly .robot-small.robot-09 { background-image: url(../img/assembly/recipes-inactive/09.png); }

.assembly .robot-small.robot-10 { background-image: url(../img/assembly/recipes-inactive/10.png); }

.assembly .robot-small.robot-11 { background-image: url(../img/assembly/recipes-inactive/11.png); }

.assembly .robot-small.robot-12 { background-image: url(../img/assembly/recipes-inactive/12.png); }

.assembly .robot-small.robot-13 { background-image: url(../img/assembly/recipes-inactive/13.png); }

.assembly .robot-small.robot-14 { background-image: url(../img/assembly/recipes-inactive/14.png); }

.assembly .robot-small.robot-15 { background-image: url(../img/assembly/recipes-inactive/15.png); }

.assembly .robot-small.robot-16 { background-image: url(../img/assembly/recipes-inactive/16.png); }

.assembly .binder-type { width: 225px; height: 80px; }

.assembly .binder-type.binder-type-09 { background-image: url(../img/assembly/binders/09.png); }

.assembly .binder-type.binder-type-10 { background-image: url(../img/assembly/binders/10.png); }

.assembly .binder-type.binder-type-11 { background-image: url(../img/assembly/binders/11.png); }

.assembly .binder-type.binder-type-12 { background-image: url(../img/assembly/binders/12.png); }

.assembly .recipe-type { width: 225px; height: 387px; }

.assembly .recipe-type.recipe-type-01 { background-image: url(../img/assembly/recipes/01.png); }

.assembly .recipe-type.recipe-type-02 { background-image: url(../img/assembly/recipes/02.png); }

.assembly .recipe-type.recipe-type-03 { background-image: url(../img/assembly/recipes/03.png); }

.assembly .recipe-type.recipe-type-04 { background-image: url(../img/assembly/recipes/04.png); }

.assembly .recipe-type.recipe-type-05 { background-image: url(../img/assembly/recipes/05.png); }

.assembly .recipe-type.recipe-type-06 { background-image: url(../img/assembly/recipes/06.png); }

.assembly .recipe-type.recipe-type-07 { background-image: url(../img/assembly/recipes/07.png); }

.assembly .recipe-type.recipe-type-08 { background-image: url(../img/assembly/recipes/08.png); }

.assembly .recipe-type.recipe-type-09 { background-image: url(../img/assembly/recipes/09.png); }

.assembly .recipe-type.recipe-type-10 { background-image: url(../img/assembly/recipes/10.png); }

.assembly .recipe-type.recipe-type-11 { background-image: url(../img/assembly/recipes/11.png); }

.assembly .recipe-type.recipe-type-12 { background-image: url(../img/assembly/recipes/12.png); }

.assembly .recipe-type.recipe-type-13 { background-image: url(../img/assembly/recipes/13.png); }

.assembly .recipe-type.recipe-type-14 { background-image: url(../img/assembly/recipes/14.png); }

.assembly .recipe-type.recipe-type-15 { background-image: url(../img/assembly/recipes/15.png); }

.assembly .recipe-type.recipe-type-16 { background-image: url(../img/assembly/recipes/16.png); }

.assembly .robots-recipe-ctr { background-image: url(../img/assembly/binder-counter.png); height: 40px; width: 73px; }

.assembly .components-head { background-image: url(../img/assembly/components-head.png); height: 155px; }

.assembly .components-legs { background-image: url(../img/assembly/components-legs.png); height: 155px; }

.assembly .binders { background-image: url(../img/assembly/binders.png); height: 76px; margin: 2px 0; }

.assembly .popup-numbers { background-image: url(../img/assembly/popup-numbers-bg.png); width: 1200px; height: 700px; position: absolute; top: 50%; left: 50%; margin: -350px 0 0 -600px; padding: 60px 30px 0 20px; z-index: 1000; }

.assembly .button-confirm { background: url(../img/assembly/button-confirm-non-active.png) no-repeat center; }

.assembly .button-confirm.active { background-image: url(../img/assembly/button-confirm.png); }

.assembly .button-confirm.active:hover { background-image: url(../img/assembly/button-confirm-hover.png); }

.assembly .button-confirm.active:active { background-image: url(../img/assembly/button-confirm-active.png); }

.assembly .popup-tiredness { margin-bottom: 3px; }

.assembly .popup-tiredness .col-2:first-child { margin-left: 9.75%; }

.assembly .popup-tiredness .col-7 { width: 26%; }

.assembly .popup-tiredness .machine-tiredness { margin-left: -67px; }

.assembly .nano > .nano-pane { background: url(../img/assembly/scrollbar-bg.png) no-repeat center; }

.assembly .nano > .nano-pane > .nano-slider { background: url(../img/assembly/scrollbar-top.png) no-repeat top center, url(../img/assembly/scrollbar-bottom.png) no-repeat bottom center; }

.assembly .nano > .nano-pane > .nano-slider:after { background: url(../img/assembly/scrollbar-middle.png) repeat-y top center; }

.assembly .robots-recipe-items-list { margin: 20px 30px 10px 30px; padding-bottom: 60px; }

.assembly .robots-recipe-items-list .draggable[data-size="3x3"] { width: 205px; height: 350px; }

.assembly .robots-recipe-items-list .draggable[data-size="3x1"][data-type="binder"] { width: 205px; height: 66px !important; }

.assembly .robots-recipe-items-list .draggable[data-size="3x1"] { width: 205px; height: 135px; }

.assembly .robots-recipe-items-list .draggable[data-size="1x3"] { width: 60px; height: 210px; }

.assembly .robots-recipe-items-list .draggable[data-size="2x3"] { width: 90px; height: 210px; }

.assembly .robots-recipe-items-list .draggable[data-size="4x3"] { width: 180px; height: 210px; }

.assembly .robots-recipe-items-list .draggable[data-size="5x3"] { width: 210px; height: 210px; }

.assembly .robots-recipe-items-list .recipe-type { background-size: 195px auto; width: 205px; height: 350px; }

.assembly .robots-recipe-items-list .binder-type { background-size: 195px auto; width: 205px; height: 66px; }

.assembly .robots-recipe-items-list .robot-type { background-size: 195px auto; width: 205px; height: 135px; }

.assembly .actions-items-list { margin: 10px 15px 10px 10px; text-align: center; }

.assembly .actions-items-list .draggable { width: auto; margin: 5px 3px; height: auto; display: inline-block; vertical-align: top; }

.assembly .actions-items-list .draggable.inactive { cursor: default; opacity: .25; pointer-events: none; }

.assembly .actions-items-list .action-type-marketing { height: 149px; width: 70px; margin: 0px; background: url(../img/assembly/actions-inactive/01.png) center no-repeat; }

.assembly .actions-items-list .action-type-stop { height: 149px; width: 143px; margin: 0px; background: url(../img/assembly/actions-inactive/02.png) center no-repeat; }

.assembly .actions-items-list .action-type-service, .assembly .actions-items-list .action-type-service-1, .assembly .actions-items-list .action-type-service-2 { height: 149px; width: 363px; margin: 0px; background: url(../img/assembly/actions-inactive/03.png) center no-repeat; }

.assembly .actions-items-list .action-type-service:before, .assembly .actions-items-list .action-type-service-1:before, .assembly .actions-items-list .action-type-service-2:before { top: 30px; }

.assembly .actions-items-list .action-type-service:not(.action-applied):hover:after, .assembly .actions-items-list .action-type-service-1:not(.action-applied):hover:after, .assembly .actions-items-list .action-type-service-2:not(.action-applied):hover:after { display: none; }

.assembly .actions-items-list .action-type-training { height: 149px; width: 290px; margin: 0px; position: relative; background: url(../img/assembly/actions-inactive/04.png) center no-repeat; }

.assembly .actions-items-list .action-type-training:before { top: 30px; left: 10px; }

.assembly .actions-items-list .action-type-training:not(.action-applied):hover:after { display: none; }

.assembly .actions-items-list .action-type-check { height: 149px; width: 70px; margin: 0px; background: url(../img/assembly/actions-inactive/05.png) center no-repeat; }

.assembly .actions-panel { overflow: hidden; cursor: default; }

.assembly .actions-panel-inner, .assembly .actions-panel.open .actions-panel-inner { width: auto; left: 0; right: 12px; top: auto; bottom: 10px; height: 367px; }

.assembly .actions-panel .robots-recipe-items-list { position: absolute; bottom: 0; margin-bottom: 90px; }

.assembly .binders-cost { position: absolute; z-index: 10; bottom: 10px; left: 620px; font-size: 18px; width: 51px; text-align: center; }

.assembly .binder-remove-icon { display: none; }

.assembly .list-binders.active .binder-remove-icon, .assembly .list-pay-binders.active .binder-remove-icon { display: block; width: 120px; height: 50px; background: url(../img/assembly/binder-remove-icon.png); position: absolute; top: 50%; margin-top: -25px; left: 20px; }

.assembly .action-type-marketing { display: block; background: url(../img/assembly/actions/01.png) center no-repeat; width: 63px; height: 352px; margin: 18px 8px; }

.assembly .zone-item .action-type-marketing:after { content: ''; display: block; background-image: url(../img/sprite-assembly.png); background-position: -396px -428px; width: 100px; height: 20px; margin-top: -70px; position: absolute; top: 50%; left: 13px; transform: rotate(90deg); transform-origin: bottom left; }

.assembly .zone-item .action-type-marketing:not(.action-applied):hover:after { background-image: url(../img/sprite-assembly.png); background-position: -294px -428px; width: 100px; height: 20px; }

.assembly .zone-item .action-type-marketing.action-applied { cursor: default; }

.assembly .zone-item .action-type-marketing.action-applied:after { background-image: url(../img/sprite-assembly.png); background-position: -498px -428px; width: 100px; height: 20px; }

.assembly .action-type-stop { display: block; background: url(../img/assembly/actions/02.png) center no-repeat; width: 134px; height: 352px; margin: 18px 8px; }

.assembly .action-type-service, .assembly .action-type-service-1, .assembly .action-type-service-2 { display: block; background: url(../img/assembly/actions/03.png) center no-repeat; width: 357px; height: 352px; margin: 18px 9px; position: relative; }

.assembly .action-type-service:before, .assembly .action-type-service-1:before, .assembly .action-type-service-2:before { content: ''; display: block; position: absolute; top: 30px; left: 13px; font-weight: bold; text-shadow: none; }

.assembly .zone-item .action-type-service-1:after, .assembly .zone-item .action-type-service-2:after { content: ''; display: block; background-image: url(../img/sprite-assembly.png); background-position: -396px -428px; width: 100px; height: 20px; position: absolute; top: 8px; left: 115px; }

.assembly .zone-item .action-type-service-1:not(.action-applied):hover:after, .assembly .zone-item .action-type-service-2:not(.action-applied):hover:after { background-image: url(../img/sprite-assembly.png); background-position: -294px -428px; width: 100px; height: 20px; }

.assembly .zone-item .action-type-service-1.action-applied, .assembly .zone-item .action-type-service-2.action-applied { cursor: default; }

.assembly .zone-item .action-type-service-1.action-applied:after, .assembly .zone-item .action-type-service-2.action-applied:after { background-image: url(../img/sprite-assembly.png); background-position: -498px -428px; width: 100px; height: 20px; }

.assembly .action-type-service-1:before { content: 'Maszyna 1'; }

.assembly .action-type-service-2:before { content: 'Maszyna 2'; }

.assembly .action-type-training { display: block; background: url(../img/assembly/actions/04.png) center no-repeat; width: 285px; height: 352px; margin: 18px 10px; position: relative; overflow: hidden; }

.assembly .action-type-training:before { content: ''; display: block; position: absolute; top: 30px; left: 13px; font-weight: bold; text-shadow: none; }

.assembly .zone-item .action-type-training:after { content: ''; display: block; background-image: url(../img/sprite-assembly.png); background-position: -396px -428px; width: 100px; height: 20px; position: absolute; top: 8px; left: 120px; }

.assembly .zone-item .action-type-training:not(.action-applied):hover:after { background-image: url(../img/sprite-assembly.png); background-position: -294px -428px; width: 100px; height: 20px; }

.assembly .zone-item .action-type-training.action-applied { cursor: default; }

.assembly .zone-item .action-type-training.action-applied:after { background-image: url(../img/sprite-assembly.png); background-position: -498px -428px; width: 100px; height: 20px; }

.assembly .inactive .action-type-training:before { display: none !important; }

.assembly .action-type-training[data-functionality="shopping"]:before { content: 'Robi Zakupy'; }

.assembly .action-type-training[data-functionality="trainer"]:before { content: 'Trener Personalny'; }

.assembly .action-type-training[data-functionality="mechanic"]:before { content: 'Naprawia Samochód'; }

.assembly .action-type-forced-service { display: block; background: url(../img/assembly/actions/03.png) center no-repeat; width: 357px; height: 352px; margin: 18px 9px; position: relative; }

.assembly .action-type-forced-service:before { content: 'Wymuszony'; display: block; position: absolute; top: 30px; left: 13px; font-weight: bold; text-shadow: none; }

.assembly .action-type-check { display: block; background: url(../img/assembly/actions/05.png) center no-repeat; width: 63px; height: 352px; margin: 18px 8px; }

.assembly .zone-item .action-type-check:after { content: ''; display: block; background-image: url(../img/sprite-assembly.png); background-position: -396px -428px; width: 100px; height: 20px; margin-top: -70px; position: absolute; top: 50%; left: 13px; transform: rotate(90deg); transform-origin: bottom left; }

.assembly .zone-item .action-type-check:not(.action-applied):hover:after { background-image: url(../img/sprite-assembly.png); background-position: -294px -428px; width: 100px; height: 20px; }

.assembly .zone-item .action-type-check.action-applied { cursor: default; }

.assembly .zone-item .action-type-check.action-applied:after { background-image: url(../img/sprite-assembly.png); background-position: -498px -428px; width: 100px; height: 20px; }

.assembly .zone:not(.zone-inactive) .action-type-check { display: block; background: url(../img/assembly/actions/05.png) center no-repeat; width: 63px; height: 352px; margin: 18px 8px; cursor: pointer; }

.assembly .action-type-small.action-type-check:after { background-image: url(../img/sprite-assembly.png); background-position: -967px -267px; width: 20px; height: 20px; margin-top: -10px; left: 13px; transform: none; }

.assembly .action-type-small.action-type-check.action-applied { cursor: default; }

.assembly .action-type-small.action-type-check.action-applied:after { background-image: url(../img/sprite-assembly.png); background-position: -969px -228px; width: 20px; height: 20px; }

.assembly .action-type-small.action-type-marketing { display: block; background: url(../img/assembly/actions-inactive/01.png) center no-repeat; width: 77px; height: 155px; margin: -1px 2px 0px; }

.assembly .action-type-small.action-type-marketing:after { background-image: url(../img/sprite-assembly.png); background-position: -967px -267px; width: 20px; height: 20px; margin-top: -10px; left: 13px; transform: none; }

.assembly .action-type-small.action-type-marketing.action-applied { cursor: default; }

.assembly .action-type-small.action-type-marketing.action-applied:after { background-image: url(../img/sprite-assembly.png); background-position: -969px -228px; width: 20px; height: 20px; }

.assembly .action-type-small.action-type-stop { display: block; background: url(../img/assembly/actions-inactive/02.png) center no-repeat; width: 149px; height: 155px; margin: -1px 4px 0px; }

.assembly .action-type-small.action-type-service, .assembly .action-type-small.action-type-service-1, .assembly .action-type-small.action-type-service-2, .assembly .action-type-small.action-type-forced-service { display: block; background: url(../img/assembly/actions-inactive/03.png) center no-repeat; width: 363px; height: 155px; margin: -1px 8px 0px; }

.assembly .action-type-small.action-type-service:after, .assembly .action-type-small.action-type-service-1:after, .assembly .action-type-small.action-type-service-2:after, .assembly .action-type-small.action-type-forced-service:after { background-image: url(../img/sprite-assembly.png); background-position: -967px -267px; width: 20px; height: 20px; top: 10px; }

.assembly .action-type-small.action-type-service.action-applied, .assembly .action-type-small.action-type-service-1.action-applied, .assembly .action-type-small.action-type-service-2.action-applied, .assembly .action-type-small.action-type-forced-service.action-applied { cursor: default; }

.assembly .action-type-small.action-type-service.action-applied:after, .assembly .action-type-small.action-type-service-1.action-applied:after, .assembly .action-type-small.action-type-service-2.action-applied:after, .assembly .action-type-small.action-type-forced-service.action-applied:after { background-image: url(../img/sprite-assembly.png); background-position: -969px -228px; width: 20px; height: 20px; }

.assembly .action-type-small.action-type-training { display: block; background: url(../img/assembly/actions-inactive/04.png) center no-repeat; width: 290px; height: 155px; margin: -1px 8px 0px; }

.assembly .action-type-small.action-type-training:after { background-image: url(../img/sprite-assembly.png); background-position: -967px -267px; width: 20px; height: 20px; top: 10px; }

.assembly .action-type-small.action-type-training.action-applied { cursor: default; }

.assembly .action-type-small.action-type-training.action-applied:after { background-image: url(../img/sprite-assembly.png); background-position: -969px -228px; width: 20px; height: 20px; }

.assembly .action-type-small.action-type-check, .assembly .action-type-small.action-type-check:not(.action-applied):hover, .assembly .zone:not(.zone-inactive) .action-type-small.action-type-check, .assembly .zone:not(.zone-inactive) .action-type-small.action-type-check:not(.action-applied):hover { display: block; background: url(../img/assembly/actions-inactive/05.png) center no-repeat; width: 77px; height: 155px; margin: -1px 2px 0px; cursor: default; }

.assembly .popup-container .component-test-popup { width: 190px; height: 279px; background: url(../img/assembly/component-test-popup.png) no-repeat center; position: absolute; top: 50%; left: 50%; margin: -139px 0 0 -95px; cursor: default; }

.assembly .popup-container .component-test-popup input { border: 0px; background: transparent; width: 170px; height: 36px; font-size: 30px; line-height: 32px; position: absolute; text-align: center; font-weight: bold; outline: none; }

.assembly .popup-container .component-test-popup input:nth-child(1) { top: 97px; left: 10px; }

.assembly .popup-container .component-test-popup input:nth-child(2) { top: 141px; left: 10px; }

.assembly .popup-container .component-test-popup input:nth-child(3) { top: 186px; left: 10px; }

.assembly .popup-container .component-test-popup .ready { display: block; width: 163px; height: 36px; background: url(../img/assembly/component-test-button.png) no-repeat center; position: absolute; bottom: 35px; left: 50%; margin-left: -81px; cursor: pointer; }

.assembly .popup-container .component-test-popup .ready:hover { background-image: url(../img/assembly/component-test-button-hover.png); }

.assembly .popup-container .component-test-popup .ready:active { background-image: url(../img/assembly/component-test-button-active.png); }

.assembly .robots-recipe-item-group { position: relative; }

.assembly .robots-recipe-ctr { position: absolute; text-align: center; line-height: 40px; bottom: -26px; left: 50%; margin-left: -36px; font-size: 24px; }

.assembly .list-binders { margin-bottom: 65px; padding-left: 10px; }

.assembly .list-pay-binders { padding-left: 10px; }

.assembly .comment { width: 25px; height: 25px; position: absolute; bottom: 4px; right: 4px; }

.assembly .comment .comment-icon { width: 25px; height: 25px; background: url(../img/assembly/add-note.png) no-repeat 0 0; cursor: pointer; }

.assembly .comment .comment-text { display: none; width: 220px; min-height: 14px; line-height: 14px; position: absolute; top: 30px; left: 15px; z-index: 1000; color: #fff; font-size: 10px; font-weight: normal; }

.assembly .comment .value { display: none; }

.assembly .comment .placeholder, .assembly .comment .value { padding: 4px; max-width: 220px; min-width: 50px; float: left; background: rgba(0, 0, 0, 0.9); text-align: left; }

.assembly .comment:hover, .assembly .comment.is-edit-mode { z-index: 1500; }

.assembly .comment:hover .comment-text, .assembly .comment.is-edit-mode .comment-text { display: block; }

.assembly .comment.is-edit-mode .placeholder, .assembly .comment.is-active .placeholder { display: none; }

.assembly .comment.is-edit-mode .value, .assembly .comment.is-active .value { display: block; }

.assembly .comment.is-readonly .comment-icon, .assembly .comment.is-readonly.is-active .comment-icon { background: url(../img/assembly/note-embeded.png) no-repeat 0 0; }

.assembly .comment.is-active .comment-icon { background: url(../img/assembly/add-note-active.png) no-repeat 0 0; }

.assembly .comment.item-comment { top: 205px; margin-left: 199px; }

.assembly .zone .comment { bottom: 5px; right: 1px; }

.assembly .robots-recipe-items-list .robots-recipe-item-wrapper:nth-child(4n+4) .comment .comment-text { left: auto; right: 15px; }

.assembly .robots-recipe-items-list .robots-recipe-item-wrapper:nth-child(4n+4) .comment .placeholder, .assembly .robots-recipe-items-list .robots-recipe-item-wrapper:nth-child(4n+4) .comment .value { float: right; }

/* DIVISION : PRODUCTION */
.production .cover-bg { background-image: url(../img/production/bg.jpg); }

.production .depertment-name { background-image: url(../img/production/department-name.png); }

.production .timer { background-image: url(../img/production/counter.png); }

.production .actions { background-image: url(../img/production/actions.png); }

.production .end-round { background-image: url(../img/production/end-round.png); }

.production .end-round:hover { background-image: url(../img/production/end-round-hover.png); }

.production .end-round:active { background-image: url(../img/production/end-round-active.png); }

.production .summary { background-image: url(../img/production/summary.png); }

.production .summary:hover { background-image: url(../img/production/summary-hover.png); }

.production .summary:active { background-image: url(../img/production/summary-active.png); }

.production .report { background-image: url(../img/production/report.png); }

.production .report:hover { background-image: url(../img/production/report-hover.png); }

.production .report:active { background-image: url(../img/production/report-active.png); }

.production .summary-popup-content { background: url(../img/production/summary-popup.png) no-repeat center; }

.production .summary-popup .back { background: url(../img/production/summary-back.png) no-repeat center; }

.production .summary-popup .back:hover { background-image: url(../img/production/summary-back-hover.png); }

.production .summary-popup .back:active { background-image: url(../img/production/summary-back-active.png); }

.production .summary-popup .nano > .nano-pane { width: 19px; }

.production .summary-popup .nano > .nano-pane > .nano-slider { width: 18px; }

.production .summary-popup .nano > .nano-pane > .nano-slider:after { display: block; content: ''; position: absolute; top: 4px; bottom: 4px; width: 18px; }

.production .machine-1 { background-image: url(../img/production/machine-mini-1.png); }

.production .machine-2 { background-image: url(../img/production/machine-mini-2.png); }

.production .machine-3 { background-image: url(../img/production/machine-mini-3.png); }

.production .machine-vert-1 { background-image: url(../img/production/machine-vert-1.png); }

.production .machine-vert-2 { background-image: url(../img/production/machine-vert-2.png); }

.production .machine-vert-3 { background-image: url(../img/production/machine-vert-3.png); }

.production .number-1 { background-image: url(../img/production/number-1.png); }

.production .number-2 { background-image: url(../img/production/number-2.png); }

.production .number-3 { background-image: url(../img/production/number-3.png); }

.production .number-4 { background-image: url(../img/production/number-4.png); }

.production .number-5 { background-image: url(../img/production/number-5.png); }

.production .number-6 { background-image: url(../img/production/number-6.png); }

.production .number-7 { background-image: url(../img/production/number-7.png); }

.production .number-8 { background-image: url(../img/production/number-8.png); }

.production .number-9 { background-image: url(../img/production/number-9.png); }

.production .number-10 { background-image: url(../img/production/number-10.png); }

.production .number-11 { background-image: url(../img/production/number-11.png); }

.production .number-12 { background-image: url(../img/production/number-12.png); }

.production .material.selected { position: relative; }

.production .material.selected:after { content: ''; display: block; position: absolute; top: 0; right: 0; width: 16px; height: 16px; background: url(../img/production/checked.png) no-repeat top right; }

.production .material-1 { background-image: url(../img/production/material-1.png); }

.production .material-1.small { background-image: url(../img/production/material-1-small.png); }

.production .material-2 { background-image: url(../img/production/material-2.png); }

.production .material-2.small { background-image: url(../img/production/material-2-small.png); }

.production .material-3 { background-image: url(../img/production/material-3.png); }

.production .material-3.small { background-image: url(../img/production/material-3-small.png); }

.production .material-4 { background-image: url(../img/production/material-4.png); }

.production .material-4.small { background-image: url(../img/production/material-4-small.png); }

.production .material-5 { background-image: url(../img/production/material-5.png); }

.production .material-5.small { background-image: url(../img/production/material-5-small.png); }

.production #materials { position: relative; }

.production .fake-materials { display: none; width: 92px; height: 52px; top: 3px; position: absolute; }

.production .fake-materials:before { content: ''; display: block; width: 46px; height: 52px; float: left; }

.production .fake-materials:after { content: ''; display: block; width: 46px; height: 52px; float: right; }

.production .fake-materials.is-visible { display: block; }

.production .fake-materials-1 { left: 317px; }

.production .fake-materials-1:before { background: url(../img/production/material-3.png) no-repeat center; }

.production .fake-materials-1:after { background: url(../img/production/material-3-small.png) no-repeat center; }

.production .fake-materials-2 { left: 131px; }

.production .fake-materials-2:before { background: url(../img/production/material-1.png) no-repeat center; }

.production .fake-materials-2:after { background: url(../img/production/material-1-small.png) no-repeat center; }

.production .fake-cards-set { display: none; position: absolute; width: 73px; height: 154px; }

.production .fake-cards-set.is-visible { display: block; }

.production .fake-cards-set-bg { width: 100%; height: 100%; background: url(../img/tutorial-fakes/production-materials.png) no-repeat 0 0; }

.production .fake-cards-set-i { width: 25px; height: 25px; position: absolute; top: 64px; left: 22px; background: url(../img/production/add-note.png) no-repeat 0 0; }

.production .fake-night-mark { display: none; position: absolute; top: 0; right: 0; width: 151px; height: 152px; }

.production .fake-night-mark.is-visible { display: block; }

.production .color { background-image: url(../img/production/color.png); }

.production .color-placeholder { background-image: url(../img/production/color-placeholder.png); }

.production .color-blue { background-image: url(../img/production/color-blue.png); }

.production .color-green { background-image: url(../img/production/color-green.png); }

.production .color-red { background-image: url(../img/production/color-red.png); }

.production .color-disabled { background-image: url(../img/production/color-placeholder-inactive.png); }

.production .color-disabled:before { content: ''; display: block; width: 0; height: 0; background: url(../img/production/color-blue.png), url(../img/production/color-green.png), url(../img/production/color-red.png); }

.production .robots-recipe { background-image: url(../img/production/robots-recipe.png); height: 154px; }

.production .robots-recipe:hover { background-image: url(../img/production/robots-recipe-hover.png); }

.production .robots-recipe.active { background-image: url(../img/production/robots-recipe-active.png); }

.production .robots-recipe-panel { background-image: url(../img/production/components-panel.png); height: 154px; }

.production .recipe-placeholder { background-image: url(../img/production/recipe-placeholder.png); height: 154px; padding-top: 5px; }

.production .recipe-placeholder.active { background-image: url(../img/production/recipe-placeholder-active.png); }

.production .components { background-image: url(../img/production/components.png); height: 154px; }

.production .components:hover { background-image: url(../img/production/components-hover.png); }

.production .components.active { background-image: url(../img/production/components-active.png); }

.production .robots-recipe-panel, .production .components-panel { background-image: url(../img/production/components-panel.png); height: 154px; }

.production .robots-recipe-panel:after, .production .components-panel:after { display: block; content: ''; position: absolute; top: 0px; bottom: 0px; left: 0px; right: 0px; z-index: 100; pointer-events: none; background: url(../img/production/components-panel-top.png) no-repeat; }

.production .robots-recipe-panel-inner { padding: 14px 25px 0 25px; overflow-x: auto; white-space: nowrap; margin: 0 3px; }

.production .robots-recipe-panel-inner .robots-recipe-item-wrapper { float: none; display: inline-block; white-space: normal; margin: 0 5px; vertical-align: top; }

.production .robots-recipe-panel-inner .robots-recipe-item-wrapper img { max-height: 125px; width: auto; }

.production .robots-recipe-panel-inner .robots-recipe-item-wrapper .action-type-marketing, .production .robots-recipe-panel-inner .robots-recipe-item-wrapper .action-type-service-1, .production .robots-recipe-panel-inner .robots-recipe-item-wrapper .action-type-service-2, .production .robots-recipe-panel-inner .robots-recipe-item-wrapper .action-type-service-3, .production .robots-recipe-panel-inner .robots-recipe-item-wrapper .action-type-service-e, .production .robots-recipe-panel-inner .robots-recipe-item-wrapper .action-type-training { margin: 0 -27px 0 0; transform: scale(0.88); transform-origin: 0 0; }

.production .robots-recipe-panel-inner .robots-recipe-item-wrapper .action-type-marketing:after, .production .robots-recipe-panel-inner .robots-recipe-item-wrapper .action-type-service-1:after, .production .robots-recipe-panel-inner .robots-recipe-item-wrapper .action-type-service-2:after, .production .robots-recipe-panel-inner .robots-recipe-item-wrapper .action-type-service-3:after, .production .robots-recipe-panel-inner .robots-recipe-item-wrapper .action-type-service-e:after, .production .robots-recipe-panel-inner .robots-recipe-item-wrapper .action-type-training:after { display: none !important; }

.production .robots-recipe-panel-inner .robots-recipe-item-wrapper .action-type-marketing { margin: 0 -10px 0 0; }

.production .robots-recipe-panel-inner .robots-recipe-item-wrapper .action-type-training { margin: 0 -35px 0 0; }

.production .robots-recipe-panel-inner .draggable.inactive { cursor: default; opacity: .25; pointer-events: none; }

.production .ui-draggable-dragging { z-index: 10000; }

.production .draggable[data-size="3x2"], .production .draggable[data-size="4x2"], .production .draggable[data-size="1x2"] { height: auto; width: auto; }

.production .components-placeholder { background-image: url(../img/production/components-placeholder.png); height: 76px; }

.production .components-placeholder-night { background-image: url(../img/production/components-placeholder-night.png); height: 76px; }

.production .components-placeholder-tired { background-image: url(../img/production/components-placeholder-inactive.png); height: 76px; }

.production .item { position: relative; }

.production .comment { width: 25px; height: 25px; position: absolute; top: 63px; margin-left: 23px; z-index: 10; }

.production .comment .comment-icon { width: 25px; height: 25px; background: url(../img/production/add-note.png) no-repeat 0 0; cursor: pointer; }

.production .comment .comment-text { display: none; width: 220px; min-height: 14px; line-height: 14px; position: absolute; top: 35px; left: 30px; z-index: 1000; color: #fff; font-size: 10px; font-weight: normal; }

.production .comment .value { display: none; }

.production .comment .placeholder, .production .comment .value { padding: 4px; max-width: 220px; min-width: 50px; float: left; background: rgba(0, 0, 0, 0.9); }

.production .comment:hover, .production .comment.is-edit-mode { z-index: 1500; }

.production .comment:hover .comment-text, .production .comment.is-edit-mode .comment-text { display: block; }

.production .comment.is-edit-mode .placeholder, .production .comment.is-active .placeholder { display: none; }

.production .comment.is-edit-mode .value, .production .comment.is-active .value { display: block; }

.production .comment.is-active .comment-icon { background: url(../img/production/add-note-active.png) no-repeat 0 0; }

.production .machine-tiredness { background-image: url(../img/production/machine-tiredness.png); }

.production .machine-tiredness-100 { background-image: url(../img/production/machine-tiredness-100.png); }

.production .machine-tiredness-90 { background-image: url(../img/production/machine-tiredness-90.png); }

.production .machine-tiredness-80 { background-image: url(../img/production/machine-tiredness-80.png); }

.production .machine-tiredness-70 { background-image: url(../img/production/machine-tiredness-70.png); }

.production .machine-tiredness-60 { background-image: url(../img/production/machine-tiredness-60.png); }

.production .machine-tiredness-50 { background-image: url(../img/production/machine-tiredness-50.png); }

.production .machine-tiredness-40 { background-image: url(../img/production/machine-tiredness-40.png); }

.production .machine-tiredness-30 { background-image: url(../img/production/machine-tiredness-30.png); }

.production .machine-tiredness-20 { background-image: url(../img/production/machine-tiredness-20.png); }

.production .machine-tiredness-100.active { background-image: url(../img/production/machine-tiredness-100-active.png); }

.production .machine-tiredness-90.active { background-image: url(../img/production/machine-tiredness-90-active.png); }

.production .machine-tiredness-80.active { background-image: url(../img/production/machine-tiredness-80-active.png); }

.production .machine-tiredness-70.active { background-image: url(../img/production/machine-tiredness-70-active.png); }

.production .machine-tiredness-60.active { background-image: url(../img/production/machine-tiredness-60-active.png); }

.production .machine-tiredness-50.active { background-image: url(../img/production/machine-tiredness-50-active.png); }

.production .machine-tiredness-40.active { background-image: url(../img/production/machine-tiredness-40-active.png); }

.production .machine-tiredness-30.active { background-image: url(../img/production/machine-tiredness-30-active.png); }

.production .machine-tiredness-20.active { background-image: url(../img/production/machine-tiredness-20-active.png); }

.production .inactive .recipe-placeholder { background-image: url(../img/production/recipe-placeholder-inactive.png); }

.production .inactive .components { background-image: url(../img/production/components-inactive.png); }

.production .inactive .components-placeholder-night { background-image: url(../img/production/components-placeholder-night-inactive.png); }

.production .inactive .robots-recipe { background-image: url(../img/production/robots-recipe-inactive.png); }

.production .recipe-type { width: 227px; height: 151px; }

.production .recipe-type.recipe-type-01 { background-image: url(../img/production/recipes/01.png); }

.production .recipe-type.recipe-type-02 { background-image: url(../img/production/recipes/02.png); }

.production .recipe-type.recipe-type-03 { background-image: url(../img/production/recipes/03.png); }

.production .recipe-type.recipe-type-04 { background-image: url(../img/production/recipes/04.png); }

.production .recipe-type.recipe-type-05 { background-image: url(../img/production/recipes/05.png); }

.production .recipe-type.recipe-type-06 { background-image: url(../img/production/recipes/06.png); }

.production .recipe-type.recipe-type-07 { background-image: url(../img/production/recipes/07.png); }

.production .recipe-type.recipe-type-08 { background-image: url(../img/production/recipes/08.png); }

.production .action-type-marketing { background-image: url(../img/production/actions/01.png); width: 58px; height: 142px; margin: 5px 10px; }

.production .zone-item .action-type-marketing:after { content: ''; display: block; background-image: url(../img/sprite-production.png); background-position: -348px -386px; width: 100px; height: 20px; position: absolute; top: 8px; left: 15px; transform: rotate(90deg); transform-origin: bottom left; }

.production .zone-item .action-type-marketing:not(.action-applied):hover:after { background-image: url(../img/sprite-production.png); background-position: -246px -386px; width: 100px; height: 20px; }

.production .zone-item .action-type-marketing.action-applied { cursor: default; }

.production .zone-item .action-type-marketing.action-applied:after { background-image: url(../img/sprite-production.png); background-position: -144px -386px; width: 100px; height: 20px; }

.production .zone-item .action-type-marketing.action-applied { cursor: default; }

.production .action-type-service, .production .action-type-service-1, .production .action-type-service-2, .production .action-type-service-3 { background-image: url(../img/production/actions/02.png); width: 204px; height: 142px; margin: 5px 10px; position: relative; cursor: pointer; }

.production .action-type-service:before, .production .action-type-service-1:before, .production .action-type-service-2:before, .production .action-type-service-3:before { content: ''; display: block; position: absolute; top: 24px; left: 8px; font-weight: bold; text-shadow: none; }

.production .action-type-service:after, .production .action-type-service-1:after, .production .action-type-service-2:after, .production .action-type-service-3:after { content: ''; display: block; background-image: url(../img/sprite-production.png); background-position: -348px -386px; width: 100px; height: 20px; position: absolute; top: 5px; left: 95px; }

.production .action-type-service:not(.action-applied):hover:after, .production .action-type-service-1:not(.action-applied):hover:after, .production .action-type-service-2:not(.action-applied):hover:after, .production .action-type-service-3:not(.action-applied):hover:after { background-image: url(../img/sprite-production.png); background-position: -246px -386px; width: 100px; height: 20px; }

.production .action-type-service.action-applied, .production .action-type-service-1.action-applied, .production .action-type-service-2.action-applied, .production .action-type-service-3.action-applied { cursor: default; }

.production .action-type-service.action-applied:after, .production .action-type-service-1.action-applied:after, .production .action-type-service-2.action-applied:after, .production .action-type-service-3.action-applied:after { background-image: url(../img/sprite-production.png); background-position: -144px -386px; width: 100px; height: 20px; }

.production .action-type-service.action-applied, .production .action-type-service-1.action-applied, .production .action-type-service-2.action-applied, .production .action-type-service-3.action-applied { cursor: default; }

.production .action-type-service-1:before { content: 'Maszyna 1'; }

.production .action-type-service-2:before { content: 'Maszyna 2'; }

.production .action-type-service-3:before { content: 'Maszyna 3'; }

.production .action-type-service-e { background-image: url(../img/production/actions/03.png); width: 204px; height: 142px; margin: 5px 10px; cursor: pointer; }

.production .action-type-service-e:after { content: ''; display: block; background-image: url(../img/sprite-production.png); background-position: -348px -386px; width: 100px; height: 20px; position: absolute; top: 10px; left: 90px; }

.production .action-type-service-e:not(.action-applied):hover:after { background-image: url(../img/sprite-production.png); background-position: -246px -386px; width: 100px; height: 20px; }

.production .action-type-service-e.action-applied { cursor: default; }

.production .action-type-service-e.action-applied:after { background-image: url(../img/sprite-production.png); background-position: -144px -386px; width: 100px; height: 20px; }

.production .action-type-service-e.action-applied { cursor: default; }

.production .action-type-training { background-image: url(../img/production/actions/04.png); width: 276px; height: 142px; margin: 5px 13px; position: relative; }

.production .action-type-training:before { content: ''; display: block; position: absolute; top: 24px; left: 8px; font-weight: bold; text-shadow: none; }

.production .action-type-training:after { content: ''; display: block; background-image: url(../img/sprite-production.png); background-position: -348px -386px; width: 100px; height: 20px; position: absolute; top: 5px; left: 105px; }

.production .action-type-training:not(.action-applied):hover:after { background-image: url(../img/sprite-production.png); background-position: -246px -386px; width: 100px; height: 20px; }

.production .action-type-training.action-applied { cursor: default; }

.production .action-type-training.action-applied:after { background-image: url(../img/sprite-production.png); background-position: -144px -386px; width: 100px; height: 20px; }

.production .action-type-training.action-applied { cursor: default; }

.production .inactive .action-type-training:before { display: none !important; }

.production .action-type-training[data-functionality="shopping"]:before { content: 'Robi Zakupy'; }

.production .action-type-training[data-functionality="trainer"]:before { content: 'Trener Personalny'; }

.production .action-type-training[data-functionality="mechanic"]:before { content: 'Naprawia Samochód'; }

.production .popup-numbers { background-image: url(../img/production/popup-numbers-bg.png); width: 1200px; height: 699px; position: absolute; top: 50%; left: 50%; margin: -350px 0 0 -600px; padding: 50px 30px; z-index: 1000; }

.production .machine-row { margin-top: 4px; }

.production .machine-row > .col-4 > div { width: 197px; margin-left: -7px; }

.production .number-color-container { margin-bottom: 4px; }

.production .component-color { height: 18px; background-image: url(../img/production/component-color.png); margin-bottom: 2px; }

.production .component-number { height: 30px; background-image: url(../img/production/component-number.png); margin-top: 2px; }

.production .popup-numbers .zone { height: auto; bottom: 30px; top: 18px; }

.production .nav-prev, .production .nav-next { position: absolute; top: 50%; margin-top: -14px; z-index: 101; cursor: default; }

.production .nav-prev.active, .production .nav-next.active { cursor: pointer; }

.production .nav-prev { background-image: url(../img/production/nav-prev.png); left: 3px; }

.production .nav-prev.active { background-image: url(../img/production/nav-prev-active.png); }

.production .nav-prev.active:hover { background-image: url(../img/production/nav-prev-hover.png); }

.production .nav-prev.active:active { background-image: url(../img/production/nav-prev-click.png); }

.production .nav-next { background-image: url(../img/production/nav-next.png); right: 4px; }

.production .nav-next.active { background-image: url(../img/production/nav-next-active.png); }

.production .nav-next.active:hover { background-image: url(../img/production/nav-next-hover.png); }

.production .nav-next.active:active { background-image: url(../img/production/nav-next-click.png); }

.remove { display: block; width: 27px; height: 26px; background: url(../img/production/remove.png); position: absolute; top: 0px; right: 1px; }

.remove:hover { background-image: url(../img/production/remove-hover.png); }

[data-subtype="forced-service"] ~ .remove { display: none; }

.number-small { float: left; height: 18px; width: 19px; }

.number-small-1 { background-image: url(../img/production/number-small-1.png); }

.number-small-2 { background-image: url(../img/production/number-small-2.png); }

.number-small-3 { background-image: url(../img/production/number-small-3.png); }

.number-small-4 { background-image: url(../img/production/number-small-4.png); }

.number-small-5 { background-image: url(../img/production/number-small-5.png); }

.number-small-6 { background-image: url(../img/production/number-small-6.png); }

.number-small-7 { background-image: url(../img/production/number-small-7.png); }

.number-small-8 { background-image: url(../img/production/number-small-8.png); }

.number-small-9 { background-image: url(../img/production/number-small-9.png); }

.number-small-10 { background-image: url(../img/production/number-small-10.png); }

.number-small-11 { background-image: url(../img/production/number-small-11.png); }

.number-small-12 { background-image: url(../img/production/number-small-12.png); }

.color-small-placeholder { float: right; height: 18px; width: 51px; background-image: url(../img/production/color-small-placeholder.png); }

.color-small-red { background-image: url(../img/production/color-small-red.png); }

.color-small-green { background-image: url(../img/production/color-small-green.png); }

.color-small-blue { background-image: url(../img/production/color-small-blue.png); }

.input-number { height: 30px; background: url(../img/production/input-number.png) no-repeat center; color: #fff; text-align: center; border: 0px; width: 100%; font-size: 24px; font-weight: bold; line-height: 24px; margin-top: 2px; }

::-webkit-input-placeholder { color: #fff; }

:-moz-placeholder { color: #fff; }

::-moz-placeholder { color: #fff; }

:-ms-input-placeholder { color: #fff; }

.button-confirm { display: block; width: 163px; height: 36px; background: url(../img/production/button-confirm-non-active.png) no-repeat center; position: absolute; top: 17px; right: 15px; }

.button-confirm.active { background-image: url(../img/production/button-confirm.png); }

.button-confirm.active:hover { background-image: url(../img/production/button-confirm-hover.png); }

.button-confirm.active:active { background-image: url(../img/production/button-confirm-active.png); }

.confirm-button { display: block; width: 163px; height: 36px; background: url(../img/shopping/button-confirm.png) no-repeat center; margin: 7px auto; }

.confirm-button:hover { background-image: url(../img/shopping/button-confirm-hover.png); }

.confirm-button:active { background-image: url(../img/shopping/button-confirm-active.png); }

.change-button { display: block; width: 163px; height: 36px; background: url(../img/shopping/button-change.png) no-repeat center; margin: 7px auto; }

.change-button:hover { background-image: url(../img/shopping/button-change-hover.png); }

.change-button:active { background-image: url(../img/shopping/button-change-active.png); }

/* DIVISION : SELL */
.sell .cover-bg { background-image: url(../img/sell/bg.jpg); }

.sell .depertment-name { background-image: url(../img/sell/department-name.png); }

.sell .timer { background-image: url(../img/sell/counter.png); }

.sell .actions { background-image: url(../img/sell/actions.png); }

.sell .actions:hover { background-image: url(../img/sell/actions-hover.png); }

.sell .actions.active { background-image: url(../img/sell/actions-active.png); }

.sell .actions-panel { background-image: url(../img/sell/actions-panel.png); }

.sell .actions-panel.open { height: 581px; }

.sell .actions-panel .action-1 { background-image: url(../img/sell/actions/action-1.png); }

.sell .actions-panel .action-1:hover { background-image: url(../img/sell/actions/action-1-hover.png); }

.sell .actions-panel .action-1:active { background-image: url(../img/sell/actions/action-1-active.png); }

.sell .actions-panel .action-1-info { background-image: url(../img/sell/actions/action-1-info.png); }

.sell .actions-panel .action-2 { background-image: url(../img/sell/actions/action-2.png); }

.sell .actions-panel .action-2:hover { background-image: url(../img/sell/actions/action-2-hover.png); }

.sell .actions-panel .action-2:active { background-image: url(../img/sell/actions/action-2-active.png); }

.sell .actions-panel .action-2-info { background-image: url(../img/sell/actions/action-2-info.png); }

.sell .actions-panel .action-3 { background-image: url(../img/sell/actions/action-3.png); }

.sell .actions-panel .action-3:hover { background-image: url(../img/sell/actions/action-3-hover.png); }

.sell .actions-panel .action-3:active { background-image: url(../img/sell/actions/action-3-active.png); }

.sell .actions-panel .action-3-info { background-image: url(../img/sell/actions/action-3-info.png); }

.sell .actions-panel .action-4 { background-image: url(../img/sell/actions/action-4.png); }

.sell .actions-panel .action-4:hover { background-image: url(../img/sell/actions/action-4-hover.png); }

.sell .actions-panel .action-4:active { background-image: url(../img/sell/actions/action-4-active.png); }

.sell .actions-panel .action-4-info { background-image: url(../img/sell/actions/action-4-info.png); }

.sell .actions-panel .action-5 { background-image: url(../img/sell/actions/action-5.png); }

.sell .actions-panel .action-5:hover { background-image: url(../img/sell/actions/action-5-hover.png); }

.sell .actions-panel .action-5:active { background-image: url(../img/sell/actions/action-5-active.png); }

.sell .actions-panel .action-5-info { background-image: url(../img/sell/actions/action-5-info.png); }

.sell .functionalities-prices { background-image: url(../img/sell/functionalities-prices.png); }

.sell .functionalities-prices:hover { background-image: url(../img/sell/functionalities-prices-hover.png); }

.sell .functionalities-prices.active { background-image: url(../img/sell/functionalities-prices-active.png); }

.sell .functionalities-prices-panel { background-image: url(../img/sell/functionalities-prices-panel.png); }

.sell .function-1 { background-image: url(../img/sell/functionalities/function-1.png); }

.sell .function-2 { background-image: url(../img/sell/functionalities/function-2.png); }

.sell .function-3 { background-image: url(../img/sell/functionalities/function-3.png); }

.sell .function-4 { background-image: url(../img/sell/functionalities/function-4.png); }

.sell .function-1.unaccepted { background-image: url(../img/sell/functionalities/function-1-unaccepted.png); }

.sell .function-2.unaccepted { background-image: url(../img/sell/functionalities/function-2-unaccepted.png); }

.sell .function-3.unaccepted { background-image: url(../img/sell/functionalities/function-3-unaccepted.png); }

.sell .function-4.unaccepted { background-image: url(../img/sell/functionalities/function-4-unaccepted.png); }

.sell .common-part { background-image: url(../img/sell/common-part.png); }

.sell .end-round { background-image: url(../img/sell/end-round.png); }

.sell .end-round:hover { background-image: url(../img/sell/end-round-hover.png); }

.sell .end-round:active { background-image: url(../img/sell/end-round-active.png); }

.sell .summary { background-image: url(../img/sell/summary.png); }

.sell .summary:hover { background-image: url(../img/sell/summary-hover.png); }

.sell .summary:active { background-image: url(../img/sell/summary-active.png); }

.sell .report { background-image: url(../img/sell/report.png); }

.sell .report:hover { background-image: url(../img/sell/report-hover.png); }

.sell .report:active { background-image: url(../img/sell/report-active.png); }

.sell .orders { background-image: url(../img/sell/orders.png); }

.sell .orders-count { background-image: url(../img/sell/orders-count.png); }

.sell .orders-count.active { background-image: url(../img/sell/orders-count-active.png); }

.sell .queue { background-image: url(../img/sell/queue.png); }

.sell .queue-count { background-image: url(../img/sell/queue-count.png); }

.sell .queue-count.active { background-image: url(../img/sell/queue-count-active.png); }

.sell .magazine { background-image: url(../img/sell/magazine.png); }

.sell .magazine-count { background-image: url(../img/sell/magazine-count.png); }

.sell .magazine-count.active { background-image: url(../img/sell/magazine-count-active.png); }

.sell .sort { background-image: url(../img/sell/sort.png); }

.sell .sort:hover { background-image: url(../img/sell/sort-hover.png); }

.sell .sort.active { background-image: url(../img/sell/sort-active.png); }

.sell .orders-panel { background-image: url(../img/sell/orders-panel.png); position: relative; }

.sell .orders-panel:after { display: block; content: ''; position: absolute; top: 0px; bottom: 0px; left: 0px; right: 0px; background: url(../img/sell/orders-panel-top.png) no-repeat center; z-index: 100; pointer-events: none; }

.sell .orders-panel .up { background: url(../img/sell/arrow-up.png); }

.sell .orders-panel .down { background: url(../img/sell/arrow-down.png); }

.sell .orders-panel .up.active { background: url(../img/sell/arrow-up-active.png); }

.sell .orders-panel .up.active:hover { background: url(../img/sell/arrow-up-hover.png); }

.sell .orders-panel .up.active:active { background: url(../img/sell/arrow-up-click.png); }

.sell .orders-panel .down.active { background: url(../img/sell/arrow-down-active.png); }

.sell .orders-panel .down.active:hover { background: url(../img/sell/arrow-down-hover.png); }

.sell .orders-panel .down.active:active { background: url(../img/sell/arrow-down-click.png); }

.sell .moves { background-image: url(../img/sell/moves.png); }

.sell .move { background-image: url(../img/sell/move.png); }

.sell .move.active { background-image: url(../img/sell/move-active.png); }

.sell .summary-popup-content { background: url(../img/sell/summary-popup.png) no-repeat center; }

.sell .summary-popup .back { background: url(../img/sell/summary-back.png) no-repeat center; }

.sell .summary-popup .back:hover { background-image: url(../img/sell/summary-back-hover.png); }

.sell .summary-popup .back:active { background-image: url(../img/sell/summary-back-active.png); }

.sell .popup-container { position: fixed; top: 0px; bottom: 0px; left: 0px; right: 0px; background: rgba(0, 0, 0, 0.7); z-index: 10; display: block; }

.sell .popup-container .functionalities-prices-popup { width: 494px; height: 411px; background: url(../img/sell/functionalities-prices-popup.png) no-repeat center; position: absolute; top: 50%; left: 50%; margin: -205px 0 0 -247px; }

.sell .popup-container .functionalities-prices-popup input { border: 0px; background: transparent; width: 70px; font-size: 36px; line-height: 32px; position: absolute; text-align: center; font-weight: bold; outline: none; }

.sell .popup-container .functionalities-prices-popup input.functionality-teacher { top: 115px; left: 140px; }

.sell .popup-container .functionalities-prices-popup input.functionality-shopper { top: 115px; left: 382px; }

.sell .popup-container .functionalities-prices-popup input.functionality-trainer { top: 270px; left: 140px; }

.sell .popup-container .functionalities-prices-popup input.functionality-mechanic { top: 270px; left: 382px; }

.sell .popup-container .functionalities-prices-popup .ready { display: block; width: 163px; height: 36px; background: url(../img/sell/button-ready.png) no-repeat center; position: absolute; bottom: 14px; left: 50%; margin-left: -81px; cursor: pointer; }

.sell .popup-container .functionalities-prices-popup .ready:hover { background-image: url(../img/sell/button-ready-hover.png); }

.sell .popup-container .functionalities-prices-popup .ready:active { background-image: url(../img/sell/button-ready-active.png); }

.sell .popup-container .offer-type-popup { width: 497px; height: 248px; padding: 25px; background: url(../img/common-popup-small.png) no-repeat center; position: absolute; top: 50%; left: 50%; margin: -129px 0 0 -248px; text-align: center; font-size: 18px; animation: appear-pop 400ms ease-out; }

.sell .popup-container .offer-type-popup[ng-click] { cursor: default; }

.sell .comment { width: 25px; height: 25px; position: absolute; bottom: 4px; right: 4px; }

.sell .comment .comment-icon { width: 25px; height: 25px; background: url(../img/sell/add-note.png) no-repeat 0 0; cursor: pointer; }

.sell .comment .comment-text { display: none; width: 220px; min-height: 14px; line-height: 14px; position: absolute; bottom: 30px; right: 0; z-index: 1000; color: #fff; font-size: 10px; font-weight: normal; }

.sell .comment .value { display: none; }

.sell .comment .placeholder, .sell .comment .value { padding: 4px; max-width: 220px; min-width: 50px; float: right; background: rgba(0, 0, 0, 0.9); text-align: left; }

.sell .comment:hover { z-index: 1500; }

.sell .comment:hover .comment-text { display: block; }

.sell .comment.is-edit-mode .placeholder, .sell .comment.is-view-mode .placeholder, .sell .comment.is-active .placeholder { display: none; }

.sell .comment.is-edit-mode .value, .sell .comment.is-view-mode .value, .sell .comment.is-active .value { display: block; }

.sell .comment.is-readonly .comment-icon, .sell .comment.is-readonly.is-active .comment-icon { background: url(../img/sell/note-embeded.png) no-repeat 0 0; }

.sell .comment.is-active .comment-icon { background: url(../img/sell/add-note-active.png) no-repeat 0 0; }

.sell .comment.item-comment { top: 205px; margin-left: 199px; }

.ui-draggable-dragging .comment { display: none; }

.robot-type-select { text-align: center; margin: 25px auto; }

.robot-type-select li { display: inline-block; width: 70px; height: 90px; margin: 0 5px; cursor: pointer; background-position: 50% 8px; background-repeat: no-repeat; background-color: #6a7170; box-shadow: inset -1px -1px 40px rgba(0, 0, 0, 0.5), inset 1px 1px 0 rgba(255, 255, 255, 0.3), inset -1px -1px 0 rgba(0, 0, 0, 0.3), -1px -1px 0 rgba(255, 255, 255, 0.3), 1px 1px 0 rgba(0, 0, 0, 0.3); border: 3px solid #74797b; border-bottom-width: 4px; border-right-width: 4px; border-radius: 4px; }

.robot-type-select li.active { cursor: default; background-color: #7caf21; }

.robot-type-select li.nanny { background-image: url(../img/sell/orders/type-nanny.png); }

.robot-type-select li.driver { background-image: url(../img/sell/orders/type-driver.png); }

.robot-type-select li.handyman { background-image: url(../img/sell/orders/type-handyman.png); }

.robot-type-select li.housekeeper { background-image: url(../img/sell/orders/type-housekeeper.png); }

.button-find-offer { display: inline-block; width: 163px; height: 36px; background: url(../img/sell/search.png) no-repeat 0 0; font-size: 0; }

.button-find-offer:hover { background: url(../img/sell/search-hover.png) no-repeat 0 0; }

.button-find-offer:active { background: url(../img/sell/search-active.png) no-repeat 0 0; }

.sell .nano > .nano-pane { background: url(../img/sell/scrollbar-bg.png) no-repeat center; }

.sell .nano > .nano-pane > .nano-slider { background: url(../img/sell/scrollbar-top.png) no-repeat top center, url(../img/sell/scrollbar-bottom.png) no-repeat bottom center; }

.sell .nano > .nano-pane > .nano-slider:after { background: url(../img/sell/scrollbar-middle.png) repeat-y top center; }

/* SELL : ORDERS CARDS */
.order-card { width: 269px; height: 156px; background: url(../img/sell/orders/bg.png) no-repeat center; position: relative; margin-bottom: 10px; }

.order-card .number { position: absolute; top: 5px; left: 5px; width: 50px; font-size: 21px; line-height: 25px; text-align: center; cursor: default; }

.order-card .time { width: 28px; position: absolute; top: 1px; right: 3px; text-align: center; font-size: 18px; line-height: 1; cursor: default; }

.order-card .label { width: 102px; position: absolute; left: 26px; top: 1px; font-size: 14px; line-height: 1; text-align: center; cursor: default; }

.order-card .label-initial { width: 24px; position: absolute; left: 1px; top: 1px; font-size: 18px; text-align: center; cursor: default; }

.order-card .price { width: 68px; position: absolute; text-align: center; line-height: 28px; font-size: 24px; text-shadow: none; }

.order-card .price.price-basic { top: 69px; left: 13px; }

.order-card .price.price-penalty { top: 121px; left: 13px; }

.order-card .price.price-top { top: 38px; left: 99px; height: 29px; }

.order-card .price.price-top.fake-input:before { content: '50'; display: block; width: 46px; text-align: center; line-height: 28px; font-size: 24px; text-shadow: none; border: 0; color: #000; background: #fafafa; }

.order-card .price.price-top.fake-input:after { content: ' k'; display: block; position: absolute; right: 5px; top: 0; }

.order-card .price.price-bottom { top: 105px; left: 99px; height: 29px; }

.order-card .price input { display: block; width: 46px; height: 28px; padding: 0; background: #fafafa; text-align: center; line-height: 28px; font-size: 24px; text-shadow: none; border: 0; color: #000; appearance: none; -moz-appearance: textfield; }

.order-card .price input::-webkit-outer-spin-button, .order-card .price input::-webkit-inner-spin-button { appearance: none; margin: 0; }

.order-card .price.input:after { content: ' k'; display: block; position: absolute; right: 5px; top: 0; }

.order-card .color { position: absolute; right: 9px; width: 22px; height: 27px; }

.order-card .color.green { background: url(../img/sell/orders/color-green.png) no-repeat center; }

.order-card .color.blue { background: url(../img/sell/orders/color-blue.png) no-repeat center; }

.order-card .color.red { background: url(../img/sell/orders/color-red.png) no-repeat center; }

.order-card .color.top-color { top: 38px; }

.order-card .color.bottom-color { top: 106px; }

.order-card .functions-fill { position: absolute; top: 23px; right: 35px; width: 62px; height: 125px; pointer-events: none; }

.order-card .function { width: 62px; height: 58px; position: absolute; }

.order-card .function .function-sign { width: 62px; height: 58px; }

.order-card .function .function-sign.type--none { background: url(../img/sell/orders/function-none.png) no-repeat center; }

.order-card .function .function-sign.type--mechanic { background: url(../img/sell/orders/function-mechanic-active.png) no-repeat center; }

.order-card .function .function-sign.type--mechanic:hover { background: url(../img/sell/orders/function-mechanic-hover.png) no-repeat center; }

.order-card .function .function-sign.type--shopping { background: url(../img/sell/orders/function-shopper-active.png) no-repeat center; }

.order-card .function .function-sign.type--shopping:hover { background: url(../img/sell/orders/function-shopper-hover.png) no-repeat center; }

.order-card .function .function-sign.type--tutor { background: url(../img/sell/orders/function-teacher-active.png) no-repeat center; }

.order-card .function .function-sign.type--tutor:hover { background: url(../img/sell/orders/function-teacher-hover.png) no-repeat center; }

.order-card .function .function-sign.type--trainer { background: url(../img/sell/orders/function-trainer-active.png) no-repeat center; }

.order-card .function .function-sign.type--trainer:hover { background: url(../img/sell/orders/function-trainer-hover.png) no-repeat center; }

.order-card .function > .function-sign.type--none { cursor: pointer; }

.order-card .function > .function-sign.type--none:hover { background: url(../img/sell/orders/function-none-hover.png) no-repeat center; }

.order-card .function .current-function { position: absolute; top: 0; left: 0; }

.order-card .function .function-change { display: none; width: 214px; height: 78px; padding: 0 0 0 5px; position: absolute; background: #222b28; border: 1px solid #d9d9d9; }

.order-card .function .function-change li { float: left; margin: 9px 3px; }

.order-card .function .function-change .function-sign.type--none { background: url(../img/sell/orders/function-none-active.png) no-repeat center; }

.order-card .function.active .function-change { display: block; z-index: 1000; }

.order-card .function.function--top { top: 23px; right: 35px; }

.order-card .function.function--top .function-change { top: -81px; left: 50%; margin-left: -107px; }

.order-card .function.function--top .function-change:before { content: ''; display: block; margin: 0 0 0 -14px; position: absolute; top: 100%; left: 50%; width: 0; height: 0; border-style: solid; border-width: 12px 14px 0 14px; border-color: #d9d9d9 transparent transparent transparent; }

.order-card .function.function--top .function-change:after { content: ''; display: block; margin: -1px 0 0 -14px; position: absolute; top: 100%; left: 50%; width: 0; height: 0; border-style: solid; border-width: 12px 14px 0 14px; border-color: #222b28 transparent transparent transparent; }

.order-card .function.function--bottom { top: 90px; right: 35px; }

.order-card .function.function--bottom .function-change { bottom: -81px; left: 50%; margin-left: -107px; }

.order-card .function.function--bottom .function-change:before { content: ''; display: block; margin: 0 0 0 -14px; position: absolute; bottom: 100%; left: 50%; width: 0; height: 0; border-style: solid; border-width: 0 14px 12px 14px; border-color: transparent transparent #d9d9d9 transparent; }

.order-card .function.function--bottom .function-change:after { content: ''; display: block; margin: 0 0 -1px -14px; position: absolute; bottom: 100%; left: 50%; width: 0; height: 0; border-style: solid; border-width: 0 14px 12px 14px; border-color: transparent transparent #222b28 transparent; }

.order-card .functionalities { display: none; position: absolute; top: 89px; left: 5px; right: 5px; }

.order-card .functionalities li { float: left; width: 25%; height: 60px; opacity: 0.5; transition: opacity 250ms ease-in-out; }

.order-card .functionalities li.active, .order-card .functionalities li:hover { opacity: 1; }

.order-card .functionalities .teacher { background: url(../img/sell/orders/function-teacher.png) no-repeat center; }

.order-card .functionalities .shopper { background: url(../img/sell/orders/function-shopper.png) no-repeat center; }

.order-card .functionalities .trainer { background: url(../img/sell/orders/function-trainer.png) no-repeat center; }

.order-card .functionalities .mechanic { background: url(../img/sell/orders/function-mechanic.png) no-repeat center; }

.order-card .functionalities .teacher.active { background: url(../img/sell/orders/function-teacher-active.png) no-repeat center; }

.order-card .functionalities .shopper.active { background: url(../img/sell/orders/function-shopper-active.png) no-repeat center; }

.order-card .functionalities .trainer.active { background: url(../img/sell/orders/function-trainer-active.png) no-repeat center; }

.order-card .functionalities .mechanic.active { background: url(../img/sell/orders/function-mechanic-active.png) no-repeat center; }

/* SELL : ROBOTS CARDS */
.robot-card { width: 269px; height: 156px; background: url(../img/sell/robot-card-bg.png) no-repeat center; position: relative; margin-bottom: 10px; text-shadow: none; }

.robot-card .number { position: absolute; top: 8px; left: 70px; font-size: 16px; line-height: 1; font-weight: bold; }

.robot-card .desc { width: 245px; position: absolute; top: 28px; left: 10px; font-size: 14px; line-height: 18px; text-align: left; font-weight: normal; }

.robot-card .buttons { display: flex; flex-direction: column; justify-content: center; align-items: center; position: absolute; top: 5px; bottom: 5px; left: 5px; right: 5px; }

.robot-card .buttons .button { margin: 1px auto; opacity: 0; transition: opacity 150ms ease; }

.robot-card .buttons .button.tutorial-tmp-visible { display: block !important; opacity: 1 !important; pointer-events: none !important; }

.robot-card:hover .buttons .button { opacity: 1; }

/* DIVISION : SHOPPING */
.shopping .cover-bg { background-image: url(../img/shopping/bg.jpg); }

.shopping .depertment-name { background-image: url(../img/shopping/department-name.png); }

.shopping .timer { background-image: url(../img/shopping/counter.png); }

.shopping .media { background-image: url(../img/shopping/media.png); }

.shopping .media:hover { background-image: url(../img/shopping/media-hover.png); }

.shopping .media.active { background-image: url(../img/shopping/media-active.png); }

.shopping .binders { background-image: url(../img/shopping/binders.png); }

.shopping .binders:hover { background-image: url(../img/shopping/binders-hover.png); }

.shopping .binders.active { background-image: url(../img/shopping/binders-active.png); }

.shopping .offers { background-image: url(../img/shopping/offers.png); }

.shopping .offers:hover { background-image: url(../img/shopping/offers-hover.png); }

.shopping .offers.active { background-image: url(../img/shopping/offers-active.png); }

.shopping .saldo { background-image: url(../img/shopping/saldo.png); }

.shopping .saldo.is-low { background-image: url(../img/shopping/saldo-red.png); }

.shopping .end-round { background-image: url(../img/shopping/end-round.png); }

.shopping .end-round:hover { background-image: url(../img/shopping/end-round-hover.png); }

.shopping .end-round:active { background-image: url(../img/shopping/end-round-active.png); }

.shopping .summary { background-image: url(../img/shopping/summary.png); }

.shopping .summary:hover { background-image: url(../img/shopping/summary-hover.png); }

.shopping .summary:active { background-image: url(../img/shopping/summary-active.png); }

.shopping .report { background-image: url(../img/shopping/report.png); }

.shopping .report:hover { background-image: url(../img/shopping/report-hover.png); }

.shopping .report:active { background-image: url(../img/shopping/report-active.png); }

.shopping .wide-stock-panel { display: none; }

.shopping .wide-stock-col { display: none; }

.shopping .offers-compare-active .wide-stock-panel { display: block; }

.shopping .offers-compare-active .narrow-stock-panel { display: none; }

.shopping .offers-compare-active .wide-stock-col { display: block; }

.shopping .offers-compare-active .stock-col { display: none; }

.shopping .offers-compare-active .orders-col { display: none; }

.shopping .offers-compare-active .compare { background-image: url(../img/shopping/uncompare.png); }

.shopping .offers-compare-active .compare:hover { background-image: url(../img/shopping/uncompare-hover.png); }

.shopping .offers-compare-active .compare:active { background-image: url(../img/shopping/uncompare-active.png); }

.shopping .stock { background-image: url(../img/shopping/stock.png); }

.shopping .wide-stock { background-image: url(../img/shopping/wide-stock.png); }

.shopping .compare { background-image: url(../img/shopping/compare.png); }

.shopping .compare:hover { background-image: url(../img/shopping/compare-hover.png); }

.shopping .compare:active { background-image: url(../img/shopping/compare-active.png); }

.shopping .orders { background-image: url(../img/shopping/orders.png); }

.shopping .narrow-stock-panel .stock-panel { background-image: url(../img/shopping/stock-bg.png); position: relative; }

.shopping .narrow-stock-panel .stock-panel:after { display: block; content: ''; position: absolute; top: 0px; bottom: 0px; left: 0px; right: 0px; z-index: 100; pointer-events: none; }

.shopping .narrow-stock-panel .stock-panel.day-3:after { background: url(../img/shopping/stock-normaldays.png) no-repeat; }

.shopping .narrow-stock-panel .stock-panel.day-2:after { background: url(../img/shopping/stock-normaldays.png) no-repeat; }

.shopping .narrow-stock-panel .stock-panel.day-1:after { background: url(../img/shopping/stock-today.png) no-repeat; }

.shopping .wide-stock-panel .stock-panel { width: 1196px; margin: 0; background-image: url(../img/shopping/stock-bg-wide.png); position: relative; }

.shopping .wide-stock-panel .stock-panel:after { display: block; content: ''; position: absolute; top: 0px; bottom: 0px; left: 0px; right: 0px; z-index: 100; pointer-events: none; }

.shopping .wide-stock-panel .stock-panel.day-3:after { background-image: url(../img/shopping/widestock-normaldays.png); }

.shopping .wide-stock-panel .stock-panel.day-2:after { background-image: url(../img/shopping/widestock-normaldays.png); }

.shopping .wide-stock-panel .stock-panel.day-1:after { background-image: url(../img/shopping/widestock-todayday.png); }

.shopping .offers-panel-content { background-image: url(../img/shopping/panel-offers.png); }

.shopping .offers-compare-panel { background-image: url(../img/shopping/offers-compare-panel.png); height: 581px; display: none; }

.shopping .offers-compare-active .order-panel-container { display: none; }

.shopping .offers-compare-active .offers-compare-panel { display: block; }

.shopping .offer-material-1 { background-image: url(../img/shopping/offer-material-1.png); }

.shopping .offer-material-1 .badge { background-image: url(../img/shopping/badge-red.png); }

.shopping .offer-material-2 { background-image: url(../img/shopping/offer-material-2.png); }

.shopping .offer-material-2 .badge { background-image: url(../img/shopping/badge-blue.png); }

.shopping .offer-material-3 { background-image: url(../img/shopping/offer-material-3.png); }

.shopping .offer-material-3 .badge { background-image: url(../img/shopping/badge-green.png); }

.shopping .offer-material-4 { background-image: url(../img/shopping/offer-material-4.png); }

.shopping .offer-material-4 .badge { background-image: url(../img/shopping/badge-violet.png); }

.shopping .offer-material-5 { background-image: url(../img/shopping/offer-material-5.png); }

.shopping .offer-material-5 .badge { background-image: url(../img/shopping/badge-yellow.png); }

.shopping .offer-small.offer-material-1 { background-image: url(../img/shopping/offer-material-1-small.png); }

.shopping .offer-small.offer-material-2 { background-image: url(../img/shopping/offer-material-2-small.png); }

.shopping .offer-small.offer-material-3 { background-image: url(../img/shopping/offer-material-3-small.png); }

.shopping .offer-small.offer-material-4 { background-image: url(../img/shopping/offer-material-4-small.png); }

.shopping .offer-small.offer-material-5 { background-image: url(../img/shopping/offer-material-5-small.png); }

.shopping .media-popup { display: none; }

.shopping .media-popup.open { display: block; }

.shopping .media-popup .modal-content { width: 760px; height: 400px; position: absolute; top: 50%; left: 50%; margin: -200px 0 0 -380px; background: url(../img/shopping/media-popup/bg.png) no-repeat 0 0; }

.shopping .media-popup .modal-row { width: 741px; margin: 42px auto 0; }

.shopping .media-popup .modal-row .modal-col { float: left; padding: 0 23px; width: 33.333%; }

.shopping .media-popup .headline { width: 201px; height: 31px; font-size: 0; background-position: 0 0; background-repeat: no-repeat; }

.shopping .media-popup .headline--step-1 { background-image: url(../img/shopping/media-popup/offer-step-01.png); }

.shopping .media-popup .headline--step-2 { background-image: url(../img/shopping/media-popup/offer-step-02.png); }

.shopping .media-popup .headline--step-3 { background-image: url(../img/shopping/media-popup/offer-step-03.png); }

.shopping .media-popup .selection-1 li { margin-top: 8px; }

.shopping .media-popup .selection-1 input { display: none; }

.shopping .media-popup .selection-1 label { display: block; width: 201px; height: 36px; cursor: pointer; font-size: 0; background-position: 0 0; background-repeat: no-repeat; }

.shopping .media-popup .selection-1 label[for="media-selection-1--1"] { background-image: url(../img/shopping/media-popup/media-01.png); }

.shopping .media-popup .selection-1 label[for="media-selection-1--1"]:hover { background-image: url(../img/shopping/media-popup/media-01-hover.png); }

.shopping .media-popup .selection-1 label[for="media-selection-1--1"]:active { background-image: url(../img/shopping/media-popup/media-01-active.png); }

.shopping .media-popup .selection-1 input:checked ~ label[for="media-selection-1--1"] { background-image: url(../img/shopping/media-popup/media-01-active.png); cursor: default; }

.shopping .media-popup .selection-1 label[for="media-selection-1--2"] { background-image: url(../img/shopping/media-popup/media-02.png); }

.shopping .media-popup .selection-1 label[for="media-selection-1--2"]:hover { background-image: url(../img/shopping/media-popup/media-02-hover.png); }

.shopping .media-popup .selection-1 label[for="media-selection-1--2"]:active { background-image: url(../img/shopping/media-popup/media-02-active.png); }

.shopping .media-popup .selection-1 input:checked ~ label[for="media-selection-1--2"] { background-image: url(../img/shopping/media-popup/media-02-active.png); cursor: default; }

.shopping .media-popup .selection-1 label[for="media-selection-1--3"] { background-image: url(../img/shopping/media-popup/media-03.png); }

.shopping .media-popup .selection-1 label[for="media-selection-1--3"]:hover { background-image: url(../img/shopping/media-popup/media-03-hover.png); }

.shopping .media-popup .selection-1 label[for="media-selection-1--3"]:active { background-image: url(../img/shopping/media-popup/media-03-active.png); }

.shopping .media-popup .selection-1 input:checked ~ label[for="media-selection-1--3"] { background-image: url(../img/shopping/media-popup/media-03-active.png); cursor: default; }

.shopping .media-popup .selection-1 label[for="media-selection-1--4"] { background-image: url(../img/shopping/media-popup/media-04.png); }

.shopping .media-popup .selection-1 label[for="media-selection-1--4"]:hover { background-image: url(../img/shopping/media-popup/media-04-hover.png); }

.shopping .media-popup .selection-1 label[for="media-selection-1--4"]:active { background-image: url(../img/shopping/media-popup/media-04-active.png); }

.shopping .media-popup .selection-1 input:checked ~ label[for="media-selection-1--4"] { background-image: url(../img/shopping/media-popup/media-04-active.png); cursor: default; }

.shopping .media-popup .selection-1 label[for="media-selection-1--5"] { background-image: url(../img/shopping/media-popup/media-05.png); }

.shopping .media-popup .selection-1 label[for="media-selection-1--5"]:hover { background-image: url(../img/shopping/media-popup/media-05-hover.png); }

.shopping .media-popup .selection-1 label[for="media-selection-1--5"]:active { background-image: url(../img/shopping/media-popup/media-05-active.png); }

.shopping .media-popup .selection-1 input:checked ~ label[for="media-selection-1--5"] { background-image: url(../img/shopping/media-popup/media-05-active.png); cursor: default; }

.shopping .media-popup .selection-1 label[for="media-selection-1--6"] { background-image: url(../img/shopping/media-popup/media-06.png); }

.shopping .media-popup .selection-1 label[for="media-selection-1--6"]:hover { background-image: url(../img/shopping/media-popup/media-06-hover.png); }

.shopping .media-popup .selection-1 label[for="media-selection-1--6"]:active { background-image: url(../img/shopping/media-popup/media-06-active.png); }

.shopping .media-popup .selection-1 input:checked ~ label[for="media-selection-1--6"] { background-image: url(../img/shopping/media-popup/media-06-active.png); cursor: default; }

.shopping .media-popup .selection-2 { margin-top: 32px; opacity: .7; pointer-events: none; }

.shopping .media-popup .selection-2.active { opacity: 1; pointer-events: auto; }

.shopping .media-popup .selection-2 li { margin-top: 8px; }

.shopping .media-popup .selection-2 input { display: none; }

.shopping .media-popup .selection-2 label { display: block; width: 120px; height: 92px; padding-left: 29px; margin: 0 auto; position: relative; left: -14px; cursor: pointer; background: url(../img/shopping/media-popup/option-01.png) no-repeat 0 0; }

.shopping .media-popup .selection-2 label .option { display: block; width: 23px; position: absolute; left: 4px; top: 12px; font-size: 22px; font-weight: bold; text-align: center; }

.shopping .media-popup .selection-2 label .label { display: flex; width: 100%; justify-content: center; align-items: center; height: 48px; padding: 10px 10px; font-size: 12px; text-shadow: none; text-align: center; }

.shopping .media-popup .selection-2 label .price { display: block; padding: 9px 10px; font-size: 24px; text-shadow: none; text-align: center; }

.shopping .media-popup .selection-2 label:hover { background-image: url(../img/shopping/media-popup/option-01-hover.png); }

.shopping .media-popup .selection-2 label:active { background-image: url(../img/shopping/media-popup/option-01-active.png); }

.shopping .media-popup .selection-2 input:checked ~ label { background-image: url(../img/shopping/media-popup/option-01-active.png); cursor: default; }

.shopping .media-popup .selection-3 { margin: 70px -13px 0; opacity: .7; pointer-events: none; }

.shopping .media-popup .selection-3.active { opacity: 1; pointer-events: auto; }

.shopping .media-popup .selection-3 .button { display: block; width: 52px; height: 36px; margin-top: 45px; font-size: 0; float: left; }

.shopping .media-popup .selection-3 .button--minus { background: url(../img/shopping/binders-popup/buttons/buy-less-inactive.png) no-repeat 0 0; cursor: default; float: left; }

.shopping .media-popup .selection-3 .button--minus.active { cursor: pointer; background-image: url(../img/shopping/binders-popup/buttons/buy-less.png); }

.shopping .media-popup .selection-3 .button--minus.active:hover { background-image: url(../img/shopping/binders-popup/buttons/buy-less-hover.png); }

.shopping .media-popup .selection-3 .button--minus.active:active { background-image: url(../img/shopping/binders-popup/buttons/buy-less-active.png); }

.shopping .media-popup .selection-3 .button--plus { background: url(../img/shopping/binders-popup/buttons/buy-more-inactive.png) no-repeat 0 0; cursor: default; float: left; }

.shopping .media-popup .selection-3 .button--plus.active { cursor: pointer; background-image: url(../img/shopping/binders-popup/buttons/buy-more.png); }

.shopping .media-popup .selection-3 .button--plus.active:hover { background-image: url(../img/shopping/binders-popup/buttons/buy-more-hover.png); }

.shopping .media-popup .selection-3 .button--plus.active:active { background-image: url(../img/shopping/binders-popup/buttons/buy-more-active.png); }

.shopping .media-popup .selection-3 .value { display: block; width: 116px; height: 87px; margin: 0 3px; padding-top: 46px; background: url(../img/shopping/media-popup/set-time.png) no-repeat 0 0; font-size: 24px; text-align: center; float: left; }

.shopping .media-popup .buttons { position: absolute; bottom: 17px; left: 172px; right: 172px; }

.shopping .media-popup .buttons .button { display: block; width: 163px; height: 36px; font-size: 0; }

.shopping .media-popup .buttons .button--ok { background: url(../img/shopping/media-popup/ok-inactive.png) no-repeat 0 0; cursor: default; float: left; }

.shopping .media-popup .buttons .button--ok.active { cursor: pointer; background-image: url(../img/shopping/media-popup/ok.png); }

.shopping .media-popup .buttons .button--ok.active:hover { background-image: url(../img/shopping/media-popup/ok-hover.png); }

.shopping .media-popup .buttons .button--ok.active:active { background-image: url(../img/shopping/media-popup/ok-active.png); }

.shopping .media-popup .buttons .button--cancel { background: url(../img/shopping/media-popup/cancel.png) no-repeat 0 0; cursor: pointer; float: right; }

.shopping .media-popup .buttons .button--cancel:hover { background-image: url(../img/shopping/media-popup/cancel-hover.png); }

.shopping .media-popup .buttons .button--cancel:active { background-image: url(../img/shopping/media-popup/cancel-active.png); }

.shopping .material-popup { display: none; position: absolute; z-index: 15000; width: 494px; height: 458px; position: absolute; top: 50%; left: 50%; margin: -229px 0 0 -247px; padding: 50px 25px; background: url(../img/shopping/material-popup/bg.png) no-repeat center; }

.shopping .material-popup.tutorial-fake-view { background: url(../img/tutorial-fakes/sales-popup.png) no-repeat 0 0; }

.shopping .material-popup.tutorial-fake-view > * { visibility: hidden; }

.shopping .material-popup.open { display: block; }

.shopping .material-popup .offer { margin: 0 auto; }

.shopping .material-popup .buying-slots { margin-top: 50px; line-height: 50px; text-align: center; width: 250px; float: left; margin-right: 50px; }

.shopping .material-popup .buying-slots .add-slot, .shopping .material-popup .buying-slots .remove-slot { display: block; float: left; width: 50px; height: 50px; background: #eee; margin-top: 50px; color: #fff; }

.shopping .material-popup .buying-slots .slots { float: left; }

.shopping .material-popup .buying-slots .slots .slot { float: left; width: 50px; height: 50px; background: #ccc; }

.shopping .material-popup .buying-slots .slots .increment, .shopping .material-popup .buying-slots .slots .decrement { display: block; width: 100%; height: 50px; background: #ddd; color: #fff; }

.shopping .material-popup .buying-buttons { text-align: center; }

.shopping .material-popup .buying-buttons .button { display: inline-block; width: 124px; height: 29px; line-height: 29px; margin: 0 12px; }

.shopping .material-popup .buying-buttons .button.close { background: url(../img/shopping/material-popup/button-close.png); }

.shopping .material-popup .buying-buttons .button.close:hover { background: url(../img/shopping/material-popup/button-close-hover.png); }

.shopping .material-popup .buying-buttons .button.close:active { background: url(../img/shopping/material-popup/button-close-active.png); }

.shopping .material-popup .buying-buttons .button.buy { background: url(../img/shopping/material-popup/button-buy.png); }

.shopping .material-popup .buying-buttons .button.buy:hover { background: url(../img/shopping/material-popup/button-buy-hover.png); }

.shopping .material-popup .buying-buttons .button.buy:active { background: url(../img/shopping/material-popup/button-buy-active.png); }

.shopping .material-popup .buying-buttons .button.express { background: url(../img/shopping/material-popup/button-express.png); }

.shopping .material-popup .buying-buttons .button.express:hover { background: url(../img/shopping/material-popup/button-express-hover.png); }

.shopping .material-popup .buying-buttons .button.express:active { background: url(../img/shopping/material-popup/button-express-active.png); }

.shopping .material-popup:not(.no-cycle) .binder-buy-offer.wide { background-image: url(../img/sprite-shopping.png); background-position: 0px -137px; width: 203px; height: 97px; }

.shopping .material-popup:not(.no-cycle) .binder-buy-offer.wide .buy-panel { width: 180px; margin: 0 auto; }

.shopping .material-popup .binder-buy-container { text-align: center; margin: 13px -5px; }

.shopping .material-popup .binder-buy-offer { display: inline-block; margin: 2px 10px; vertical-align: top; }

.shopping .material-popup .binder-buy-offer .buy-title { font-size: 16px; text-shadow: none; }

.shopping .material-popup .round-price, .shopping .material-popup .total-price { background-image: url(../img/sprite-shopping.png); background-position: -205px -38px; width: 203px; height: 97px; color: #fff; text-align: center; font-size: 30px; display: inline-block; margin: 2px 10px; padding: 5px 14px 12px 12px; vertical-align: top; }

.shopping .material-popup .round-price .price-title, .shopping .material-popup .total-price .price-title { font-size: 16px; text-shadow: none; }

.shopping .material-popup .round-price .price-value, .shopping .material-popup .total-price .price-value { font-size: 24px; font-weight: bold; margin-top: 24px; text-shadow: none; }

.shopping .material-popup .round-price .price-value--basic, .shopping .material-popup .total-price .price-value--basic { display: none; }

.shopping .material-popup .round-price.is-losing, .shopping .material-popup .total-price.is-losing { background-image: url(../img/sprite-shopping.png); background-position: -205px -137px; width: 203px; height: 97px; }

.shopping .material-popup .round-price.is-saving, .shopping .material-popup .total-price.is-saving { background-image: url(../img/sprite-shopping.png); background-position: 0px -38px; width: 203px; height: 97px; }

.shopping .material-popup .round-price.is-losing .price-value, .shopping .material-popup .round-price.is-saving .price-value, .shopping .material-popup .total-price.is-losing .price-value, .shopping .material-popup .total-price.is-saving .price-value { width: 45%; float: left; }

.shopping .material-popup .round-price.is-losing .price-value--basic, .shopping .material-popup .round-price.is-saving .price-value--basic, .shopping .material-popup .total-price.is-losing .price-value--basic, .shopping .material-popup .total-price.is-saving .price-value--basic { display: block; float: right; margin-right: -3%; }

.shopping .material-popup.no-cycle { background: url(../img/shopping/material-popup/small-bg.png) no-repeat center; height: 341px; margin-top: -170px; }

.shopping .material-popup.no-cycle.is-express { background-image: url(../img/shopping/material-popup/small-bg-express.png); height: 385px; margin-top: -192px; padding-top: 80px; z-index: 10000; }

.shopping .nav-prev, .shopping .nav-next { position: absolute; top: 50%; margin-top: 0; z-index: 101; cursor: default; }

.shopping .nav-prev.active, .shopping .nav-next.active { cursor: pointer; }

.shopping .nav-prev { background-image: url(../img/shopping/nav-prev.png); left: 4px; }

.shopping .nav-prev.active { background-image: url(../img/shopping/nav-prev-active.png); }

.shopping .nav-prev.active:hover { background-image: url(../img/shopping/nav-prev-hover.png); }

.shopping .nav-prev.active:active { background-image: url(../img/shopping/nav-prev-click.png); }

.shopping .nav-next { background-image: url(../img/shopping/nav-next.png); right: 5px; }

.shopping .nav-next.active { background-image: url(../img/shopping/nav-next-active.png); }

.shopping .nav-next.active:hover { background-image: url(../img/shopping/nav-next-hover.png); }

.shopping .nav-next.active:active { background-image: url(../img/shopping/nav-next-click.png); }

.summary-popup--shopping .summary-popup-content { background: url(../img/shopping/summary-popup.png) no-repeat center; }

.summary-popup--shopping .summary-popup .back { background: url(../img/shopping/summary-back.png) no-repeat center; }

.summary-popup--shopping .summary-popup .back:hover { background-image: url(../img/shopping/summary-back-hover.png); }

.summary-popup--shopping .summary-popup .back:active { background-image: url(../img/shopping/summary-back-active.png); }

.summary-popup--shopping .offers-panel-inner { padding: 8px 0 0 21px; }

.summary-popup--shopping .nano > .nano-pane { background: url(../img/shopping/scrollbar-bg.png) no-repeat center; }

.summary-popup--shopping .nano > .nano-pane > .nano-slider { background: url(../img/shopping/scrollbar-top.png) no-repeat top center, url(../img/shopping/scrollbar-bottom.png) no-repeat bottom center; }

.summary-popup--shopping .nano > .nano-pane > .nano-slider:after { background: url(../img/shopping/scrollbar-middle.png) repeat-y top center; }

.summary-popup--assembly .summary-popup-content { background: url(../img/assembly/summary-popup.png) no-repeat center; }

.summary-popup--assembly .summary-popup .back { background: url(../img/assembly/summary-back.png) no-repeat center; }

.summary-popup--assembly .summary-popup .back:hover { background-image: url(../img/assembly/summary-back-hover.png); }

.summary-popup--assembly .summary-popup .back:active { background-image: url(../img/assembly/summary-back-active.png); }

.summary-popup--assembly .offers-panel-inner { padding: 8px 0 0 21px; }

.summary-popup--assembly .nano > .nano-pane { background: url(../img/assembly/scrollbar-bg.png) no-repeat center; }

.summary-popup--assembly .nano > .nano-pane > .nano-slider { background: url(../img/assembly/scrollbar-top.png) no-repeat top center, url(../img/assembly/scrollbar-bottom.png) no-repeat bottom center; }

.summary-popup--assembly .nano > .nano-pane > .nano-slider:after { background: url(../img/assembly/scrollbar-middle.png) repeat-y top center; }

.summary-popup--production .summary-popup-content { background: url(../img/production/summary-popup.png) no-repeat center; }

.summary-popup--production .summary-popup .back { background: url(../img/production/summary-back.png) no-repeat center; }

.summary-popup--production .summary-popup .back:hover { background-image: url(../img/production/summary-back-hover.png); }

.summary-popup--production .summary-popup .back:active { background-image: url(../img/production/summary-back-active.png); }

.summary-popup--production .offers-panel-inner { padding: 8px 0 0 21px; }

.summary-popup--production .nano > .nano-pane { background: url(../img/production/scrollbar-bg.png) no-repeat center; }

.summary-popup--production .nano > .nano-pane > .nano-slider { background: url(../img/production/scrollbar-top.png) no-repeat top center, url(../img/production/scrollbar-bottom.png) no-repeat bottom center; }

.summary-popup--production .nano > .nano-pane > .nano-slider:after { background: url(../img/production/scrollbar-middle.png) repeat-y top center; }

.summary-popup--sales .summary-popup-content { background: url(../img/sell/summary-popup.png) no-repeat center; }

.summary-popup--sales .summary-popup .back { background: url(../img/sell/summary-back.png) no-repeat center; }

.summary-popup--sales .summary-popup .back:hover { background-image: url(../img/sell/summary-back-hover.png); }

.summary-popup--sales .summary-popup .back:active { background-image: url(../img/sell/summary-back-active.png); }

.summary-popup--sales .offers-panel-inner { padding: 8px 0 0 21px; }

.summary-popup--sales .summary-left.tmp-fake-summary { position: relative; }

.summary-popup--sales .summary-left.tmp-fake-summary:before { content: ''; display: block; position: absolute; top: -30px; left: -29px; right: 11px; bottom: -31px; background: url(../img/tutorial-fakes/sales-fake-summary-left.jpg) no-repeat 50% 50%; }

.summary-popup--sales .summary-left.tmp-fake-summary > * { opacity: 0 !important; }

.summary-popup--sales .summary-right { width: 805px; height: 677px; margin: -28px -20px -30px -20px; }

.summary-popup--sales .summary-right.tmp-fake-summary { position: relative; }

.summary-popup--sales .summary-right.tmp-fake-summary:before { content: ''; display: block; position: absolute; top: 0; left: 0; right: 0; bottom: 0; background: url(../img/tutorial-fakes/sales-fake-summary.jpg) no-repeat 50% 50%; }

.summary-popup--sales .summary-right.tmp-fake-summary > * { opacity: 0 !important; }

.summary-popup--sales .summary-right .nano-content { padding-top: 2px; }

.summary-popup--sales .nano > .nano-pane { background: url(../img/sell/scrollbar-bg.png) no-repeat center; }

.summary-popup--sales .nano > .nano-pane > .nano-slider { background: url(../img/sell/scrollbar-top.png) no-repeat top center, url(../img/sell/scrollbar-bottom.png) no-repeat bottom center; }

.summary-popup--sales .nano > .nano-pane > .nano-slider:after { background: url(../img/sell/scrollbar-middle.png) repeat-y top center; }

.shopping .quality-check-popup { cursor: default; top: 50%; left: 50%; right: auto; bottom: auto; }

.shopping .quality-check-popup .offer { margin: 25px auto; position: relative; }

.shopping .quality-check-popup .offer:before { content: ''; display: block; width: 31px; height: 15px; position: absolute; top: 6px; left: -36px; background: url(../img/shopping/arrow.png) no-repeat 0 0; }

.shopping .today-shipment-modal { position: fixed; top: 0; left: 0; right: 0; bottom: 0; background: rgba(0, 0, 0, 0.7); z-index: 1000; }

.shopping .today-shipment-modal .modal-content { width: 596px; height: 687px; padding: 35px 0 0; position: absolute; top: 50%; left: 50%; margin: -343px 0 0 -298px; background: url(../img/shopping/today-shipment-bg.png) no-repeat 0 0; animation: appear-pop 400ms ease-out; }

.shopping .today-shipment-modal .modal-content div[scrollable] { height: 100%; }

.shopping .today-shipment-modal .modal-content div[scrollable] .nano-content { padding: 20px 0 20px 25px; }

.shopping .today-shipment-modal .modal-content div[scrollable] .nano > .nano-pane { background: url(../img/shopping/today-shipment-scrollbar-bg.png) no-repeat center; }

.shopping .today-shipment-modal .modal-content .offer { float: left; }

.shopping .today-shipment-modal .modal-content .today-no-orders { width: 510px; height: 98px; margin: 5px 0 5px 20px; background: url(../img/shopping/today-no-orders.png) no-repeat 0 0; }

.shopping .today-shipment-modal .modal-content .cash { width: 188px; height: 98px; font-size: 30px; padding-top: 42px; margin-left: 250px; text-align: center; }

.shopping .today-shipment-modal .modal-content .cash.budget { background: url(../img/shopping/today-shipment-budget.png) no-repeat 0 0; }

.shopping .today-shipment-modal .modal-content .cash.total { background: url(../img/shopping/today-shipment-total.png) no-repeat 0 0; }

.shopping .today-shipment-modal .modal-content .cash.rest { background: url(../img/shopping/today-shipment-rest.png) no-repeat 0 0; }

.shopping .today-shipment-modal .modal-content .cash.cost { background: url(../img/shopping/today-shipment-cost.png) no-repeat 0 0; width: 129px; height: 98px; float: left; margin-left: 25px; margin-top: 5px; }

.shopping .today-shipment-modal .modal-content .cancel { display: block; float: left; width: 124px; height: 29px; margin: 40px 0 0 10px; border: 0; background: url(../img/shopping/today-shipment-cancel.png) no-repeat 0 0; }

.shopping .today-shipment-modal .modal-content .cancel:hover { background-image: url(../img/shopping/today-shipment-cancel-hover.png); }

.shopping .today-shipment-modal .modal-content .cancel:active { background-image: url(../img/shopping/today-shipment-cancel-active.png); }

.shopping .today-shipment-modal .modal-content .done { display: block; width: 124px; height: 29px; margin: 20px auto 10px; border: 0; background: url(../img/shopping/today-shipment-ready.png) no-repeat 0 0; }

.shopping .today-shipment-modal .modal-content .done:hover { background-image: url(../img/shopping/today-shipment-ready-hover.png); }

.shopping .today-shipment-modal .modal-content .done:active { background-image: url(../img/shopping/today-shipment-ready-active.png); }

.shopping .today-shipment-modal .modal-content .done:disabled { background-image: url(../img/shopping/today-shipment-ready-inactive.png); }

.robots-recipe-items-list { margin: 20px 0; }

.report-popup-content { background: url(../img/trainer/report-top.png) no-repeat center, url(../img/trainer/report-bg.png) no-repeat center; }

.report-popup .back { background: url(../img/trainer/report-close.png) no-repeat center; }

.report-popup .back:hover { background-image: url(../img/trainer/report-close-hover.png); }

.report-popup .back:active { background-image: url(../img/trainer/report-close-active.png); }

.report-popup .show-report-players { background: url(../img/trainer/report-show.png) no-repeat center; }

.report-popup .show-report-players:hover { background-image: url(../img/trainer/report-show-hover.png); }

.report-popup .show-report-players:active { background-image: url(../img/trainer/report-show-active.png); }

.report-popup .show-report-players.hide { background: url(../img/trainer/report-hide.png) no-repeat center; }

.report-popup .show-report-players.hide:hover { background-image: url(../img/trainer/report-hide-hover.png); }

.report-popup .show-report-players.hide:active { background-image: url(../img/trainer/report-hide-active.png); }

/* TRAINER */
.basic .cover-bg { background-image: url(../img/trainer/bg.png); }

.basic .timer { background-image: url(../img/trainer/counter.png); color: #fff; }

.summary-popup-content { background: url(../img/trainer/summary-popup.png) no-repeat center; color: #fff; font-weight: bold; text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.75); }

.summary-popup .back { background: url(../img/trainer/summary-back.png) no-repeat center; }

.summary-popup .back:hover { background-image: url(../img/trainer/summary-back-hover.png); }

.summary-popup .back:active { background-image: url(../img/trainer/summary-back-active.png); }

.basic .finance-summary { height: 73px; background-image: url(../img/trainer/finance-summary.png); text-align: center; color: #fff; font-size: 30px; font-weight: bold; text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.75); padding-top: 32px; }

.basic .new-game { height: 36px; background-image: url(../img/trainer/new-game.png); }

.basic .new-game:hover { background-image: url(../img/trainer/new-game-hover.png); }

.basic .new-game:active { background-image: url(../img/trainer/new-game-active.png); }

.basic .new-game.active { background-image: url(../img/trainer/end-game.png); }

.basic .new-game.active:hover { background-image: url(../img/trainer/end-game-hover.png); }

.basic .new-game.active:active { background-image: url(../img/trainer/end-game-active.png); }

.basic .current-devisions { height: 80px; background-image: url(../img/trainer/current-devisions.png); }

.basic .current-devisions ul { padding: 38px 0px 0 12px; }

.basic .current-devisions ul li { float: left; width: 55px; height: 27px; }

.basic .current-devisions ul li.icon-production { background-image: url(../img/trainer/dev-production-offline.png); }

.basic .current-devisions ul li.icon-assembly1 { background-image: url(../img/trainer/dev-assembly1-offline.png); }

.basic .current-devisions ul li.icon-assembly2 { background-image: url(../img/trainer/dev-assembly2-offline.png); }

.basic .current-devisions ul li.icon-sell { background-image: url(../img/trainer/dev-sell-offline.png); }

.basic .current-devisions ul li.icon-shopping { background-image: url(../img/trainer/dev-shopping-offline.png); }

.basic .current-devisions ul li.active.icon-production { background-image: url(../img/trainer/dev-production-online.png); }

.basic .current-devisions ul li.active.icon-assembly1 { background-image: url(../img/trainer/dev-assembly1-online.png); }

.basic .current-devisions ul li.active.icon-assembly2 { background-image: url(../img/trainer/dev-assembly2-online.png); }

.basic .current-devisions ul li.active.icon-sell { background-image: url(../img/trainer/dev-sell-online.png); }

.basic .current-devisions ul li.active.icon-shopping { background-image: url(../img/trainer/dev-shopping-online.png); }

.basic .new-round { height: 73px; background-image: url(../img/trainer/new-round.png); }

.basic .new-round:hover { background-image: url(../img/trainer/new-round-hover.png); }

.basic .new-round:active { background-image: url(../img/trainer/new-round-active.png); }

.basic .new-round.active { height: 73px; background-image: url(../img/trainer/end-round.png); }

.basic .new-round.active:hover { background-image: url(../img/trainer/end-round-hover.png); }

.basic .new-round.active:active { background-image: url(../img/trainer/end-round-active.png); }

.basic .panel-left { height: 464px; width: 496px; background-image: url(../img/trainer/panel-left.png); padding: 55px 40px 0 58px; }

.basic .panel-left .results-container { margin-bottom: 10px; }

.basic .panel-left .results-container.big-gutter { margin-top: 45px; }

.basic .panel-left .results-container .results-status { width: 56px; height: 37px; background: url(../img/trainer/offline.png) no-repeat center; margin-right: 43px; }

.basic .panel-left .results-container .results-status.active { background-image: url(../img/trainer/online.png); }

.basic .panel-left .results-container > div { float: left; height: 34px; width: 183px; }

.basic .panel-left .results-container > input { float: left; width: 82px; height: 34px; background: url(../img/trainer/results-input.png) no-repeat center; border: 0px; text-align: center; line-height: 34px; font-size: 22px; font-weight: bold; margin-left: 34px; }

.basic .panel-left .results-production { background-image: url(../img/trainer/results-production.png); }

.basic .panel-left .results-assemblyA { background-image: url(../img/trainer/results-assembly1.png); }

.basic .panel-left .results-assemblyB { background-image: url(../img/trainer/results-assembly2.png); }

.basic .panel-left .results-sales { background-image: url(../img/trainer/results-sell.png); }

.basic .panel-left .results-supply { background-image: url(../img/trainer/results-shopping.png); }

.basic .panel-left .results-quality { background-image: url(../img/trainer/results-quality.png); }

.basic .panel-left .results-marketing { background-image: url(../img/trainer/results-marketing.png); }

.basic .panel-left .results-failure-container { border-top: 1px solid #fff; margin-top: 20px; padding-top: 12px; }

.basic .panel-left .results-container > div.results-failure { float: none; margin: 0 auto; width: 215px; height: 35px; background-image: url(../img/trainer/results-failure.png); line-height: 35px; font-size: 22px; font-weight: bold; color: #fff; padding-left: 135px; text-align: center; }

.basic .panel-left .results-costs { background-image: url(../img/trainer/results-costs.png); }

.basic .panel-middle { height: 583px; background-image: url(../img/trainer/panel-middle.png); padding: 20px 10px; }

.basic .panel-middle .quality-header { width: 183px; height: 34px; background-image: url(../img/trainer/quality.png); margin: 0 auto; }

.basic .panel-middle .subheader.small-subheader { margin-top: 5px; }

.basic .panel-middle .subheader, .basic .popup-container .subheader { width: 391px; height: 34px; margin: 12px auto 5px auto; }

.basic .popup-container .subheader { margin-top: 5px; }

.basic .panel-middle .input-container { width: 129px; float: left; margin: 0 20px 0 50px; }

.basic .panel-middle .input-container.robots { width: auto; float: none; margin-left: 15px; margin-right: 0; }

.basic .panel-middle .input-container.robots + .button-check { float: none; margin: 9px auto 0; }

.basic .panel-middle .input-container.material input { background-image: url(../img/trainer/quality-input-material.png); }

.basic .panel-middle .input-container-broken { width: 219px; float: left; margin: 0 7px 0 19px; }

.basic .panel-middle .input-container-broken input { width: 219px; background-image: url(../img/trainer/quality-input-broken.png); }

.basic .panel-middle input { width: 129px; height: 47px; background: url(../img/trainer/quality-input.png) no-repeat center; border: 0px; text-align: center; line-height: 30px; padding-top: 15px; font-size: 24px; font-weight: bold; margin-bottom: 3px; }

.basic .panel-middle .quality-robots-check, .basic .popup-container .quality-robots-check { background-image: url(../img/trainer/quality-robots-check.png); }

.basic .panel-middle .quality-robots-fix, .basic .popup-container .quality-robots-fix { background-image: url(../img/trainer/quality-robots-fix.png); }

.basic .panel-middle .quality-robots-remove, .basic .popup-container .quality-robots-remove { background-image: url(../img/trainer/quality-robots-remove.png); }

.basic .panel-middle .quality-robots-broken { background-image: url(../img/trainer/quality-robots-broken.png); }

.basic .panel-middle .quality-materials-check, .basic .popup-container .quality-materials-check { background-image: url(../img/trainer/quality-materials-check.png); }

.basic .quality-check-result-popup .subheader, .assembly .quality-check-result-popup .subheader { margin-top: 5px; }

.basic .quality-check-result-popup table, .assembly .quality-check-result-popup table { width: 320px; margin: 5px auto; }

.basic .quality-check-result-popup table td, .assembly .quality-check-result-popup table td { padding: 5px; text-align: center; font-size: 22px; }

.basic .quality-check-result-popup table td small, .assembly .quality-check-result-popup table td small { font-size: 14px; }

.assembly .quality-check-result-popup table td { padding: 2px 5px; }

.basic .media-buy-result-popup table { width: 520px; margin: 5px auto; }

.basic .media-buy-result-popup table td { padding: 0; text-align: center; font-size: 18px; }

.basic .media-buy-result-popup table td:first-child { text-align: left; }

.basic .media-buy-result-popup table td:last-child { text-align: right; }

.basic .media-buy-result-popup table td small { font-size: 14px; }

header { cursor: default; }

[ng-click="panelCloseAll()"] { cursor: default; }

.basic .prices-accept-popup { width: 761px; height: 378px; position: absolute; top: 50%; left: 50%; margin: -189px 0 0 -380.5px; background-image: url(../img/common-popup-large.png); padding: 20px 10px; animation: appear-pop 400ms ease-out; }

.basic .prices-accept-popup .sell-robots { width: 161px; height: 277px; background: url(../img/trainer/sell-robots.png); float: left; margin-left: 172px; }

.basic .prices-accept-popup .sell-inputs { float: left; }

.basic .prices-accept-popup .sell-prices { width: 391px; height: 34px; background-image: url(../img/trainer/sell-prices.png); margin: 5px auto; }

.basic .prices-accept-popup .sell-header { width: 183px; height: 34px; background-image: url(../img/trainer/sell.png); margin: 0 auto; }

.basic .prices-accept-popup .sell-inputs-container { margin-top: 16px; }

.basic .prices-accept-popup .button-submit { margin: 5px auto; float: none; }

.basic .prices-accept-popup input { display: block; float: left; width: 117px; height: 46px; background: url(../img/trainer/sell-input.png); border: 0px; text-align: center; line-height: 46px; font-size: 24px; font-weight: bold; margin-bottom: 3px; }

.basic .prices-accept-popup .action-yes { display: block; float: left; width: 56px; height: 46px; background: url(../img/trainer/sell-ok.png) no-repeat center; }

.basic .prices-accept-popup .action-yes:hover { background-image: url(../img/trainer/sell-ok-hover.png); }

.basic .prices-accept-popup .action-yes:active, .basic .prices-accept-popup .action-yes.active { background-image: url(../img/trainer/sell-ok-active.png); }

.basic .prices-accept-popup .action-nope { display: block; float: left; width: 56px; height: 46px; background: url(../img/trainer/sell-nope.png) no-repeat center; margin-left: 4px; }

.basic .prices-accept-popup .action-nope:hover { background-image: url(../img/trainer/sell-nope-hover.png); }

.basic .prices-accept-popup .action-nope:active, .basic .prices-accept-popup .action-nope.active { background-image: url(../img/trainer/sell-nope-active.png); }

.basic .log { height: 583px; }

.basic .log-item { margin: 5px 0 0; width: 628px; height: 195px; padding: 20px; font-size: 18px; color: #fff; background: url(../img/trainer/log-1.png) no-repeat 0 0; text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.75); transition: opacity 200ms ease-in-out; animation: appear-right 300ms ease-out; }

.basic .log-item:first-child { margin-top: 0; }

.basic .log-item.done { opacity: .5; }

.basic .log-item.done:hover { opacity: .7; }

.basic .log-item h3 { text-align: center; text-transform: uppercase; margin-bottom: 5px; font-size: 22px; font-weight: bold; }

.basic .log-header { width: 183px; height: 34px; margin-bottom: 10px; }

.basic .log-header.log-header-trainer { display: none; }

.basic .log-header.log-header-assembly-1, .basic .log-header.log-header-assemblyA { background: url(../img/trainer/log-header-assembly-1.png) no-repeat 0 0; }

.basic .log-header.log-header-assembly-2, .basic .log-header.log-header-assemblyB { background: url(../img/trainer/log-header-assembly-2.png) no-repeat 0 0; }

.basic .log-header.log-header-marketing { background: url(../img/trainer/log-header-marketing.png) no-repeat 0 0; }

.basic .log-header.log-header-production { background: url(../img/trainer/log-header-production.png) no-repeat 0 0; }

.basic .log-header.log-header-quality { background: url(../img/trainer/log-header-quality.png) no-repeat 0 0; }

.basic .log-header.log-header-sales { background: url(../img/trainer/log-header-sales.png) no-repeat 0 0; }

.basic .log-header.log-header-supply { background: url(../img/trainer/log-header-supply.png) no-repeat 0 0; }

.basic .header-quality { width: 448px; height: 34px; background: url(../img/trainer/header-quality.png) no-repeat 0 0; }

.basic .header-log { width: 648px; height: 36px; background: url(../img/trainer/header-log.png) no-repeat 0 0; }

.basic .header-results { width: 296px; height: 34px; background: url(../img/trainer/header-results.png) no-repeat 0 0; }

.basic .button-raport { display: block; width: 298px; height: 73px; background-image: url(../img/trainer/trainer-report.png); margin: 0 auto; }

.basic .button-raport:hover { background-image: url(../img/trainer/trainer-report-hover.png); }

.basic .button-raport:active { background-image: url(../img/trainer/trainer-report-active.png); }

.basic .button-check { display: block; width: 163px; height: 36px; background-image: url(../img/trainer/button-check.png); float: left; margin-top: 7px; }

.basic .button-check:hover { background-image: url(../img/trainer/button-check-hover.png); }

.basic .button-check:active { background-image: url(../img/trainer/button-check-active.png); }

.basic .button-fix { display: block; width: 163px; height: 36px; background-image: url(../img/trainer/button-fix.png); float: left; margin-top: 7px; }

.basic .button-fix:hover { background-image: url(../img/trainer/button-fix-hover.png); }

.basic .button-fix:active { background-image: url(../img/trainer/button-fix-active.png); }

.basic .button-remove { display: block; width: 163px; height: 36px; background-image: url(../img/trainer/button-remove.png); float: left; margin-top: 7px; }

.basic .button-remove:hover { background-image: url(../img/trainer/button-remove-hover.png); }

.basic .button-remove:active { background-image: url(../img/trainer/button-remove-active.png); }

.basic .button-submit { display: block; width: 163px; height: 36px; background-image: url(../img/trainer/button-submit.png); float: left; margin-top: 7px; }

.basic .button-submit:hover { background-image: url(../img/trainer/button-submit-hover.png); }

.basic .button-submit:active { background-image: url(../img/trainer/button-submit-active.png); }

[ng-app] .sweet-overlay { background: rgba(0, 0, 0, 0.7); }

[ng-app] .sweet-alert { width: 497px; height: 248px; margin-left: -248px; padding: 30px; color: #fff; background: url(../img/common-popup-small.png) no-repeat 0 0; font-family: 'play', 'Arial', sans-serif; text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.75); font-size: 18px; }

[ng-app] .sweet-alert.large { width: 631px; height: 315px; margin-left: -315px; background-image: url(../img/common-popup-medium.png); }

[ng-app] .sweet-alert h2 { height: 33px; color: #fff; font-size: 24px; line-height: 33px; text-transform: uppercase; font-weight: bold; margin-bottom: 5px; }

[ng-app] .sweet-alert .success.animate ~ h2 { background: url(../img/common-popup-success.png) no-repeat top center; }

[ng-app] .sweet-alert .error.animateErrorIcon ~ h2 { background: url(../img/common-popup-error.png) no-repeat top center; }

[ng-app] .sweet-alert p { font-size: 18px; color: #fff; font-weight: bold; }

[ng-app] .sweet-alert .icon { display: none !important; }

[ng-app] .sweet-alert button { width: 163px; height: 36px; padding: 0; cursor: pointer; border: 3px solid #808080; background: #9c9c9c !important; box-shadow: inset 0 0 15px rgba(0, 0, 0, 0.4), inset 0 0 30px rgba(0, 0, 0, 0.3) !important; text-transform: uppercase; line-height: 30px; text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3); font-weight: bold; letter-spacing: .04em; }

[ng-app] .sweet-alert button:hover { background: darkgray !important; }

[ng-app] .sweet-alert button:active { border-color: #fafafa; }

[ng-app] .sweet-alert .sa-button-container { position: absolute; left: 0; right: 0; bottom: 34px; }

@keyframes appear-right { 0% { transform: translate(20px, 0);
    opacity: 0; }
  75% { transform: translate(-5px, 0);
    opacity: 1; }
  100% { transform: translate(0, 0);
    opacity: 1; } }

@keyframes appear-pop { 0% { transform: scale(0.9);
    opacity: 0; }
  75% { transform: scale(1.02);
    opacity: 1; }
  100% { transform: scale(1);
    opacity: 1; } }

@keyframes appear { 0% { opacity: 0; }
  100% { opacity: 1; } }

.sell-opinions { height: 78px; background: url(../img/sell/opinions/opinions.png) no-repeat center; }

.sell-opinions:hover { background-image: url(../img/sell/opinions/opinions-hover.png); }

.sell-opinions.active { background-image: url(../img/sell/opinions/opinions-active.png); }

.summary-popup.intro-force-open .intro-summary-left, .summary-popup.intro-force-open .intro-summary-right { position: absolute; top: 0; bottom: 0; }

.summary-popup.intro-force-open .intro-summary-left { left: 0; width: 30%; }

.summary-popup.intro-force-open .intro-summary-right { right: 0; width: 70%; }

.shepherd-element.shepherd-theme-dark .shepherd-content { border: 1px solid rgba(255, 255, 255, 0.4); font-size: .9em; }

.shepherd-element.shepherd-theme-dark .shepherd-content:before { z-index: 2; }

.shepherd-element.shepherd-theme-dark .shepherd-content:after { content: ""; display: block; position: absolute; z-index: 1; width: 0; height: 0; border-color: transparent; border-width: 16px; border-style: solid; pointer-events: none; }

.shepherd-element.shepherd-theme-dark .shepherd-content footer .shepherd-buttons { display: flex; justify-content: flex-end; }

.shepherd-element.shepherd-theme-dark .shepherd-content footer .shepherd-buttons li:first-child { margin-right: auto; }

.shepherd-element.shepherd-theme-dark .shepherd-content footer .shepherd-buttons li .shepherd-button { padding: .75em 1.2em; min-width: 7.3em; text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.4); text-align: center; }

.shepherd-element.shepherd-theme-dark .shepherd-content footer .shepherd-buttons li .shepherd-button.shepherd-button-secondary { background: #555; color: #fafafa; }

.shepherd-element.shepherd-theme-dark.shepherd-element-attached-top.shepherd-element-attached-center .shepherd-content:before { margin-bottom: -1px; }

.shepherd-element.shepherd-theme-dark.shepherd-element-attached-top.shepherd-element-attached-center .shepherd-content:after { bottom: 100%; left: 50%; margin-left: -16px; border-bottom-color: rgba(255, 255, 255, 0.4); }

.shepherd-element.shepherd-theme-dark.shepherd-element-attached-left.shepherd-element-attached-middle .shepherd-content:before { margin-right: -1px; }

.shepherd-element.shepherd-theme-dark.shepherd-element-attached-left.shepherd-element-attached-middle .shepherd-content:after { right: 100%; top: 50%; margin-top: -16px; border-right-color: rgba(255, 255, 255, 0.4); }

.shepherd-element.shepherd-theme-dark.shepherd-element-attached-bottom.shepherd-element-attached-center .shepherd-content:before { margin-top: -1px; }

.shepherd-element.shepherd-theme-dark.shepherd-element-attached-bottom.shepherd-element-attached-center .shepherd-content:after { top: 100%; left: 50%; margin-left: -16px; border-top-color: rgba(255, 255, 255, 0.4); }

.shepherd-element.shepherd-theme-dark.shepherd-element-attached-right.shepherd-element-attached-middle .shepherd-content:before { margin-left: -1px; }

.shepherd-element.shepherd-theme-dark.shepherd-element-attached-right.shepherd-element-attached-middle .shepherd-content:after { left: 100%; top: 50%; margin-top: -16px; border-left-color: rgba(255, 255, 255, 0.4); }

.fake-popup-visible, .fake-popup-visible.ng-hide { display: block !important; pointer-events: none; }

.shepherd-active.sell .robot-card:not(.tmp-fake-checked) .desc { display: none; }

.tutorial-switch { width: 240px; height: 36px; padding: 10px 0; position: fixed; top: -8px; left: 50%; margin-left: -120px; z-index: 1000; color: #fff; font-weight: bold; background: url(../img/common-bar.png) no-repeat top center; }

.tutorial-switch:before, .tutorial-switch:after { content: ''; display: block; width: 30px; height: 36px; position: absolute; top: 0; background: url(../img/common-bar.png) no-repeat top center; }

.tutorial-switch:before { left: -30px; background-position: 0 0; }

.tutorial-switch:after { right: -30px; background-position: 100% 100%; }

.tutorial-switch ul { display: flex; justify-content: center; }

.tutorial-switch ul li { padding: 0 20px; flex: 1; border-right: 1px solid rgba(255, 255, 255, 0.4); text-align: center; }

.tutorial-switch ul li:first-child { padding-left: 0; }

.tutorial-switch ul li:last-child { padding-right: 0; border-right: 0; }

.tutorial-switch ul li a { color: rgba(255, 255, 255, 0.5); transition: color 250ms ease-out; }

.tutorial-switch ul li a:hover { color: #fff; }

.tutorial-switch ul li.is-active a { color: #fff; text-decoration: underline; pointer-events: none; }

.tour-button { height: 36px; padding: 7px 20px 5px; position: fixed; top: -8px; left: 0; z-index: 1000; background: url(../img/common-bar.png) no-repeat top right; font-size: 12px; line-height: 26px; text-transform: uppercase; }

.tour-button:hover { text-decoration: underline; }

.quality .cover-bg { background-image: url(../img/quality/bg.jpg); }

.quality .depertment-name { background-image: url(../img/sprite-quality.png); background-position: 0px -845px; width: 296px; height: 36px; }

.quality .timer { background-image: url(../img/sprite-quality.png); background-position: -298px -845px; width: 296px; height: 36px; }

.quality .actions { position: relative; z-index: 11; background-image: url(../img/sprite-quality.png); background-position: 0px -498px; width: 348px; height: 74px; }

.quality .actions:hover { background-image: url(../img/sprite-quality.png); background-position: 0px -574px; width: 348px; height: 74px; }

.quality .actions.active { background-image: url(../img/sprite-quality.png); background-position: -350px -498px; width: 348px; height: 74px; }

.quality .parameters { position: relative; z-index: 11; background-image: url(../img/sprite-quality.png); background-position: -740px 0px; width: 248px; height: 74px; }

.quality .parameters:hover { background-image: url(../img/sprite-quality.png); background-position: -740px -76px; width: 248px; height: 74px; }

.quality .parameters.active { background-image: url(../img/sprite-quality.png); background-position: -350px -574px; width: 248px; height: 74px; }

.quality .parameters-panel { height: 0px; width: 100%; position: absolute; margin-top: -10px; top: 100%; transition: all 250ms cubic-bezier(0.86, 0, 0.07, 1); background-repeat: no-repeat; background-position: bottom left; overflow: hidden; background-image: url(../img/quality/parameters-panel.png); }

.quality .parameters-panel.open { height: 630px; }

.quality .summary { background-image: url(../img/sprite-quality.png); background-position: 0px -807px; width: 298px; height: 36px; }

.quality .summary:hover { background-image: url(../img/sprite-quality.png); background-position: -300px -807px; width: 298px; height: 36px; }

.quality .summary:active { background-image: url(../img/sprite-quality.png); background-position: 0px -769px; width: 298px; height: 36px; }

.quality .report { background-image: url(../img/sprite-quality.png); background-position: -300px -769px; width: 298px; height: 36px; }

.quality .report:hover { background-image: url(../img/sprite-quality.png); background-position: -600px -769px; width: 298px; height: 36px; }

.quality .report:active { background-image: url(../img/sprite-quality.png); background-position: -600px -807px; width: 298px; height: 36px; }

.quality .summary-popup-content { background: url(../img/quality/summary-popup.png) no-repeat center; }

.quality .summary-popup .back { background-image: url(../img/sprite-quality.png); background-position: -820px -971px; width: 163px; height: 36px; }

.quality .summary-popup .back:hover { background-image: url(../img/sprite-quality.png); background-position: -985px -971px; width: 163px; height: 36px; }

.quality .summary-popup .back:active { background-image: url(../img/sprite-quality.png); background-position: -495px -1041px; width: 163px; height: 36px; }

.quality .panel-container { z-index: 100; }

.quality .nano > .nano-pane { background: url(../img/quality/scrollbar-bg.png) no-repeat center; }

.quality .nano > .nano-pane > .nano-slider { background: url(../img/quality/scrollbar-top.png) no-repeat top center, url(../img/quality/scrollbar-bottom.png) no-repeat bottom center; }

.quality .nano > .nano-pane > .nano-slider:after { background: url(../img/quality/scrollbar-middle.png) repeat-y top center; }

.quality .actions-panel { margin-top: -10px; right: 1px; background-image: url(../img/quality/actions-panel.png); background-repeat: no-repeat; }

.quality .actions-panel.open { height: 591px; }

.quality .actions-panel .action-row { margin: 12px 14px 0 15px; }

.quality .actions-panel .action-row:first-child { margin-top: 19px; }

.quality .actions-panel .col-action { float: left; width: 110px; }

.quality .actions-panel .col-info { float: left; width: 211px; }

.quality .actions-panel .action { cursor: default; pointer-events: none; }

.quality .actions-panel .action.active { cursor: pointer; opacity: 1; pointer-events: auto; }

.quality .actions-panel .action-1 { background-image: url(../img/sprite-quality.png); background-position: -1098px -707px; width: 106px; height: 68px; }

.quality .actions-panel .action-1.active { background-image: url(../img/sprite-quality.png); background-position: -990px -147px; width: 106px; height: 68px; }

.quality .actions-panel .action-1.active:hover { background-image: url(../img/sprite-quality.png); background-position: -600px -574px; width: 106px; height: 68px; }

.quality .actions-panel .action-1.active:active { background-image: url(../img/sprite-quality.png); background-position: 0px -971px; width: 106px; height: 68px; }

.quality .actions-panel .action-1-info { background-image: url(../img/sprite-quality.png); background-position: -740px -502px; width: 211px; height: 68px; }

.quality .actions-panel .action-2 { background-image: url(../img/sprite-quality.png); background-position: -1098px -637px; width: 106px; height: 68px; }

.quality .actions-panel .action-2.active { background-image: url(../img/sprite-quality.png); background-position: -990px -777px; width: 106px; height: 68px; }

.quality .actions-panel .action-2.active:hover { background-image: url(../img/sprite-quality.png); background-position: -990px -497px; width: 106px; height: 68px; }

.quality .actions-panel .action-2.active:active { background-image: url(../img/sprite-quality.png); background-position: -1098px -287px; width: 106px; height: 68px; }

.quality .actions-panel .action-2-info { background-image: url(../img/sprite-quality.png); background-position: -740px -152px; width: 211px; height: 68px; }

.quality .actions-panel .action-3 { background-image: url(../img/sprite-quality.png); background-position: -1098px -847px; width: 106px; height: 68px; }

.quality .actions-panel .action-3.active { background-image: url(../img/sprite-quality.png); background-position: -108px -971px; width: 106px; height: 68px; }

.quality .actions-panel .action-3.active:hover { background-image: url(../img/sprite-quality.png); background-position: -990px -847px; width: 106px; height: 68px; }

.quality .actions-panel .action-3.active:active { background-image: url(../img/sprite-quality.png); background-position: -1098px -777px; width: 106px; height: 68px; }

.quality .actions-panel .action-3-info { background-image: url(../img/sprite-quality.png); background-position: -740px -222px; width: 211px; height: 68px; }

.quality .actions-panel .action-4 { background-image: url(../img/sprite-quality.png); background-position: -1098px -77px; width: 106px; height: 68px; }

.quality .actions-panel .action-4.active { background-image: url(../img/sprite-quality.png); background-position: -1098px -147px; width: 106px; height: 68px; }

.quality .actions-panel .action-4.active:hover { background-image: url(../img/sprite-quality.png); background-position: -990px -77px; width: 106px; height: 68px; }

.quality .actions-panel .action-4.active:active { background-image: url(../img/sprite-quality.png); background-position: -216px -971px; width: 106px; height: 68px; }

.quality .actions-panel .action-4-info { background-image: url(../img/sprite-quality.png); background-position: -740px -572px; width: 211px; height: 68px; }

.quality .actions-panel .action-5 { background-image: url(../img/sprite-quality.png); background-position: -990px -287px; width: 106px; height: 68px; }

.quality .actions-panel .action-5.active { background-image: url(../img/sprite-quality.png); background-position: -990px -357px; width: 106px; height: 68px; }

.quality .actions-panel .action-5.active:hover { background-image: url(../img/sprite-quality.png); background-position: -1098px -217px; width: 106px; height: 68px; }

.quality .actions-panel .action-5.active:active { background-image: url(../img/sprite-quality.png); background-position: -990px -217px; width: 106px; height: 68px; }

.quality .actions-panel .action-5-info { background-image: url(../img/sprite-quality.png); background-position: -740px -432px; width: 211px; height: 68px; }

.quality .actions-panel .action-6 { background-image: url(../img/sprite-quality.png); background-position: -1098px -427px; width: 106px; height: 68px; }

.quality .actions-panel .action-6.active { background-image: url(../img/sprite-quality.png); background-position: -1098px -497px; width: 106px; height: 68px; }

.quality .actions-panel .action-6.active:hover { background-image: url(../img/sprite-quality.png); background-position: -990px -427px; width: 106px; height: 68px; }

.quality .actions-panel .action-6.active:active { background-image: url(../img/sprite-quality.png); background-position: -1098px -357px; width: 106px; height: 68px; }

.quality .actions-panel .action-6-info { background-image: url(../img/sprite-quality.png); background-position: -740px -362px; width: 211px; height: 68px; }

.quality .actions-panel .action-7 { background-image: url(../img/sprite-quality.png); background-position: -990px -637px; width: 106px; height: 68px; }

.quality .actions-panel .action-7.active { background-image: url(../img/sprite-quality.png); background-position: -990px -707px; width: 106px; height: 68px; }

.quality .actions-panel .action-7.active:hover { background-image: url(../img/sprite-quality.png); background-position: -1098px -567px; width: 106px; height: 68px; }

.quality .actions-panel .action-7.active:active { background-image: url(../img/sprite-quality.png); background-position: -990px -567px; width: 106px; height: 68px; }

.quality .actions-panel .action-7-info { background-image: url(../img/sprite-quality.png); background-position: -740px -292px; width: 211px; height: 68px; }

.quality .robots-panel { height: 543px; }

.quality .robots-panel .robots-panel-item { position: relative; }

.quality .robots-panel .comment { z-index: 100; bottom: 1px; right: -2px; }

.quality .robots-panel .up, .quality .robots-panel .down { display: block; width: 30px; height: 17px; position: absolute; left: 50%; margin-left: -15px; z-index: 101; cursor: default; }

.quality .robots-panel .up.active, .quality .robots-panel .down.active { cursor: pointer; }

.quality .robots-panel .up { top: 3px; }

.quality .robots-panel .down { bottom: 3px; }

.quality .robots-panel-inner { height: 543px; padding: 25px 15px 15px 15px; overflow-x: auto; }

.quality .robots-panel { background-image: url(../img/quality/magazine-panel.png); position: relative; }

.quality .robots-panel:after { display: block; content: ''; position: absolute; top: 0px; bottom: 0px; left: 0px; right: 0px; background: url(../img/quality/magazine-panel-top.png) no-repeat center; z-index: 100; pointer-events: none; }

.quality .robots-panel .up { background-image: url(../img/sprite-quality.png); background-position: -1326px -184px; width: 30px; height: 17px; }

.quality .robots-panel .up.active { background-image: url(../img/sprite-quality.png); background-position: -1326px -203px; width: 30px; height: 17px; }

.quality .robots-panel .up.active:hover { background-image: url(../img/sprite-quality.png); background-position: -1326px -165px; width: 30px; height: 17px; }

.quality .robots-panel .up.active:active { background-image: url(../img/sprite-quality.png); background-position: -1326px -127px; width: 30px; height: 17px; }

.quality .robots-panel .down { background-image: url(../img/sprite-quality.png); background-position: -1326px -89px; width: 30px; height: 17px; }

.quality .robots-panel .down.active { background-image: url(../img/sprite-quality.png); background-position: -1326px -108px; width: 30px; height: 17px; }

.quality .robots-panel .down.active:hover { background-image: url(../img/sprite-quality.png); background-position: -1326px -146px; width: 30px; height: 17px; }

.quality .robots-panel .down.active:active { background-image: url(../img/sprite-quality.png); background-position: -1326px -222px; width: 30px; height: 17px; }

.quality .robot-remove { display: block; margin: 0 auto; border: 0; font-size: 0; background-image: url(../img/sprite-quality.png); background-position: -990px -917px; width: 164px; height: 37px; }

.quality .robot-remove:hover { background-image: url(../img/sprite-quality.png); background-position: -550px -650px; width: 164px; height: 37px; }

.quality .robot-remove:active, .quality .robot-remove.is-active { background-image: url(../img/sprite-quality.png); background-position: -324px -971px; width: 164px; height: 37px; }

.quality .button-recycle-robot { display: block; font-size: 0; background-image: url(../img/sprite-quality.png); background-position: -740px -642px; width: 229px; height: 42px; }

.quality .button-recycle-robot:hover { background-image: url(../img/sprite-quality.png); background-position: -231px -927px; width: 229px; height: 42px; }

.quality .button-recycle-robot:active { background-image: url(../img/sprite-quality.png); background-position: 0px -883px; width: 229px; height: 42px; }

.quality .button-fix-robot { display: block; font-size: 0; background-image: url(../img/sprite-quality.png); background-position: -462px -927px; width: 229px; height: 42px; }

.quality .button-fix-robot:hover { background-image: url(../img/sprite-quality.png); background-position: -693px -883px; width: 229px; height: 42px; }

.quality .button-fix-robot:active { background-image: url(../img/sprite-quality.png); background-position: -231px -883px; width: 229px; height: 42px; }

.quality .button-check-robot { display: block; font-size: 0; background-image: url(../img/sprite-quality.png); background-position: 0px -927px; width: 229px; height: 42px; }

.quality .button-check-robot:hover { background-image: url(../img/sprite-quality.png); background-position: -693px -927px; width: 229px; height: 42px; }

.quality .button-check-robot:active { background-image: url(../img/sprite-quality.png); background-position: -462px -883px; width: 229px; height: 42px; }

.robots-header { width: 298px; margin-top: 3px; margin-bottom: 1px; }

.robots-header:before, .robots-header:after { content: ""; display: table; }

.robots-header:after { clear: both; }

.robots-header .heading { float: left; background-image: url(../img/sprite-quality.png); background-position: -990px -39px; width: 198px; height: 36px; }

.robots-header .count { float: left; padding: 0 22px 0 0; margin-left: 2px; text-align: center; font-size: 28px; line-height: 36px; font-weight: normal; }

.robots-header .repair-count { background-image: url(../img/sprite-quality.png); background-position: -1190px -39px; width: 48px; height: 36px; }

.robots-header .repair-count.active { background-image: url(../img/sprite-quality.png); background-position: -658px -1184px; width: 48px; height: 36px; }

.robots-header .check-count { background-image: url(../img/sprite-quality.png); background-position: -558px -1184px; width: 48px; height: 36px; }

.robots-header .check-count.active { background-image: url(../img/sprite-quality.png); background-position: -608px -1184px; width: 48px; height: 36px; }

.quality .comment { width: 25px; height: 25px; position: absolute; bottom: 4px; right: 4px; }

.quality .comment .comment-icon { width: 25px; height: 25px; background: url(../img/sell/add-note.png) no-repeat 0 0; cursor: pointer; }

.quality .comment .comment-text { display: none; width: 100px; min-height: 14px; line-height: 14px; position: absolute; bottom: 30px; right: 3px; z-index: 1000; color: #fff; font-size: 10px; font-weight: normal; }

.quality .comment .value { display: none; }

.quality .comment .placeholder, .quality .comment .value { padding: 4px; max-width: 100px; min-width: 50px; float: right; background: rgba(0, 0, 0, 0.9); text-align: left; }

.quality .comment:hover { z-index: 1500; }

.quality .comment:hover .comment-text { display: block; }

.quality .comment.is-edit-mode .placeholder, .quality .comment.is-active .placeholder { display: none; }

.quality .comment.is-edit-mode .value, .quality .comment.is-active .value { display: block; }

.quality .comment.is-readonly .comment-icon, .quality .comment.is-readonly.is-active .comment-icon { background: url(../img/sell/note-embeded.png) no-repeat 0 0; }

.quality .comment.is-active .comment-icon { background: url(../img/sell/add-note-active.png) no-repeat 0 0; }

.quality .comment.item-comment { top: 205px; margin-left: 199px; }

.moves-container { margin-left: 103px; }

.moves-container:before, .moves-container:after { content: ""; display: table; }

.moves-container:after { clear: both; }

.moves-container .moves-label { font-size: 0; float: left; background-image: url(../img/sprite-quality.png); background-position: -990px 0px; width: 248px; height: 37px; }

.moves-container .moves { float: left; background-image: url(../img/sprite-quality.png); background-position: 0px -650px; width: 548px; height: 37px; }

.moves-container .moves:before, .moves-container .moves:after { content: ""; display: table; }

.moves-container .moves:after { clear: both; }

.moves-container .moves .move { float: left; opacity: 0; margin: 13px 3px 0; background-image: url(../img/sprite-quality.png); background-position: -1326px -241px; width: 21px; height: 20px; }

.moves-container .moves .move-active { opacity: 1; }

.moves-container .moves .move:first-child { margin-left: 4px; }

.moves-container .moves .move:nth-child(3n+6) { margin-left: 4px; }

.moves-container .moves .move:last-child { margin-right: 0; }

.quality-board { width: 100%; height: 543px; margin: 0 0 2px 0; background: url(../img/quality/main-panel.png) no-repeat 100% 0; }

.quality-machines-board { padding: 18px; }

.quality-machines-board .board-section-header { position: relative; }

.quality-machines-board .board-section-header .heading { position: absolute; left: 0; top: 6px; }

.quality-machines-board .board-section-header .heading.production { background-image: url(../img/sprite-trainer.png); background-position: 0px -72px; width: 183px; height: 34px; font-size: 0; }

.quality-machines-board .board-section-header .heading.assembly-1 { background-image: url(../img/sprite-trainer.png); background-position: 0px 0px; width: 183px; height: 34px; font-size: 0; }

.quality-machines-board .board-section-header .heading.assembly-2 { background-image: url(../img/sprite-trainer.png); background-position: 0px -36px; width: 183px; height: 34px; font-size: 0; }

.quality-machines-board .board-section-header .heading-failure { position: absolute; font-size: 0; top: 16px; }

.quality-machines-board .board-section-header .heading-failure.heading-failure-1 { background-image: url(../img/sprite-quality.png); background-position: -324px -1010px; width: 178px; height: 24px; left: 220px; }

.quality-machines-board .board-section-header .heading-failure.heading-failure-2 { background-image: url(../img/sprite-quality.png); background-position: -596px -845px; width: 299px; height: 24px; left: 400px; }

.quality-machines-board .board-section-header .button { margin-left: 704px; }

.quality-machines-board .board-section { margin: 12px 0 0; padding: 12px 0 0; border-top: 1px solid #fff; }

.quality-machines-board .board-section:first-child { margin-top: 0; padding-top: 0; border-top: 0; }

.quality-machines-board .button-training { font-size: 0; background-image: url(../img/sprite-quality.png); background-position: -82px -1117px; width: 162px; height: 31px; cursor: default; }

.quality-machines-board .button-training.active { background-image: url(../img/sprite-quality.png); background-position: -574px -1117px; width: 162px; height: 31px; cursor: pointer; }

.quality-machines-board .button-training.active:active { background-image: url(../img/sprite-quality.png); background-position: -738px -1117px; width: 162px; height: 31px; }

.quality-machines-board .button-training.active:hover { background-image: url(../img/sprite-quality.png); background-position: -410px -1117px; width: 162px; height: 31px; }

.quality-machines-board .button-training.pending { background-image: url(../img/sprite-quality.png); background-position: -574px -1117px; width: 162px; height: 31px; cursor: default; pointer-events: none; opacity: .5; }

.quality-machines-board .button-inspection { font-size: 0; background-image: url(../img/sprite-quality.png); background-position: -1240px -578px; width: 81px; height: 48px; cursor: default; }

.quality-machines-board .button-inspection.active { background-image: url(../img/sprite-quality.png); background-position: -1240px -528px; width: 81px; height: 48px; cursor: pointer; }

.quality-machines-board .button-inspection.active:active { background-image: url(../img/sprite-quality.png); background-position: -1240px -278px; width: 81px; height: 48px; }

.quality-machines-board .button-inspection.active:hover { background-image: url(../img/sprite-quality.png); background-position: -1240px -228px; width: 81px; height: 48px; }

.quality-machines-board .button-forced-inspection { font-size: 0; background-image: url(../img/sprite-quality.png); background-position: -1240px -378px; width: 81px; height: 48px; cursor: default; }

.quality-machines-board .button-forced-inspection.active { background-image: url(../img/sprite-quality.png); background-position: -1240px -328px; width: 81px; height: 48px; cursor: pointer; }

.quality-machines-board .button-forced-inspection.active:active { background-image: url(../img/sprite-quality.png); background-position: -1240px -478px; width: 81px; height: 48px; }

.quality-machines-board .button-forced-inspection.active:hover { background-image: url(../img/sprite-quality.png); background-position: -1240px -428px; width: 81px; height: 48px; }

.quality-machines-board .board-section-machine { margin: 4px 0; }

.quality-machines-board .board-section-machine:before, .quality-machines-board .board-section-machine:after { content: ""; display: table; }

.quality-machines-board .board-section-machine:after { clear: both; }

.quality-machines-board .board-section-machine .machine-heading { float: left; margin-right: 7px; margin-top: 14px; }

.quality-machines-board .board-section-machine .machine-tiredness { float: left; margin-right: 4px; margin-top: 8px; }

.quality-machines-board .board-section-machine .button { float: left; margin-right: 2px; }

.quality-machines-board .board-section-machine .button:last-child { margin-right: -10px; }

.quality-machines-board .board-section-production .machine-heading { font-size: 0; padding: 0; }

.quality-machines-board .board-section-production .machine-heading-1 { background-image: url(../img/sprite-production.png); background-position: -95px -642px; width: 93px; height: 18px; }

.quality-machines-board .board-section-production .machine-heading-2 { background-image: url(../img/sprite-production.png); background-position: -450px -386px; width: 93px; height: 18px; }

.quality-machines-board .board-section-production .machine-heading-3 { background-image: url(../img/sprite-production.png); background-position: 0px -642px; width: 93px; height: 18px; }

.quality-machines-board .board-section-production .machine-tiredness:before, .quality-machines-board .board-section-production .machine-tiredness:after { content: ""; display: table; }

.quality-machines-board .board-section-production .machine-tiredness:after { clear: both; }

.quality-machines-board .board-section-production .machine-tiredness li { float: left; font-size: 0; margin-right: 2px; }

.quality-machines-board .board-section-production .machine-tiredness .machine-tiredness-100 { background-image: url(../img/sprite-quality.png); background-position: -1022px -1117px; width: 58px; height: 30px; }

.quality-machines-board .board-section-production .machine-tiredness .machine-tiredness-100.active { background-image: url(../img/sprite-quality.png); background-position: -1142px -1117px; width: 58px; height: 30px; }

.quality-machines-board .board-section-production .machine-tiredness .machine-tiredness-90 { background-image: url(../img/sprite-quality.png); background-position: -82px -1150px; width: 58px; height: 30px; }

.quality-machines-board .board-section-production .machine-tiredness .machine-tiredness-90.active { background-image: url(../img/sprite-quality.png); background-position: -902px -1117px; width: 58px; height: 30px; }

.quality-machines-board .board-section-production .machine-tiredness .machine-tiredness-80 { background-image: url(../img/sprite-quality.png); background-position: -562px -1150px; width: 58px; height: 30px; }

.quality-machines-board .board-section-production .machine-tiredness .machine-tiredness-80.active { background-image: url(../img/sprite-quality.png); background-position: -202px -1150px; width: 58px; height: 30px; }

.quality-machines-board .board-section-production .machine-tiredness .machine-tiredness-70 { background-image: url(../img/sprite-quality.png); background-position: -262px -1150px; width: 58px; height: 30px; }

.quality-machines-board .board-section-production .machine-tiredness .machine-tiredness-70.active { background-image: url(../img/sprite-quality.png); background-position: -742px -1150px; width: 58px; height: 30px; }

.quality-machines-board .board-section-production .machine-tiredness .machine-tiredness-60 { background-image: url(../img/sprite-quality.png); background-position: -802px -1150px; width: 58px; height: 30px; }

.quality-machines-board .board-section-production .machine-tiredness .machine-tiredness-60.active { background-image: url(../img/sprite-quality.png); background-position: -862px -1150px; width: 58px; height: 30px; }

.quality-machines-board .board-section-production .machine-tiredness .machine-tiredness-50 { background-image: url(../img/sprite-quality.png); background-position: -922px -1150px; width: 58px; height: 30px; }

.quality-machines-board .board-section-production .machine-tiredness .machine-tiredness-50.active { background-image: url(../img/sprite-quality.png); background-position: -982px -1150px; width: 58px; height: 30px; }

.quality-machines-board .board-section-production .machine-tiredness .machine-tiredness-40 { background-image: url(../img/sprite-quality.png); background-position: -1042px -1150px; width: 58px; height: 30px; }

.quality-machines-board .board-section-production .machine-tiredness .machine-tiredness-40.active { background-image: url(../img/sprite-quality.png); background-position: -502px -1150px; width: 58px; height: 30px; }

.quality-machines-board .board-section-production .machine-tiredness .machine-tiredness-30 { background-image: url(../img/sprite-quality.png); background-position: -1082px -1117px; width: 58px; height: 30px; }

.quality-machines-board .board-section-production .machine-tiredness .machine-tiredness-30.active { background-image: url(../img/sprite-quality.png); background-position: -962px -1117px; width: 58px; height: 30px; }

.quality-machines-board .board-section-assembly .machine-heading { font-size: 0; padding: 0; }

.quality-machines-board .board-section-assembly .machine-heading-1 { background-image: url(../img/sprite-assembly.png); background-position: -600px -428px; width: 93px; height: 18px; }

.quality-machines-board .board-section-assembly .machine-heading-2 { background-image: url(../img/sprite-assembly.png); background-position: -695px -428px; width: 93px; height: 18px; }

.quality-machines-board .board-section-assembly .machine-tiredness:before, .quality-machines-board .board-section-assembly .machine-tiredness:after { content: ""; display: table; }

.quality-machines-board .board-section-assembly .machine-tiredness:after { clear: both; }

.quality-machines-board .board-section-assembly .machine-tiredness li { float: left; font-size: 0; margin-right: 2px; }

.quality-machines-board .board-section-assembly .machine-tiredness .machine-tiredness-circle { background-image: url(../img/sprite-quality.png); background-position: -442px -1150px; width: 58px; height: 30px; }

.quality-machines-board .board-section-assembly .machine-tiredness .machine-tiredness-circle.active { background-image: url(../img/sprite-quality.png); background-position: -322px -1150px; width: 58px; height: 30px; }

.quality-machines-board .board-section-assembly .machine-tiredness .machine-tiredness-triangle { background-image: url(../img/sprite-quality.png); background-position: -382px -1150px; width: 58px; height: 30px; }

.quality-machines-board .board-section-assembly .machine-tiredness .machine-tiredness-triangle.active { background-image: url(../img/sprite-quality.png); background-position: -142px -1150px; width: 58px; height: 30px; }

.quality-machines-board .board-section-assembly .machine-tiredness .machine-tiredness-square { background-image: url(../img/sprite-quality.png); background-position: -682px -1150px; width: 58px; height: 30px; }

.quality-machines-board .board-section-assembly .machine-tiredness .machine-tiredness-square.active { background-image: url(../img/sprite-quality.png); background-position: -622px -1150px; width: 58px; height: 30px; }

.quality-development-board { padding: 18px; }

.quality-development-research { position: relative; height: 164px; margin-top: 12px; margin-left: 35px; }

.quality-development-research:first-child { margin-top: -5px; }

.quality-development-research:before { content: ''; display: block; position: absolute; top: 0; left: 0; }

.quality-development-research .quality-button-development { position: absolute; z-index: 15; top: 6px; left: 715px; }

.quality-development-research .development-research-level { width: 640px; position: absolute; top: 24px; left: 80px; font-size: 0; }

.quality-development-research .development-research-level .level { display: inline-block; background-image: url(../img/sprite-quality.png); background-position: -1326px -54px; width: 33px; height: 33px; margin: 0 4px 0; opacity: 0; transition: opacity 250ms ease; }

.quality-development-research .development-research-level .level:nth-child(4), .quality-development-research .development-research-level .level:nth-child(7), .quality-development-research .development-research-level .level:nth-child(10), .quality-development-research .development-research-level .level:nth-child(13) { margin-left: 5px; }

.quality-development-research .development-research-level .level-active { opacity: 1; }

.quality-development-research .development-scope { width: 114px; height: 55px; position: absolute; top: 107px; z-index: 10; text-align: center; }

.quality-development-research .development-scope .development-scope-sign { display: inline-block; margin: 0 1px; }

.quality-development-research .development-scope--robots { left: 209px; }

.quality-development-research .development-scope--robots .development-scope-sign { background-image: url(../img/sprite-quality.png); background-position: 0px -1237px; width: 49px; height: 49px; }

.quality-development-research .development-scope--materials { left: 416px; }

.quality-development-research .development-scope--materials .development-scope-sign { background-image: url(../img/sprite-quality.png); background-position: -1326px 0px; width: 45px; height: 52px; }

.quality-development-research .development-scope--training { left: 622px; }

.quality-development-research .development-scope--training .development-scope-sign { background-image: url(../img/sprite-quality.png); background-position: -102px -1184px; width: 100px; height: 51px; }

.quality-development-research .development-scope--training.active .development-scope-sign--1 { background-image: url(../img/sprite-quality.png); background-position: 0px -1184px; width: 100px; height: 51px; }

.quality-development-research--shopping:before { background-image: url(../img/sprite-quality.png); background-position: 0px 0px; width: 738px; height: 164px; }

.quality-development-research--shopping .development-scope--robots.active .development-scope-sign--1 { background-image: url(../img/sprite-quality.png); background-position: -153px -1237px; width: 49px; height: 49px; }

.quality-development-research--shopping .development-scope--robots.active .development-scope-sign--2 { background-image: url(../img/sprite-quality.png); background-position: -51px -1237px; width: 49px; height: 49px; }

.quality-development-research--shopping .development-scope--materials.active .development-scope-sign--1 { background-image: url(../img/sprite-production.png); background-position: -673px -263px; width: 45px; height: 52px; }

.quality-development-research--shopping .development-scope--materials.active .development-scope-sign--2 { background-image: url(../img/sprite-production.png); background-position: -673px -263px; width: 45px; height: 52px; }

.quality-development-research--trainer:before { background-image: url(../img/sprite-quality.png); background-position: 0px -166px; width: 738px; height: 164px; }

.quality-development-research--trainer .development-scope--robots.active .development-scope-sign--1 { background-image: url(../img/sprite-quality.png); background-position: -153px -1237px; width: 49px; height: 49px; }

.quality-development-research--trainer .development-scope--robots.active .development-scope-sign--2 { background-image: url(../img/sprite-quality.png); background-position: -204px -1237px; width: 49px; height: 49px; }

.quality-development-research--trainer .development-scope--materials.active .development-scope-sign--1 { background-image: url(../img/sprite-production.png); background-position: -673px -317px; width: 45px; height: 52px; }

.quality-development-research--trainer .development-scope--materials.active .development-scope-sign--2 { background-image: url(../img/sprite-production.png); background-position: -673px -263px; width: 45px; height: 52px; }

.quality-development-research--mechanic:before { background-image: url(../img/sprite-quality.png); background-position: 0px -332px; width: 738px; height: 164px; }

.quality-development-research--mechanic .development-scope--robots.active .development-scope-sign--1 { background-image: url(../img/sprite-quality.png); background-position: -102px -1237px; width: 49px; height: 49px; }

.quality-development-research--mechanic .development-scope--robots.active .development-scope-sign--2 { background-image: url(../img/sprite-quality.png); background-position: -51px -1237px; width: 49px; height: 49px; }

.quality-development-research--mechanic .development-scope--materials.active .development-scope-sign--1 { background-image: url(../img/sprite-production.png); background-position: -673px -155px; width: 45px; height: 52px; }

.quality-development-research--mechanic .development-scope--materials.active .development-scope-sign--2 { background-image: url(../img/sprite-production.png); background-position: -673px -263px; width: 45px; height: 52px; }

.quality-button-development { background-image: url(../img/sprite-quality.png); background-position: -1240px -57px; width: 84px; height: 55px; font-size: 0; border: 0; cursor: default; }

.quality-button-development.active { background-image: url(../img/sprite-quality.png); background-position: -1240px -114px; width: 84px; height: 55px; cursor: pointer; }

.quality-button-development.active:hover { background-image: url(../img/sprite-quality.png); background-position: -1240px 0px; width: 84px; height: 55px; }

.quality-button-development.active:active { background-image: url(../img/sprite-quality.png); background-position: -1240px -171px; width: 84px; height: 55px; }

.board-tab.active { cursor: default; }

.board-tab--machines { background-image: url(../img/sprite-quality.png); background-position: -596px -729px; width: 296px; height: 38px; }

.board-tab--machines:hover { background-image: url(../img/sprite-quality.png); background-position: -298px -729px; width: 296px; height: 38px; }

.board-tab--machines.active { background-image: url(../img/sprite-quality.png); background-position: 0px -729px; width: 296px; height: 38px; }

.board-tab--development { background-image: url(../img/sprite-quality.png); background-position: -596px -689px; width: 296px; height: 38px; }

.board-tab--development:hover { background-image: url(../img/sprite-quality.png); background-position: -298px -689px; width: 296px; height: 38px; }

.board-tab--development.active { background-image: url(../img/sprite-quality.png); background-position: 0px -689px; width: 296px; height: 38px; }

.boards-tabs { margin-top: 2px; }

.boards-tabs:before, .boards-tabs:after { content: ""; display: table; }

.boards-tabs:after { clear: both; }

.boards-tabs .board-tab { float: left; }

.boards-tabs .board-tab--machines { margin-left: 6px; }

.boards-tabs .board-tab--development { margin-left: 37px; }

.boards-tabs .count { float: left; padding: 0 22px 0 0; margin-left: 2px; text-align: center; font-size: 28px; line-height: 36px; font-weight: normal; }

.boards-tabs .maintenance-count { background-image: url(../img/sprite-quality.png); background-position: -1156px -917px; width: 74px; height: 36px; }

.boards-tabs .maintenance-count.active { background-image: url(../img/sprite-quality.png); background-position: -1240px -1184px; width: 74px; height: 36px; }

.boards-tabs .training-count { background-image: url(../img/sprite-quality.png); background-position: -900px -807px; width: 74px; height: 36px; }

.boards-tabs .training-count.active { background-image: url(../img/sprite-quality.png); background-position: -1240px -1146px; width: 74px; height: 36px; }

.boards-tabs .development-count { background-image: url(../img/sprite-quality.png); background-position: -900px -769px; width: 74px; height: 36px; }

.boards-tabs .development-count.active { background-image: url(../img/sprite-quality.png); background-position: -1240px -1108px; width: 74px; height: 36px; }

.quality-action-popup { position: fixed; top: 0; left: 0; right: 0; bottom: 0; z-index: 900; text-shadow: none; }

.quality-action-popup h2 { text-align: center; margin-top: 10px; font-size: 20px; }

.quality-action-popup-bg { position: fixed; top: 0; left: 0; right: 0; bottom: 0; background: rgba(0, 0, 0, 0.7); z-index: 1; animation: appear 300ms ease-out; }

.quality-action-popup-content { width: 497px; height: 248px; padding: 15px; position: absolute; top: 50%; left: 50%; margin: -124px 0 0 -249px; z-index: 10; display: block; animation: appear-pop 400ms ease-out; background: url(../img/quality/popup-small.png) no-repeat 0 0; }

.quality-action-popup-content .button--cancel { display: block; position: absolute; bottom: 25px; left: 72px; background-image: url(../img/sprite-quality.png); background-position: -165px -1079px; width: 163px; height: 36px; }

.quality-action-popup-content .button--cancel:hover { background-image: url(../img/sprite-quality.png); background-position: -490px -971px; width: 163px; height: 36px; }

.quality-action-popup-content .button--cancel:active { background-image: url(../img/sprite-quality.png); background-position: -495px -1079px; width: 163px; height: 36px; }

.quality-action-popup-content .button--apply { display: block; position: absolute; bottom: 25px; right: 72px; background-image: url(../img/sprite-quality.png); background-position: -825px -1079px; width: 163px; height: 36px; }

.quality-action-popup-content .button--apply:hover { background-image: url(../img/sprite-quality.png); background-position: -990px -1079px; width: 163px; height: 36px; }

.quality-action-popup-content .button--apply:active { background-image: url(../img/sprite-quality.png); background-position: -825px -1041px; width: 163px; height: 36px; }

.quality-action-popup .quality-action-picker { width: 208px; margin: 15px auto 0; position: relative; }

.quality-action-popup .quality-action-picker .button { display: block; position: absolute; top: 16px; font-size: 0; }

.quality-action-popup .quality-action-picker .button--less { left: 0; background-image: url(../img/sprite-quality.png); background-position: -450px -1184px; width: 52px; height: 36px; cursor: default; pointer-events: none; }

.quality-action-popup .quality-action-picker .button--less.active { background-image: url(../img/sprite-quality.png); background-position: -396px -1184px; width: 52px; height: 36px; cursor: pointer; pointer-events: auto; }

.quality-action-popup .quality-action-picker .button--less.active:hover { background-image: url(../img/sprite-quality.png); background-position: -1155px -1041px; width: 52px; height: 36px; }

.quality-action-popup .quality-action-picker .button--less.active:active { background-image: url(../img/sprite-quality.png); background-position: -1155px -1079px; width: 52px; height: 36px; }

.quality-action-popup .quality-action-picker .button--more { right: 0; background-image: url(../img/sprite-quality.png); background-position: -504px -1184px; width: 52px; height: 36px; cursor: default; pointer-events: none; }

.quality-action-popup .quality-action-picker .button--more.active { background-image: url(../img/sprite-quality.png); background-position: -924px -883px; width: 52px; height: 36px; cursor: pointer; pointer-events: auto; }

.quality-action-popup .quality-action-picker .button--more.active:hover { background-image: url(../img/sprite-quality.png); background-position: -924px -927px; width: 52px; height: 36px; }

.quality-action-popup .quality-action-picker .button--more.active:active { background-image: url(../img/sprite-quality.png); background-position: -1150px -971px; width: 52px; height: 36px; }

.quality-action-popup .quality-action-picker .value { padding-top: 19px; margin: 0 auto; background-image: url(../img/sprite-quality.png); background-position: 0px -1117px; width: 80px; height: 65px; text-align: center; font-size: 24px; }

.quality-action-popup .quality-action-price { margin-top: 15px; font-size: 24px; text-align: center; }

.quality-action-popup .quality-action-price .move-icon { display: inline-block; margin-left: 2px; position: relative; top: 2px; background-image: url(../img/sprite-quality.png); background-position: -1326px -241px; width: 21px; height: 20px; }

.quality-check-popup { position: fixed; top: 0; left: 0; right: 0; bottom: 0; z-index: 900; text-shadow: none; }

.quality-check-popup h2 { text-align: center; margin-top: 10px; font-size: 20px; }

.quality-check-popup-bg { position: fixed; top: 0; left: 0; right: 0; bottom: 0; background: rgba(0, 0, 0, 0.7); z-index: 1; animation: appear 300ms ease-out; }

.quality-check-popup-content { width: 497px; height: 248px; padding: 15px; position: absolute; top: 50%; left: 50%; margin: -124px 0 0 -249px; z-index: 10; display: block; animation: appear-pop 400ms ease-out; background: url(../img/quality/popup-small.png) no-repeat 0 0; }

.quality-check-popup-content .button--cancel { display: block; position: absolute; bottom: 25px; left: 72px; background-image: url(../img/sprite-quality.png); background-position: -330px -1079px; width: 163px; height: 36px; }

.quality-check-popup-content .button--cancel:hover { background-image: url(../img/sprite-quality.png); background-position: 0px -1079px; width: 163px; height: 36px; }

.quality-check-popup-content .button--cancel:active { background-image: url(../img/sprite-quality.png); background-position: 0px -1041px; width: 163px; height: 36px; }

.quality-check-popup-content .button--next { display: block; position: absolute; bottom: 25px; right: 72px; background-image: url(../img/sprite-quality.png); background-position: -165px -1041px; width: 163px; height: 36px; cursor: default; }

.quality-check-popup-content .button--next.active { background-image: url(../img/sprite-quality.png); background-position: -330px -1041px; width: 163px; height: 36px; cursor: pointer; }

.quality-check-popup-content .button--next.active:hover { background-image: url(../img/sprite-quality.png); background-position: -655px -971px; width: 163px; height: 36px; }

.quality-check-popup-content .button--next.active:active { background-image: url(../img/sprite-quality.png); background-position: -990px -1041px; width: 163px; height: 36px; }

.quality-check-popup .quality-check-number { width: 208px; margin: 15px auto 0; position: relative; }

.quality-check-popup .quality-check-number .button { display: block; position: absolute; top: 16px; font-size: 0; }

.quality-check-popup .quality-check-number .button--check { right: -40px; background-image: url(../img/sprite-quality.png); background-position: -894px -689px; width: 94px; height: 36px; cursor: default; pointer-events: none; }

.quality-check-popup .quality-check-number .button--check.active { background-image: url(../img/sprite-quality.png); background-position: -300px -1184px; width: 94px; height: 36px; cursor: pointer; pointer-events: auto; }

.quality-check-popup .quality-check-number .button--check.active:hover { background-image: url(../img/sprite-quality.png); background-position: -894px -729px; width: 94px; height: 36px; }

.quality-check-popup .quality-check-number .button--check.active:active { background-image: url(../img/sprite-quality.png); background-position: -204px -1184px; width: 94px; height: 36px; }

.quality-check-popup .quality-check-number .value { margin: 0 auto; background-image: url(../img/sprite-quality.png); background-position: 0px -1117px; width: 80px; height: 65px; }

.quality-check-popup .quality-check-number .value input { width: 100%; height: 100%; text-align: center; font-size: 24px; border: 0; background: none; }

.quality-check-popup .quality-check-number .value input:focus { outline: 0; }

.quality-check-popup .quality-check-number .value input::-webkit-outer-spin-button, .quality-check-popup .quality-check-number .value input::-webkit-inner-spin-button { -webkit-appearance: none; margin: 0; }

.quality-check-popup .quality-check-number .value input[type=number] { -moz-appearance: textfield; }

.quality-check-popup .quality-check-result { margin-top: 15px; font-size: 24px; text-align: center; }

.quality-training-popup { position: fixed; top: 0; left: 0; right: 0; bottom: 0; z-index: 900; text-shadow: none; }

.quality-training-popup-bg { position: fixed; top: 0; left: 0; right: 0; bottom: 0; background: rgba(0, 0, 0, 0.7); z-index: 1; animation: appear 300ms ease-out; }

.quality-training-popup-content { width: 497px; height: 248px; padding: 30px 30px; position: absolute; top: 50%; left: 50%; margin: -124px 0 0 -248px; z-index: 10; display: block; animation: appear-pop 400ms ease-out; background: url(../img/common-popup-small.png) no-repeat 0 0; }

.quality-training-popup h1 { margin-bottom: 10px; font-size: 24px; font-weight: bold; text-align: center; }

.quality-training-popup p { text-align: center; }

.quality-training-popup .quality-training-options { text-align: center; margin-top: 15px; }

.quality-training-popup .quality-training-options li { display: inline-block; vertical-align: top; margin: 0 5px; }

.quality-training-popup .quality-training-options .button-option { display: block; width: 120px; height: 100px; padding: 0; position: relative; background-color: #515756; box-shadow: inset -1px -1px 40px rgba(0, 0, 0, 0.5), inset 1px 1px 0 rgba(255, 255, 255, 0.3), inset -1px -1px 0 rgba(0, 0, 0, 0.3), -1px -1px 0 rgba(255, 255, 255, 0.3), 1px 1px 0 rgba(0, 0, 0, 0.3); border: 3px solid #74797b; border-bottom-width: 5px; border-right-width: 5px; border-radius: 5px; text-align: center; cursor: default; opacity: 0.5; font-size: 11px; }

.quality-training-popup .quality-training-options .button-option:before { content: ''; display: inline-block; margin: 4px 20px; }

.quality-training-popup .quality-training-options .button-option:after { content: ''; display: block; position: absolute; bottom: -5px; top: -5px; left: 10%; right: 10%; border: 3px solid #d9d9d9; border-left-color: transparent; border-right-color: transparent; border-radius: 3px; transition: all 250ms ease-out; }

.quality-training-popup .quality-training-options .button-option.active { cursor: pointer; opacity: 1; }

.quality-training-popup .quality-training-options .button-option.active:hover:after { left: 8%; right: 8%; border-top-color: #f2f2f2; border-bottom-color: #f2f2f2; }

.quality-training-popup .quality-training-options .button-option.active:active:after { left: 11%; right: 11%; }

.quality-training-popup .quality-training-options .button-option--shopping:before { background-image: url(../img/sprite-quality.png); background-position: -1240px -868px; width: 62px; height: 58px; }

.quality-training-popup .quality-training-options .button-option--trainer:before { background-image: url(../img/sprite-quality.png); background-position: -1240px -628px; width: 62px; height: 58px; }

.quality-training-popup .quality-training-options .button-option--mechanic:before { background-image: url(../img/sprite-quality.png); background-position: -1240px -808px; width: 62px; height: 58px; }

.summary-popup--quality .summary-popup-content { background: url(../img/quality/summary-popup.png) no-repeat center; }

.summary-popup--quality .failure-rate-table { text-shadow: none; width: 385px; margin: 20px 0; }

.summary-popup--quality .failure-rate-table td, .summary-popup--quality .failure-rate-table th { border-right: 1px solid; border-bottom: 1px solid; padding: 5px 5px; width: 50%; text-align: center; }

.summary-popup--quality .failure-rate-table td:last-child, .summary-popup--quality .failure-rate-table th:last-child { border-right: 0; }

.summary-popup--quality .failure-rate-table th { font-weight: normal; padding-top: 0; }

.summary-popup--quality .failure-rate-table td { font-weight: bold; }

.summary-popup--quality .bonus-chart table thead th { height: 38px; }

.summary-popup--quality .summary-right { width: 805px; height: 677px; margin: -28px -20px -30px -20px; }

.summary-popup--quality .summary-quality { padding: 10px 10px 5px 20px; border-top: 1px solid rgba(255, 255, 255, 0.5); }

.summary-popup--quality .summary-quality:first-child { border-top: 0; }

.summary-popup--quality .summary-quality h3 { font-size: 18px; line-height: 30px; text-shadow: none; }

.summary-popup--quality .summary-quality .subheader { overflow: hidden; }

.summary-popup--quality .summary-quality .subheader h3 { float: left; width: 285px; }

.summary-popup--quality .summary-quality .empty-info { text-shadow: none; font-size: 18px; line-height: 30px; margin-bottom: 40px; }

.summary-popup--quality .summary-quality-item { padding-bottom: 10px; margin-bottom: 10px; border-bottom: 1px solid rgba(255, 255, 255, 0.3); text-shadow: none; }

.summary-popup--quality .summary-quality-item .robot-card { float: left; margin-bottom: 0; }

.summary-popup--quality .summary-quality-item .item-info { width: 215px; float: left; margin-left: 10px; }

.summary-popup--quality .summary-quality-item .item-info .item-info-row.sum { border-top: 1px solid rgba(255, 255, 255, 0.3); margin-top: 5px; padding-top: 5px; }

.summary-popup--quality .summary-quality-item .item-info .item-info-text { margin-bottom: 15px; }

.summary-popup--quality .summary-quality-item .item-info.item-info__status-badRobot .item-info-row:first-child .price, .summary-popup--quality .summary-quality-item .item-info.item-info__status-badRobot_badTop .item-info-row:first-child .price, .summary-popup--quality .summary-quality-item .item-info.item-info__status-badRobot_badBottom .item-info-row:first-child .price, .summary-popup--quality .summary-quality-item .item-info.item-info__status-badRobot_badTop_badBottom .item-info-row:first-child .price { position: relative; }

.summary-popup--quality .summary-quality-item .item-info.item-info__status-badRobot .item-info-row:first-child .price:after, .summary-popup--quality .summary-quality-item .item-info.item-info__status-badRobot_badTop .item-info-row:first-child .price:after, .summary-popup--quality .summary-quality-item .item-info.item-info__status-badRobot_badBottom .item-info-row:first-child .price:after, .summary-popup--quality .summary-quality-item .item-info.item-info__status-badRobot_badTop_badBottom .item-info-row:first-child .price:after { content: ''; position: absolute; display: block; position: absolute; top: 50%; left: -4px; right: -4px; height: 2px; margin-top: -1px; background: #fff; box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.3); transform: rotate(10deg); }

.summary-popup--quality .summary-quality-item .item-info .label { display: block; float: left; width: 175px; height: 21px; line-height: 21px; }

.summary-popup--quality .summary-quality-item .item-info .price { display: block; float: right; width: 40px; height: 21px; line-height: 21px; text-align: right; }

.marketing .cover-bg { background-image: url(../img/marketing/bg.jpg); }

.marketing .depertment-name { background-image: url(../img/sprite-marketing.png); background-position: -263px -815px; width: 296px; height: 36px; }

.marketing .timer { background-image: url(../img/sprite-marketing.png); background-position: -561px -815px; width: 296px; height: 36px; }

.marketing .media { position: relative; z-index: 11; background-image: url(../img/sprite-marketing.png); background-position: -150px -890px; width: 148px; height: 74px; }

.marketing .media:hover { background-image: url(../img/sprite-marketing.png); background-position: -300px -890px; width: 148px; height: 74px; }

.marketing .media.active { background-image: url(../img/sprite-marketing.png); background-position: 0px -890px; width: 148px; height: 74px; }

.marketing .summary { background-image: url(../img/sprite-marketing.png); background-position: -1300px -698px; width: 298px; height: 36px; }

.marketing .summary:hover { background-image: url(../img/sprite-marketing.png); background-position: -500px -769px; width: 298px; height: 36px; }

.marketing .summary:active { background-image: url(../img/sprite-marketing.png); background-position: -1100px -769px; width: 298px; height: 36px; }

.marketing .report { background-image: url(../img/sprite-marketing.png); background-position: -1600px -698px; width: 298px; height: 36px; }

.marketing .report:hover { background-image: url(../img/sprite-marketing.png); background-position: -1000px -698px; width: 298px; height: 36px; }

.marketing .report:active { background-image: url(../img/sprite-marketing.png); background-position: -800px -769px; width: 298px; height: 36px; }

.marketing .summary-popup-content { background: url(../img/marketing/summary-popup.png) no-repeat center; }

.marketing .summary-popup .back { background-image: url(../img/sprite-marketing.png); background-position: -1519px -815px; width: 163px; height: 36px; }

.marketing .summary-popup .back:hover { background-image: url(../img/sprite-marketing.png); background-position: -1900px -698px; width: 163px; height: 36px; }

.marketing .summary-popup .back:active { background-image: url(../img/sprite-marketing.png); background-position: -859px -815px; width: 163px; height: 36px; }

.marketing .panel-container { z-index: 100; }

.marketing .nano > .nano-pane { background: url(../img/marketing/scrollbar-bg.png) no-repeat center; }

.marketing .nano > .nano-pane > .nano-slider { background: url(../img/marketing/scrollbar-top.png) no-repeat top center, url(../img/marketing/scrollbar-bottom.png) no-repeat bottom center; }

.marketing .nano > .nano-pane > .nano-slider:after { background: url(../img/marketing/scrollbar-middle.png) repeat-y top center; }

.marketing-asset-counter { background-image: url(../img/sprite-marketing.png); background-position: -1000px -622px; width: 447px; height: 74px; }

.marketing-asset-counter .assets { width: 340px; margin: 0 -10px 0 auto; padding: 14px 0 0; }

.marketing-asset-counter .assets:before, .marketing-asset-counter .assets:after { content: ""; display: table; }

.marketing-asset-counter .assets:after { clear: both; }

.marketing-asset-counter .assets-item { float: left; width: 20%; text-align: center; }

.marketing-asset-counter .assets-item-icon { display: block; margin: 0 auto; }

.marketing-asset-counter .assets-item-count { display: block; font-weight: bold; }

.marketing-asset-counter .assets-item--marketing .assets-item-icon { background-image: url(../img/sprite-marketing.png); background-position: -859px -966px; width: 40px; height: 40px; }

.marketing-asset-counter .assets-item--marketing.active .assets-item-icon { background-image: url(../img/sprite-marketing.png); background-position: -607px -966px; width: 40px; height: 40px; }

.marketing-asset-counter .assets-item--assembly .assets-item-icon { background-image: url(../img/sprite-marketing.png); background-position: -649px -966px; width: 40px; height: 40px; }

.marketing-asset-counter .assets-item--assembly.active .assets-item-icon { background-image: url(../img/sprite-marketing.png); background-position: -481px -966px; width: 40px; height: 40px; }

.marketing-asset-counter .assets-item--sales .assets-item-icon { background-image: url(../img/sprite-marketing.png); background-position: -523px -966px; width: 40px; height: 40px; }

.marketing-asset-counter .assets-item--sales.active .assets-item-icon { background-image: url(../img/sprite-marketing.png); background-position: -565px -966px; width: 40px; height: 40px; }

.marketing-asset-counter .assets-item--production .assets-item-icon { background-image: url(../img/sprite-marketing.png); background-position: -817px -966px; width: 40px; height: 40px; }

.marketing-asset-counter .assets-item--production.active .assets-item-icon { background-image: url(../img/sprite-marketing.png); background-position: -775px -966px; width: 40px; height: 40px; }

.marketing-asset-counter .assets-item--quality .assets-item-icon { background-image: url(../img/sprite-marketing.png); background-position: -733px -966px; width: 40px; height: 40px; }

.marketing-asset-counter .assets-item--quality.active .assets-item-icon { background-image: url(../img/sprite-marketing.png); background-position: -691px -966px; width: 40px; height: 40px; }

.marketing-tabs-wrapper { padding: 2px 0; }

.marketing-tabs-wrapper:before, .marketing-tabs-wrapper:after { content: ""; display: table; }

.marketing-tabs-wrapper:after { clear: both; }

.marketing-tabs-wrapper .marketing-tabs { float: left; width: 118px; height: 620px; }

.marketing-tabs-wrapper .marketing-tabs-content { float: left; width: 1082px; height: 620px; }

.marketing-tabs .tabs-group { height: 310px; padding-top: 2px; position: relative; }

.marketing-tabs .tabs-group:first-child { margin-top: 2px; }

.marketing-tabs .tabs-group-heading { position: absolute; top: 6px; left: 0; font-size: 0; }

.marketing-tabs .tabs-group-heading--robots { background-image: url(../img/sprite-marketing.png); background-position: 0px -966px; width: 36px; height: 296px; }

.marketing-tabs .tabs-group-heading--functionalities { background-image: url(../img/sprite-marketing.png); background-position: -38px -966px; width: 36px; height: 296px; }

.marketing-tabs .tabs-group-list { margin-left: 42px; }

.marketing-tabs .tabs-group-list-item { background-image: url(../img/sprite-marketing.png); background-position: -314px -966px; width: 76px; height: 76px; margin-top: -1px; padding-left: 3px; overflow: hidden; cursor: pointer; }

.marketing-tabs .tabs-group-list-item:first-child { margin-top: 2px; }

.marketing-tabs .tabs-group-list-item:hover { background-image: url(../img/sprite-marketing.png); background-position: -158px -966px; width: 76px; height: 76px; }

.marketing-tabs .tabs-group-list-item.active, .marketing-tabs .tabs-group-list-item:active { background-image: url(../img/sprite-marketing.png); background-position: -236px -966px; width: 76px; height: 76px; }

.marketing-tabs .tabs-group-list-item.active { cursor: default; }

.marketing-tabs .tabs-group-list-item:before { content: ''; display: block; position: relative; }

.marketing-tabs .tabs-group-list-item--robot-1:before { top: 13.5px; left: 13.5px; background-image: url(../img/sprite-quality.png); background-position: -204px -1237px; width: 49px; height: 49px; }

.marketing-tabs .tabs-group-list-item--robot-2:before { top: 13.5px; left: 13.5px; background-image: url(../img/sprite-quality.png); background-position: -153px -1237px; width: 49px; height: 49px; }

.marketing-tabs .tabs-group-list-item--robot-3:before { top: 13.5px; left: 13.5px; background-image: url(../img/sprite-quality.png); background-position: -102px -1237px; width: 49px; height: 49px; }

.marketing-tabs .tabs-group-list-item--robot-4:before { top: 13.5px; left: 13.5px; background-image: url(../img/sprite-quality.png); background-position: -51px -1237px; width: 49px; height: 49px; }

.marketing-tabs .tabs-group-list-item--fn-teacher:before { top: 9px; left: 7px; background-image: url(../img/sprite-quality.png); background-position: -1240px -988px; width: 62px; height: 58px; }

.marketing-tabs .tabs-group-list-item--fn-shopping:before { top: 9px; left: 7px; background-image: url(../img/sprite-quality.png); background-position: -1240px -868px; width: 62px; height: 58px; }

.marketing-tabs .tabs-group-list-item--fn-trainer:before { top: 9px; left: 7px; background-image: url(../img/sprite-quality.png); background-position: -1240px -628px; width: 62px; height: 58px; }

.marketing-tabs .tabs-group-list-item--fn-mechanic:before { top: 9px; left: 7px; background-image: url(../img/sprite-quality.png); background-position: -1240px -808px; width: 62px; height: 58px; }

.marketing-panel { text-shadow: none; position: absolute; top: -100000px; left: -10000px; z-index: 0; }

.marketing-panel.active { position: relative; top: 0; left: 0; z-index: 10; }

.marketing-panel--functionalities { background-image: url(../img/sprite-marketing.png); background-position: 0px 0px; width: 1082px; height: 620px; }

.marketing-panel--robots { background-image: url(../img/sprite-marketing.png); background-position: -1084px 0px; width: 1082px; height: 620px; }

.marketing-panel .marketing-viral-button { position: absolute; left: 547px; bottom: 12px; }

.marketing-panel h1 { width: 400px; margin: 0 0 0 -200px; position: absolute; top: 2px; left: 50%; font-size: 24px; text-align: center; font-weight: bold; }

.marketing-bars { padding-left: 10px; }

.marketing-bars-scrollable { width: 100%; height: 100%; }

.marketing-bars-scale { padding: 15px 0; }

.marketing-bars-scale-line { display: block; height: 30px; line-height: 30px; white-space: nowrap; }

.marketing-bars-scale-line:before { content: ''; display: inline-block; width: 388px; height: 1px; position: relative; top: -4px; white-space: nowrap; font-weight: normal; letter-spacing: 3px; border-top: 1px dashed; opacity: .7; }

.marketing-bars-scale-line .label { display: inline-block; width: 22px; text-align: center; font-size: 14px; }

.marketing-bars-chart { display: flex; width: 406px; height: 1500px; position: absolute; top: 30px; left: -15px; z-index: 15; }

.marketing-bars-chart li { display: flex; flex-direction: column; align-items: center; justify-content: flex-end; width: 25%; }

.marketing-bars-chart li .bar { width: 60px; height: 30px; margin: 0 auto; padding: 7px 0; position: relative; background: url(../img/marketing/campaign-middle.png) repeat-y 0 0; background-clip: content-box; transition: height 300ms ease; }

.marketing-bars-chart li .bar:before, .marketing-bars-chart li .bar:after { content: ''; display: block; position: absolute; left: 0; right: 0; }

.marketing-bars-chart li .bar:before { height: 7px; top: 0; background: url(../img/marketing/campaign-top.png) no-repeat 0 0; }

.marketing-bars-chart li .bar:after { height: 7px; bottom: 0; background: url(../img/marketing/campaign-bottom.png) no-repeat 0 0; }

.marketing-bars-chart li .bar--theme-billboards, .marketing-bars-chart li .bar--theme-flyers { background-image: url(../img/marketing/campaign-middle-alt.png); }

.marketing-bars-chart li .bar--theme-billboards:before, .marketing-bars-chart li .bar--theme-flyers:before { background-image: url(../img/marketing/campaign-top-alt.png); }

.marketing-bars-chart li .bar--theme-billboards:after, .marketing-bars-chart li .bar--theme-flyers:after { background-image: url(../img/marketing/campaign-bottom-alt.png); }

.marketing-bars-chart li .bar--theme-internet, .marketing-bars-chart li .bar--theme-newspaper { background-image: url(../img/marketing/campaign-middle-alt2.png); }

.marketing-bars-chart li .bar--theme-internet:before, .marketing-bars-chart li .bar--theme-newspaper:before { background-image: url(../img/marketing/campaign-top-alt2.png); }

.marketing-bars-chart li .bar--theme-internet:after, .marketing-bars-chart li .bar--theme-newspaper:after { background-image: url(../img/marketing/campaign-bottom-alt2.png); }

.marketing-bars-chart li .bar--h-1 { height: 30px; }

.marketing-bars-chart li .bar--h-2 { height: 60px; }

.marketing-bars-chart li .bar--h-3 { height: 90px; }

.marketing-bars-chart li .bar--h-4 { height: 120px; }

.marketing-bars-chart li .bar--h-5 { height: 150px; }

.marketing-bars-chart li .bar--h-6 { height: 180px; }

.marketing-bars-chart li .bar--h-7 { height: 210px; }

.marketing-bars-chart li .bar--h-8 { height: 240px; }

.marketing-bars-chart li .bar--h-9 { height: 270px; }

.marketing-bars-chart li .bar--h-10 { height: 300px; }

.marketing-bars-chart li .bar--h-11 { height: 330px; }

.marketing-bars-chart li .bar--h-12 { height: 360px; }

.marketing-bars-chart li .bar--h-13 { height: 390px; }

.marketing-bars-chart li .bar--h-14 { height: 420px; }

.marketing-bars-chart li .bar--h-15 { height: 450px; }

.marketing-bars-chart li .bar--h-16 { height: 480px; }

.marketing-bars-chart li .bar--h-17 { height: 510px; }

.marketing-bars-chart li .bar--h-18 { height: 540px; }

.marketing-bars-chart li .bar--h-19 { height: 570px; }

.marketing-bars-chart li .bar--h-20 { height: 600px; }

.marketing-bars-chart li .bar--h-21 { height: 630px; }

.marketing-bars-chart li .bar--h-22 { height: 660px; }

.marketing-bars-chart li .bar--h-23 { height: 690px; }

.marketing-bars-chart li .bar--h-24 { height: 720px; }

.marketing-bars-chart li .bar--h-25 { height: 750px; }

.marketing-bars-chart li .bar--h-26 { height: 780px; }

.marketing-bars-chart li .bar--h-27 { height: 810px; }

.marketing-bars-chart li .bar--h-28 { height: 840px; }

.marketing-bars-chart li .bar--h-29 { height: 870px; }

.marketing-bars-chart li .bar--h-30 { height: 900px; }

.marketing-bars-chart li .bar--h-31 { height: 930px; }

.marketing-bars-chart li .bar--h-32 { height: 960px; }

.marketing-bars-chart li .bar--h-33 { height: 990px; }

.marketing-bars-chart li .bar--h-34 { height: 1020px; }

.marketing-bars-chart li .bar--h-35 { height: 1050px; }

.marketing-bars-chart li .bar--h-36 { height: 1080px; }

.marketing-bars-chart li .bar--h-37 { height: 1110px; }

.marketing-bars-chart li .bar--h-38 { height: 1140px; }

.marketing-bars-chart li .bar--h-39 { height: 1170px; }

.marketing-bars-chart li .bar--h-40 { height: 1200px; }

.marketing-bars-chart li .bar--h-41 { height: 1230px; }

.marketing-bars-chart li .bar--h-42 { height: 1260px; }

.marketing-bars-chart li .bar--h-43 { height: 1290px; }

.marketing-bars-chart li .bar--h-44 { height: 1320px; }

.marketing-bars-chart li .bar--h-45 { height: 1350px; }

.marketing-bars-chart li .bar--h-46 { height: 1380px; }

.marketing-bars-chart li .bar--h-47 { height: 1410px; }

.marketing-bars-chart li .bar--h-48 { height: 1440px; }

.marketing-bars-chart li .bar--h-49 { height: 1470px; }

.marketing-bars-chart li .bar--h-50 { height: 1500px; }

.marketing-bars-chart li .bar--h-51 { height: 1530px; }

.marketing-bars-chart li .bar--h-52 { height: 1560px; }

.marketing-bars-chart li .bar--h-53 { height: 1590px; }

.marketing-bars-chart li .bar--h-54 { height: 1620px; }

.marketing-bars-chart li .bar--h-55 { height: 1650px; }

.marketing-bars-chart li .bar--h-56 { height: 1680px; }

.marketing-bars-chart li .bar--h-57 { height: 1710px; }

.marketing-bars-chart li .bar--h-58 { height: 1740px; }

.marketing-bars-chart li .bar--h-59 { height: 1770px; }

.marketing-bars-chart li .bar--h-60 { height: 1800px; }

.marketing-bars-chart li .bar--h-61 { height: 1830px; }

.marketing-bars-chart li .bar--h-62 { height: 1860px; }

.marketing-bars-chart li .bar--h-63 { height: 1890px; }

.marketing-bars-chart li .bar--h-64 { height: 1920px; }

.marketing-bars-chart li .bar--h-65 { height: 1950px; }

.marketing-bars-chart li .bar--h-66 { height: 1980px; }

.marketing-bars-chart li .bar--h-67 { height: 2010px; }

.marketing-bars-chart li .bar--h-68 { height: 2040px; }

.marketing-bars-chart li .bar--h-69 { height: 2070px; }

.marketing-bars-chart li .bar--h-70 { height: 2100px; }

.marketing-bars-chart li .bar--h-71 { height: 2130px; }

.marketing-bars-chart li .bar--h-72 { height: 2160px; }

.marketing-bars-chart li .bar--h-73 { height: 2190px; }

.marketing-bars-chart li .bar--h-74 { height: 2220px; }

.marketing-bars-chart li .bar--h-75 { height: 2250px; }

.marketing-bars-chart li .bar--h-76 { height: 2280px; }

.marketing-bars-chart li .bar--h-77 { height: 2310px; }

.marketing-bars-chart li .bar--h-78 { height: 2340px; }

.marketing-bars-chart li .bar--h-79 { height: 2370px; }

.marketing-bars-chart li .bar--h-80 { height: 2400px; }

.marketing-bars-chart li .bar--h-81 { height: 2430px; }

.marketing-bars-chart li .bar--h-82 { height: 2460px; }

.marketing-bars-chart li .bar--h-83 { height: 2490px; }

.marketing-bars-chart li .bar--h-84 { height: 2520px; }

.marketing-bars-chart li .bar--h-85 { height: 2550px; }

.marketing-bars-chart li .bar--h-86 { height: 2580px; }

.marketing-bars-chart li .bar--h-87 { height: 2610px; }

.marketing-bars-chart li .bar--h-88 { height: 2640px; }

.marketing-bars-chart li .bar--h-89 { height: 2670px; }

.marketing-bars-chart li .bar--h-90 { height: 2700px; }

.marketing-bars-chart li .bar--h-91 { height: 2730px; }

.marketing-bars-chart li .bar--h-92 { height: 2760px; }

.marketing-bars-chart li .bar--h-93 { height: 2790px; }

.marketing-bars-chart li .bar--h-94 { height: 2820px; }

.marketing-bars-chart li .bar--h-95 { height: 2850px; }

.marketing-bars-chart li .bar--h-96 { height: 2880px; }

.marketing-bars-chart li .bar--h-97 { height: 2910px; }

.marketing-bars-chart li .bar--h-98 { height: 2940px; }

.marketing-bars-chart li .bar--h-99 { height: 2970px; }

.marketing-bars-chart li .bar--h-100 { height: 3000px; }

.marketing-bars-range { width: 87px; position: absolute; top: 0; left: 413px; }

.marketing-bars-range-item { position: absolute; left: 0; }

.marketing-bars-range-item:before, .marketing-bars-range-item:after { content: ''; display: block; width: 40px; height: 40px; position: absolute; left: 3px; border: 1px solid; }

.marketing-bars-range-item:before { top: 0; border-width: 1px 1px 0 0; }

.marketing-bars-range-item:after { bottom: -1px; border-width: 0 1px 1px 0; }

.marketing-bars-range-item--32-50 { height: 540px; top: 30px; }

.marketing-bars-range-item--16-31 { height: 450px; top: 600px; }

.marketing-bars-range-item--7-15 { height: 240px; top: 1080px; }

.marketing-bars-range-item .inner:before, .marketing-bars-range-item .inner:after { content: ''; display: block; width: 388px; height: 1px; margin-right: 25px; position: absolute; right: 100%; border-top: 1px solid; }

.marketing-bars-range-item .inner:before { top: 0; }

.marketing-bars-range-item .inner:after { bottom: -1px; }

.marketing-bars-range-item .value { position: absolute; top: 50%; left: 0; margin-top: -26px; background-image: url(../img/sprite-marketing.png); background-position: -392px -966px; width: 87px; height: 53px; text-align: center; font-size: 20px; line-height: 53px; }

.marketing-bars-wrapper { width: 512px; height: 590px; position: absolute; top: 28px; left: 10px; }

.marketing-bars-wrapper .marketing-bars-rounds { position: absolute; left: 0; right: 0; bottom: 1px; }

.marketing-bars-wrapper .marketing-bars-points { position: absolute; left: 0; right: 0; top: 38px; }

.marketing-bars-wrapper .marketing-bars { position: absolute; top: 76px; left: 0; right: -21px; bottom: 37px; }

.marketing-bars-wrapper .marketing-bars .nano-content { padding-right: 21px; }

.marketing-bars-rounds { padding-left: 65px; }

.marketing-bars-rounds:before, .marketing-bars-rounds:after { content: ""; display: table; }

.marketing-bars-rounds:after { clear: both; }

.marketing-bars-rounds-item { float: left; width: 44px; line-height: 32px; margin-left: 58px; text-align: center; font-size: 24px; }

.marketing-bars-rounds-item:first-child { display: none; }

.marketing-bars-points:before, .marketing-bars-points:after { content: ""; display: table; }

.marketing-bars-points:after { clear: both; }

.marketing-bars-points-item { float: left; width: 44px; margin-left: 58px; position: relative; text-align: center; line-height: 32px; font-size: 24px; }

.marketing-bars-points-item:after { content: ''; display: block; position: absolute; top: 0; left: 48px; background-image: url(../img/sprite-marketing.png); background-position: -2113px -660px; width: 29px; height: 29px; }

.marketing-bars-points-item.active:after { background-image: url(../img/sprite-marketing.png); background-position: -2134px -698px; width: 29px; height: 29px; }

.marketing-bars-points-item:first-child { margin-left: 22px; margin-right: 1px; }

.marketing-bars-points-item:first-child:after { left: 50px; }

.marketing-content { position: relative; background-image: url(../img/sprite-marketing.png); background-position: -500px -622px; width: 248px; height: 145px; }

.marketing-content--newspaper, .marketing-content--tv, .marketing-content--billboards, .marketing-content--internet { background-image: url(../img/sprite-marketing.png); background-position: 0px -622px; width: 248px; height: 191px; }

.marketing-content-headline { padding: 5px 15px; position: relative; }

.marketing-content-headline h3 { padding-top: 7px; }

.marketing-content-headline .params { position: absolute; top: 3px; right: 45px; }

.marketing-content-headline .params-item { display: block; white-space: nowrap; }

.marketing-content-headline .params-item-line { display: inline-block; text-align: right; }

.marketing-content-headline .params-item-line-value { display: inline-block; width: 21px; position: relative; text-align: center; }

.marketing-content-headline .params-item-line-value.double { width: 28px; }

.marketing-content-headline .params-item-line-value:before { content: '-'; display: block; width: 8px; margin-left: -4px; position: absolute; top: 0; left: 0; text-align: center; }

.marketing-content-headline .params-item-line-value:first-child:before { display: none; }

.marketing-content-headline:after { content: ''; display: block; position: absolute; top: 7px; right: 12px; background-image: url(../img/sprite-marketing.png); background-position: -2134px -698px; width: 29px; height: 29px; }

.marketing-content-values { position: absolute; top: 47px; left: 7px; right: 7px; bottom: 4px; }

.marketing-content-values ul:before, .marketing-content-values ul:after { content: ""; display: table; }

.marketing-content-values ul:after { clear: both; }

.marketing-content-values ul li { float: left; padding: 3px; }

.marketing-content.is-fulfilled { background-image: url(../img/sprite-marketing.png); background-position: -750px -622px; width: 248px; height: 145px; }

.marketing-content.is-fulfilled.marketing-content--newspaper, .marketing-content.is-fulfilled.marketing-content--tv, .marketing-content.is-fulfilled.marketing-content--billboards, .marketing-content.is-fulfilled.marketing-content--internet { background-image: url(../img/sprite-marketing.png); background-position: -250px -622px; width: 248px; height: 191px; }

.marketing-content.is-fulfilled .marketing-content-values:before { content: ''; display: block; margin: -30px 0 0 -34px; position: absolute; top: 50%; left: 50%; background-image: url(../img/sprite-marketing.png); background-position: -76px -1055px; width: 68px; height: 60px; }

.marketing-content.is-fulfilled .marketing-content-values ul { display: none; }

.marketing-content-wrapper { width: 260px; height: 546px; top: 68px; left: 816px; position: absolute; }

.marketing-content-wrapper .marketing-content { margin: 5px auto; }

.marketing-content-pill { position: relative; }

.marketing-content-pill .button-add { display: none; width: 100%; height: 100%; position: absolute; top: 0; left: 0; cursor: pointer; font-size: 0; background: none; border: 0; }

.marketing-content-pill .button-remove { display: none; width: 100%; height: 100%; position: absolute; top: 0; right: 0; cursor: pointer; font-size: 0; border: 0; background-image: url(../img/sprite-marketing.png); background-position: -1000px -736px; width: 27px; height: 26px; transform: scale(0.8); transform-origin: top right; }

.marketing-content-pill .button-remove:hover { background-image: url(../img/sprite-marketing.png); background-position: -1029px -736px; width: 27px; height: 26px; }

.marketing-content-pill.clickable .button-add { display: block; }

.marketing-content-pill.clickable:after { content: '+'; display: block; width: 18px; height: 18px; margin: 0; position: absolute; right: 0; bottom: 0; background: #000; color: #fff; line-height: 16px; font-size: 16px; font-weight: bold; text-align: center; border-radius: 50%; border: 1px solid #fff; opacity: .5; }

.marketing-content-pill.clickable:hover:after { opacity: 1; }

.marketing-content-pill.removable .button-remove { display: block; }

.marketing-content-pill--marketing { background-image: url(../img/sprite-marketing.png); background-position: -859px -966px; width: 40px; height: 40px; }

.marketing-content-pill--marketing.active, .marketing-content-pill--marketing.clickable:hover { background-image: url(../img/sprite-marketing.png); background-position: -607px -966px; width: 40px; height: 40px; }

.marketing-content-pill--assembly { background-image: url(../img/sprite-marketing.png); background-position: -649px -966px; width: 40px; height: 40px; }

.marketing-content-pill--assembly.active, .marketing-content-pill--assembly.clickable:hover { background-image: url(../img/sprite-marketing.png); background-position: -481px -966px; width: 40px; height: 40px; }

.marketing-content-pill--sales { background-image: url(../img/sprite-marketing.png); background-position: -523px -966px; width: 40px; height: 40px; }

.marketing-content-pill--sales.active, .marketing-content-pill--sales.clickable:hover { background-image: url(../img/sprite-marketing.png); background-position: -565px -966px; width: 40px; height: 40px; }

.marketing-content-pill--production { background-image: url(../img/sprite-marketing.png); background-position: -817px -966px; width: 40px; height: 40px; }

.marketing-content-pill--production.active, .marketing-content-pill--production.clickable:hover { background-image: url(../img/sprite-marketing.png); background-position: -775px -966px; width: 40px; height: 40px; }

.marketing-content-pill--quality { background-image: url(../img/sprite-marketing.png); background-position: -733px -966px; width: 40px; height: 40px; }

.marketing-content-pill--quality.active, .marketing-content-pill--quality.clickable:hover { background-image: url(../img/sprite-marketing.png); background-position: -691px -966px; width: 40px; height: 40px; }

.marketing-research, .marketing-market-research { width: 260px; height: 46px; padding-right: 72px; position: absolute; left: 547px; }

.marketing-research-fill { width: 264px; height: 336px; position: absolute; top: 29px; left: 546px; pointer-events: none; }

.marketing-research-headline { padding: 5px 10px; position: relative; }

.marketing-research-headline h3 { padding-bottom: 7px; }

.marketing-research--billboards, .marketing-research--tv { top: 80px; }

.marketing-research--radio, .marketing-research--newspaper { top: 275px; }

.marketing-research--flyers, .marketing-research--internet { display: none; }

.marketing-research-values, .marketing-market-research-values { padding: 0 0 0 2px; }

.marketing-research-values ul:before, .marketing-market-research-values ul:before, .marketing-research-values ul:after, .marketing-market-research-values ul:after { content: ""; display: table; }

.marketing-research-values ul:after, .marketing-market-research-values ul:after { clear: both; }

.marketing-research-values ul li, .marketing-market-research-values ul li { float: left; padding: 3px; }

.marketing-research .button-apply, .marketing-market-research .button-apply { display: block; position: absolute; top: 41px; right: 5px; background-image: url(../img/sprite-marketing.png); background-position: -2065px -698px; width: 67px; height: 35px; font-size: 0; cursor: default; }

.marketing-research .button-apply.active, .marketing-market-research .button-apply.active { background-image: url(../img/sprite-marketing.png); background-position: -2083px -815px; width: 67px; height: 35px; cursor: pointer; }

.marketing-research .button-apply.active:hover, .marketing-market-research .button-apply.active:hover { background-image: url(../img/sprite-marketing.png); background-position: -263px -853px; width: 67px; height: 35px; }

.marketing-research .button-apply.active:active, .marketing-market-research .button-apply.active:active { background-image: url(../img/sprite-marketing.png); background-position: -2014px -815px; width: 67px; height: 35px; }

.marketing-market-research { top: 446px; }

.marketing-market-research .button-apply { top: 5px; }

.marketing-market-research-popup { position: fixed; top: 0; left: 0; right: 0; bottom: 0; z-index: 900; text-shadow: none; }

.marketing-market-research-popup-bg { position: fixed; top: 0; left: 0; right: 0; bottom: 0; background: rgba(0, 0, 0, 0.7); z-index: 1; animation: appear 300ms ease-out; }

.marketing-market-research-popup-content { width: 389px; height: 294px; padding: 15px; position: absolute; top: 50%; left: 50%; margin: -147px 0 0 -195px; z-index: 10; display: block; animation: appear-pop 400ms ease-out; background: url(../img/marketing/check-popup/bg.png) no-repeat 0 0; }

.marketing-market-research-popup-content .button-close { display: block; margin-left: -81.5px; position: absolute; bottom: 15px; left: 50%; background-image: url(../img/sprite-marketing.png); background-position: -1519px -815px; width: 163px; height: 36px; }

.marketing-market-research-popup-content .button-close:hover { background-image: url(../img/sprite-marketing.png); background-position: -1900px -698px; width: 163px; height: 36px; }

.marketing-market-research-popup-content .button-close:active { background-image: url(../img/sprite-marketing.png); background-position: -859px -815px; width: 163px; height: 36px; }

.marketing-market-research-popup .market-research-check { padding: 56px 10px 0; }

.marketing-market-research-popup .market-research-check-item { margin: 3px 0; }

.marketing-market-research-popup .market-research-check-item:before, .marketing-market-research-popup .market-research-check-item:after { content: ""; display: table; }

.marketing-market-research-popup .market-research-check-item:after { clear: both; }

.marketing-market-research-popup .market-research-check-item .range { display: block; width: 33.333%; float: left; line-height: 53px; font-size: 24px; text-align: center; }

.marketing-market-research-popup .market-research-check-item .value { display: block; margin: 0 15px; float: left; background-image: url(../img/sprite-marketing.png); background-position: -392px -966px; width: 87px; height: 53px; text-align: center; font-size: 20px; line-height: 53px; }

.marketing-market-research-popup .market-research-check-item .button--check { display: none; margin: 8px; float: left; background-image: url(../img/sprite-marketing.png); background-position: -2044px -660px; width: 67px; height: 35px; font-size: 0; }

.marketing-market-research-popup .market-research-check-item .button--check.active { display: block; }

.marketing-market-research-popup .market-research-check-item .button--check:active { background-image: url(../img/sprite-marketing.png); background-position: -1975px -660px; width: 67px; height: 35px; }

.marketing-market-research-popup .market-research-check-item .button--check:hover { background-image: url(../img/sprite-marketing.png); background-position: -332px -853px; width: 67px; height: 35px; }

.marketing-media-popup { position: fixed; top: 0; left: 0; right: 0; bottom: 0; z-index: 900; text-shadow: none; }

.marketing-media-popup-bg { position: fixed; top: 0; left: 0; right: 0; bottom: 0; background: rgba(0, 0, 0, 0.7); z-index: 1; animation: appear 300ms ease-out; }

.marketing-media-popup-content { width: 761px; height: 507px; padding: 15px; position: absolute; top: 50%; left: 50%; margin: -380px 0 0 -253px; z-index: 10; display: block; animation: appear-pop 400ms ease-out; background: url(../img/marketing/media-popup/media-popup.png) no-repeat 0 0; }

.marketing-media-popup-content:after { content: ''; display: block; position: absolute; top: 0; left: 0; right: 0; bottom: 0; z-index: 100; background: url(../img/marketing/media-popup/media-popup-top.png) no-repeat 0 0; pointer-events: none; }

.marketing-media-popup-content-inner { width: 100%; height: 100%; }

.marketing-media-popup-content .button-close { display: block; margin-left: -81.5px; position: absolute; bottom: 15px; left: 50%; background-image: url(../img/sprite-marketing.png); background-position: -1519px -815px; width: 163px; height: 36px; }

.marketing-media-popup-content .button-close:hover { background-image: url(../img/sprite-marketing.png); background-position: -1900px -698px; width: 163px; height: 36px; }

.marketing-media-popup-content .button-close:active { background-image: url(../img/sprite-marketing.png); background-position: -859px -815px; width: 163px; height: 36px; }

.marketing-media-popup .marketing-media:before, .marketing-media-popup .marketing-media:after { content: ""; display: table; }

.marketing-media-popup .marketing-media:after { clear: both; }

.marketing-media-popup .marketing-media-column { float: left; width: 50%; padding-bottom: 45px; }

.marketing-media-popup .marketing-media-column-heading { font-size: 0; margin: 30px auto 10px; }

.marketing-media-popup .marketing-media-column-heading--robots { background-image: url(../img/sprite-marketing.png); background-position: -1400px -769px; width: 296px; height: 36px; }

.marketing-media-popup .marketing-media-column-heading--functionalities { background-image: url(../img/sprite-marketing.png); background-position: -1698px -769px; width: 296px; height: 36px; }

.marketing-media-popup .marketing-media-column .media-card { margin: 10px auto; }

.marketing-viral-button { background-image: url(../img/sprite-marketing.png); background-position: 0px -815px; width: 261px; height: 73px; }

.marketing-viral-button:hover { background-image: url(../img/sprite-marketing.png); background-position: -1712px -622px; width: 261px; height: 73px; }

.marketing-viral-button:active { background-image: url(../img/sprite-marketing.png); background-position: -1449px -622px; width: 261px; height: 73px; }

.marketing-viral-popup { position: fixed; top: 0; left: 0; right: 0; bottom: 0; z-index: 900; text-shadow: none; }

.marketing-viral-popup-bg { position: fixed; top: 0; left: 0; right: 0; bottom: 0; background: rgba(0, 0, 0, 0.7); z-index: 1; animation: appear 300ms ease-out; }

.marketing-viral-popup-content { width: 389px; height: 264px; padding: 15px; position: absolute; top: 50%; left: 50%; margin: -132px 0 0 -195px; z-index: 10; display: block; animation: appear-pop 400ms ease-out; background: url(../img/marketing/viral-popup/bg.png) no-repeat 0 0; }

.marketing-viral-popup-content .button--cancel { display: block; position: absolute; bottom: 15px; left: 22px; background-image: url(../img/sprite-marketing.png); background-position: -1684px -815px; width: 163px; height: 36px; }

.marketing-viral-popup-content .button--cancel:hover { background-image: url(../img/sprite-marketing.png); background-position: -1849px -815px; width: 163px; height: 36px; }

.marketing-viral-popup-content .button--cancel:active { background-image: url(../img/sprite-marketing.png); background-position: -1189px -815px; width: 163px; height: 36px; }

.marketing-viral-popup-content .button--apply { display: block; position: absolute; bottom: 15px; right: 22px; background-image: url(../img/sprite-marketing.png); background-position: -1996px -769px; width: 163px; height: 36px; cursor: default; pointer-events: none; }

.marketing-viral-popup-content .button--apply.active { background-image: url(../img/sprite-marketing.png); background-position: -450px -890px; width: 163px; height: 36px; cursor: pointer; pointer-events: auto; }

.marketing-viral-popup-content .button--apply.active:hover { background-image: url(../img/sprite-marketing.png); background-position: -1354px -815px; width: 163px; height: 36px; }

.marketing-viral-popup-content .button--apply.active:active { background-image: url(../img/sprite-marketing.png); background-position: -1975px -622px; width: 163px; height: 36px; }

.marketing-viral-popup .viral-round-picker { width: 208px; margin: 70px auto 0; position: relative; }

.marketing-viral-popup .viral-round-picker .button { display: block; position: absolute; top: 38px; font-size: 0; }

.marketing-viral-popup .viral-round-picker .button--less { left: 0; background-image: url(../img/sprite-marketing.png); background-position: -885px -890px; width: 52px; height: 36px; cursor: default; pointer-events: none; }

.marketing-viral-popup .viral-round-picker .button--less.active { background-image: url(../img/sprite-marketing.png); background-position: -831px -890px; width: 52px; height: 36px; cursor: pointer; pointer-events: auto; }

.marketing-viral-popup .viral-round-picker .button--less.active:hover { background-image: url(../img/sprite-marketing.png); background-position: -939px -890px; width: 52px; height: 36px; }

.marketing-viral-popup .viral-round-picker .button--less.active:active { background-image: url(../img/sprite-marketing.png); background-position: -615px -890px; width: 52px; height: 36px; }

.marketing-viral-popup .viral-round-picker .button--more { right: 0; background-image: url(../img/sprite-marketing.png); background-position: -669px -890px; width: 52px; height: 36px; cursor: default; pointer-events: none; }

.marketing-viral-popup .viral-round-picker .button--more.active { background-image: url(../img/sprite-marketing.png); background-position: -993px -890px; width: 52px; height: 36px; cursor: pointer; pointer-events: auto; }

.marketing-viral-popup .viral-round-picker .button--more.active:hover { background-image: url(../img/sprite-marketing.png); background-position: -723px -890px; width: 52px; height: 36px; }

.marketing-viral-popup .viral-round-picker .button--more.active:active { background-image: url(../img/sprite-marketing.png); background-position: -777px -890px; width: 52px; height: 36px; }

.marketing-viral-popup .viral-round-picker .value { padding-top: 44px; margin: 0 auto; background-image: url(../img/sprite-marketing.png); background-position: -76px -966px; width: 80px; height: 87px; text-align: center; font-size: 24px; }

.media-card-launch { display: none; width: 288px; height: 80px; margin-left: -145px; position: absolute; bottom: 28px; left: 50%; border: 1px solid #fff; background: #252b29; box-shadow: inset 0 0 10px #282828; text-align: center; }

.media-card-launch:before { content: ''; display: block; width: 0; height: 0; margin: 0 0 0 -15px; position: absolute; top: 100%; left: 50%; border-style: solid; border-width: 12px 15px 0 15px; border-color: #ffffff transparent transparent transparent; }

.media-card-launch:after { content: ''; display: block; width: 0; height: 0; margin: -1px 0 0 -15px; position: absolute; top: 100%; left: 50%; border-style: solid; border-width: 12px 15px 0 15px; border-color: #282828 transparent transparent transparent; }

.media-card-launch .launch-option { display: inline-block; width: 62px; height: 62px; margin: 8px 3px; padding: 0; position: relative; font-size: 0; cursor: default; opacity: .3; }

.media-card-launch .launch-option:before { content: ''; display: block; position: absolute; top: 50%; left: 50%; }

.media-card-launch .launch-option.active { cursor: pointer; opacity: 1; }

.media-card-launch .launch-option--robot-1:before { margin-top: -29px; margin-left: -31px; background-image: url(../img/sprite-marketing.png); background-position: -530px -1055px; width: 62px; height: 58px; }

.media-card-launch .launch-option--robot-1.active:hover:before { background-image: url(../img/sprite-marketing.png); background-position: -594px -1055px; width: 62px; height: 58px; }

.media-card-launch .launch-option--robot-2:before { margin-top: -29px; margin-left: -31px; background-image: url(../img/sprite-marketing.png); background-position: -402px -1055px; width: 62px; height: 58px; }

.media-card-launch .launch-option--robot-2.active:hover:before { background-image: url(../img/sprite-marketing.png); background-position: -466px -1055px; width: 62px; height: 58px; }

.media-card-launch .launch-option--robot-3:before { margin-top: -29px; margin-left: -31px; background-image: url(../img/sprite-marketing.png); background-position: -274px -1055px; width: 62px; height: 58px; }

.media-card-launch .launch-option--robot-3.active:hover:before { background-image: url(../img/sprite-marketing.png); background-position: -146px -1055px; width: 62px; height: 58px; }

.media-card-launch .launch-option--robot-4:before { margin-top: -29px; margin-left: -31px; background-image: url(../img/sprite-marketing.png); background-position: -210px -1055px; width: 62px; height: 58px; }

.media-card-launch .launch-option--robot-4.active:hover:before { background-image: url(../img/sprite-marketing.png); background-position: -338px -1055px; width: 62px; height: 58px; }

.media-card-launch .launch-option--teacher:before { margin-top: -29px; margin-left: -31px; background-image: url(../img/sprite-quality.png); background-position: -1240px -988px; width: 62px; height: 58px; }

.media-card-launch .launch-option--teacher.active:hover:before { background-image: url(../img/sprite-quality.png); background-position: -1240px -928px; width: 62px; height: 58px; }

.media-card-launch .launch-option--shopping:before { margin-top: -29px; margin-left: -31px; background-image: url(../img/sprite-quality.png); background-position: -1240px -868px; width: 62px; height: 58px; }

.media-card-launch .launch-option--shopping.active:hover:before { background-image: url(../img/sprite-quality.png); background-position: -1240px -748px; width: 62px; height: 58px; }

.media-card-launch .launch-option--trainer:before { margin-top: -29px; margin-left: -31px; background-image: url(../img/sprite-quality.png); background-position: -1240px -628px; width: 62px; height: 58px; }

.media-card-launch .launch-option--trainer.active:hover:before { background-image: url(../img/sprite-quality.png); background-position: -1240px -1048px; width: 62px; height: 58px; }

.media-card-launch .launch-option--mechanic:before { margin-top: -29px; margin-left: -31px; background-image: url(../img/sprite-quality.png); background-position: -1240px -808px; width: 62px; height: 58px; }

.media-card-launch .launch-option--mechanic.active:hover:before { background-image: url(../img/sprite-quality.png); background-position: -1240px -688px; width: 62px; height: 58px; }

.summary-popup--marketing .summary-popup-content { background: url(../img/marketing/summary-popup.png) no-repeat center; }

.summary-popup--marketing .failure-rate-table { text-shadow: none; width: 385px; margin: 20px 0; }

.summary-popup--marketing .failure-rate-table td, .summary-popup--marketing .failure-rate-table th { border-right: 1px solid; border-bottom: 1px solid; padding: 5px 5px; width: 50%; text-align: center; }

.summary-popup--marketing .failure-rate-table td:last-child, .summary-popup--marketing .failure-rate-table th:last-child { border-right: 0; }

.summary-popup--marketing .failure-rate-table th { font-weight: normal; padding-top: 0; }

.summary-popup--marketing .failure-rate-table td { font-weight: bold; }

.summary-popup--marketing .bonus-chart table thead th { height: 38px; }

.summary-popup--marketing .summary-right { width: 805px; height: 677px; margin: -28px -20px -30px -20px; }

.summary-popup--marketing .summary-marketing { padding: 10px 10px 5px 20px; border-top: 1px solid rgba(255, 255, 255, 0.5); }

.summary-popup--marketing .summary-marketing:first-child { border-top: 0; }

.summary-popup--marketing .summary-marketing h3 { font-size: 18px; line-height: 30px; text-shadow: none; }

.summary-popup--marketing .summary-marketing .content-table { width: 605px; margin-bottom: 20px; text-shadow: none; }

.summary-popup--marketing .summary-marketing .content-table th:not(:first-child) { width: 126px; font-weight: normal; }

.summary-popup--marketing .summary-marketing .content-table td { height: 70px; text-align: left; vertical-align: middle; position: relative; }

.summary-popup--marketing .summary-marketing .content-table td:not(:first-child) { border: 1px solid; text-align: center; }

.summary-popup--marketing .summary-marketing .content-table-check { display: block; margin: 0 auto; background-image: url(../img/sprite-marketing.png); background-position: -76px -1055px; width: 68px; height: 60px; transform: scale(0.5); }

.summary-popup--marketing .summary-marketing .content-table-is-initial:before { content: 'Nie wlicza się'; display: block; position: absolute; left: 0; right: 0; top: 0; text-align: center; }

.summary-popup--marketing .summary-marketing .content-table-is-initial:after { content: 'do premii'; display: block; position: absolute; left: 0; right: 0; bottom: 0; text-align: center; }

.summary-popup--marketing .summary-marketing .content-table-label { display: block; padding-left: 68px; position: relative; line-height: 49px; }

.summary-popup--marketing .summary-marketing .content-table-label:before { content: ''; display: block; position: absolute; top: 50%; left: 0; }

.summary-popup--marketing .summary-marketing .content-table-label--robot-1:before { background-image: url(../img/sprite-quality.png); background-position: -204px -1237px; width: 49px; height: 49px; margin-top: -24.5px; }

.summary-popup--marketing .summary-marketing .content-table-label--robot-2:before { background-image: url(../img/sprite-quality.png); background-position: -153px -1237px; width: 49px; height: 49px; margin-top: -24.5px; }

.summary-popup--marketing .summary-marketing .content-table-label--robot-3:before { background-image: url(../img/sprite-quality.png); background-position: -102px -1237px; width: 49px; height: 49px; margin-top: -24.5px; }

.summary-popup--marketing .summary-marketing .content-table-label--robot-4:before { background-image: url(../img/sprite-quality.png); background-position: -51px -1237px; width: 49px; height: 49px; margin-top: -24.5px; }

.summary-popup--marketing .summary-marketing .content-table-label--teacher:before { background-image: url(../img/sprite-quality.png); background-position: -1240px -988px; width: 62px; height: 58px; margin-top: -29px; }

.summary-popup--marketing .summary-marketing .content-table-label--shopping:before { background-image: url(../img/sprite-quality.png); background-position: -1240px -868px; width: 62px; height: 58px; margin-top: -29px; }

.summary-popup--marketing .summary-marketing .content-table-label--trainer:before { background-image: url(../img/sprite-quality.png); background-position: -1240px -628px; width: 62px; height: 58px; margin-top: -29px; }

.summary-popup--marketing .summary-marketing .content-table-label--mechanic:before { background-image: url(../img/sprite-quality.png); background-position: -1240px -808px; width: 62px; height: 58px; margin-top: -29px; }

.sell-opinion-popup { position: fixed; top: 0; left: 0; right: 0; bottom: 0; z-index: 900; }

.sell-opinion-popup-bg { position: fixed; top: 0; left: 0; right: 0; bottom: 0; background: rgba(0, 0, 0, 0.7); z-index: 1; animation: appear 300ms ease-out; }

.sell-opinion-popup-content { width: 891px; height: 623px; position: absolute; top: 50%; left: 50%; margin: -311.5px 0 0 -445.5px; z-index: 10; display: block; animation: appear-pop 400ms ease-out; background: url(../img/sell/opinions/opinions-panel-top.png) no-repeat center; }

.sell-opinion-popup .labels { padding: 47px 15px 5px 35px; display: flex; align-items: center; }

.sell-opinion-popup .labels li { float: left; width: 10%; text-align: center; }

.sell-opinion-popup .labels li:nth-child(1) { width: 22%; }

.sell-opinion-popup .labels li:nth-child(2) { width: 13%; }

.sell-opinion-popup .labels li:nth-child(3) { width: 13%; padding-left: 25px; }

.sell-opinion-popup.fake-popup-visible .sell-opinion-popup-content { margin-left: -295.5px; }

@media screen and (max-width: 1359px) { .sell-opinion-popup.fake-popup-visible .sell-opinion-popup-content { margin-left: -595.5px; } }

.sell-opinion-popup .sell-marketing { float: left; width: 33%; margin-left: 4%; line-height: 50px; text-align: center; padding-top: 2px; }

.sell-opinion-popup .sell-marketing .points { float: left; width: 64%; font-size: 24px; }

.sell-opinion-popup .sell-marketing .count { float: left; width: 36%; font-size: 36px; }

.sell-opinion-popup .clients-opinions { float: right; width: 520px; height: 477px; background-image: url(../img/sell/opinions/opinions-panel-bottom.png); margin-right: 17px; border-radius: 4px; }

.sell-opinion-popup .clients-opinions .item { display: flex; align-items: center; padding: 2px 0; }

.sell-opinion-popup .clients-opinions .item:nth-child(2n+1) { background: rgba(255, 255, 255, 0.22); }

.sell-opinion-popup .clients-opinions .item .no { font-size: 72px; opacity: 0.5; text-align: center; width: 18%; font-weight: bold; }

.sell-opinion-popup .clients-opinions .item .opinions { width: 81%; line-height: 29px; }

.sell-opinion-popup .clients-opinions .item .opinions .subitem { position: relative; }

.sell-opinion-popup .clients-opinions .item .opinions .number { width: 15%; float: left; font-size: 24px; text-align: center; }

.sell-opinion-popup .clients-opinions .mark { position: absolute; top: 0; left: 15%; width: 50%; height: 29px; }

.sell-opinion-popup .clients-opinions .mark:last-child:before { content: ''; display: block; background: #fff; height: 1px; position: absolute; top: 14px; left: 0; right: 0; }

.sell-opinion-popup .clients-opinions .mark:after { content: ''; display: block; height: 49px; width: 49px; position: absolute; top: 50%; transform: translateY(-50%); right: 0; background-position: center; background-repeat: no-repeat; z-index: 2; }

.sell-opinion-popup .clients-opinions .mark-n { width: 18.5%; }

.sell-opinion-popup .clients-opinions .mark-n:after { background-image: url(../img/quality/research/research-robot-01.png); }

.sell-opinion-popup .clients-opinions .mark-g { width: 38%; }

.sell-opinion-popup .clients-opinions .mark-g:after { background-image: url(../img/quality/research/research-robot-02.png); }

.sell-opinion-popup .clients-opinions .mark-z { width: 58.5%; }

.sell-opinion-popup .clients-opinions .mark-z:after { background-image: url(../img/quality/research/research-robot-03.png); }

.sell-opinion-popup .clients-opinions .mark-k { width: 78.5%; }

.sell-opinion-popup .clients-opinions .mark-k:after { background-image: url(../img/quality/research/research-robot-04.png); }

.sell-opinion-popup .back { display: block; width: 163px; height: 36px; background: url(../img/sell/summary-back.png) no-repeat center; position: absolute; right: 17px; bottom: 15px; }

.sell-opinion-popup .back:hover { background-image: url(../img/sell/summary-back-hover.png); }

.additional-budget { width: 100%; height: 78px; position: relative; cursor: default; }

.additional-budget:before { content: ''; display: block; position: absolute; top: 3px; left: -3px; background-image: url(../img/sprite-shopping.png); background-position: -410px -38px; width: 99px; height: 74px; }

.additional-budget.active { cursor: pointer; }

.additional-budget.active:before { background-image: url(../img/sprite-shopping.png); background-position: -410px -137px; width: 99px; height: 74px; }

.additional-budget.active:hover:before { background-image: url(../img/sprite-shopping.png); background-position: -101px -236px; width: 99px; height: 74px; }

.additional-budget.active:active:before { background-image: url(../img/sprite-shopping.png); background-position: 0px -236px; width: 99px; height: 74px; }
